
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenBalanceDisplay from './components/trading/TokenBalanceDisplay';
import CloseIcon from '@mui/icons-material/Close';
import TokenPurchaseHistory from './components/trading/TokenPurchaseHistory';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { ToastContainer, toast } from 'react-toastify';
import logoImage from './assets/logo-tp.png';
import bnnaImage from './assets/bnnalogo.png';
import pumpImage from './assets/pump-logo.png';
import TokenChart from './TokenChart';
import { Box, Container, Typography, Modal, Grid, Button, Paper, Stack, IconButton, Dialog, DialogTitle, DialogContent, ButtonGroup } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { PlayCircle, Receipt } from '@mui/icons-material';
import { Savings } from '@mui/icons-material';
import { Stream } from '@mui/icons-material';
import { Update } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PointsDisplay from './PointsDisplay';
import { VideogameAsset } from '@mui/icons-material';
import { Book } from '@mui/icons-material';
import GainsCalculator from './GainsCalculator';
import PumpAssessmentModal from './PumpAssessmentModal';
import { Calculate } from '@mui/icons-material';
import DemoAutoApeModal from './DemoAutoApeModal';
import { verify } from '@noble/ed25519';
import bs58 from 'bs58';
import { PublicKey } from '@solana/web3.js';
import { encode as bs58encode } from 'bs58';
import TokenStats from './TokenStats';
import { ContentCopy } from '@mui/icons-material';
import VideoModal from './VideoModal';
import { FaTiktok } from 'react-icons/fa';
import LatestTrades from './LatestTrades';
import UserCount from './UserCount';
import { Star } from '@mui/icons-material';
import VideoPlayer from './VideoPlayer';
import dexPaidVideo from './assets/dexpaid.mp4';
import aiVideo from './assets/ai-apeout.mp4';
import wojakVideo from './assets/wojakbuysbanana.mp4';
import { requestQueue } from './App';
import PremiumModal from './components/PremiumModal';
import PremiumFeature from './components/PremiumFeature';
import { Wallet, EmojiEvents } from '@mui/icons-material';
import SectionDivider from './components/SectionDivider';
import Leaderboard from './components/Leaderboard';
import AutoBumpDemoModal from './components/features/AutoBumpDemoModal';
import WalletAndBuyButtons from './components/WalletAndBuyButtons';
import EnhancedContainer from './components/EnhancedContainer';
import AutoTPSLButton from './components/trading/AutoTPSLButton';
import WojakStorySection from './components/WojanStorySection';
import MaintenanceBanner from './components/MaintenanceBanner';
import ConsoleLogModal from './components/admin/ConsoleLogModal';
import { Terminal } from '@mui/icons-material';
import BnnaConverter from './utils/BnnaConverter';
import LaunchIcon from '@mui/icons-material/Launch';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DisclaimerModal from './components/DisclaimerModal';
import ComingSoonOverlay from './components/ComingSoonOverlay';
import AuthenticationHandler from './components/auth/AuthenticationHandler';
import BnnaConverterModal from './utils/BnnaConverterModal';
import AchievementsModal from './components/AchievementsModal'; 
import { FaTrophy } from 'react-icons/fa';
import PremiumTriggerButton from './components/PremiumTriggerButton';



const StyledBannerSection = styled('div')({
  background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://cdn.pixabay.com/photo/2019/08/19/09/24/fruit-4415864_1280.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: '2rem',
  borderRadius: '16px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  margin: '1rem 0',
});
const StyledTitle = styled('h1')({
  fontWeight: 'bold',
  background: 'linear-gradient(to right, #fff, #cbd5e1)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
  textAlign: 'center',
  textDecoration: 'underline',
  textDecorationColor: '#cbd5e1',
  textUnderlineOffset: '8px',
  textDecorationThickness: '2px',
  marginBottom: '1.5rem',
  fontSize: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5rem'
});
const ButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  flexWrap: 'wrap',
});
const StyledWalletButton = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '20px',
  right: '20px',
  zIndex: 1000,
  width: 'auto',
  '@media (max-width: 600px)': {
    top: '10px',
    right: '10px',
  }
}));

const StyledWalletButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '100%'
});
const StyledUtilitiesSection = styled('div')({
  background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://cdn.pixabay.com/photo/2015/08/14/19/41/minion-888797_1280.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: '2rem',
  borderRadius: '16px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  margin: '2rem auto',
  maxWidth: '1200px',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  alignItems: 'center'
});
const StyledHeading = styled('h2')({
  fontWeight: 'bold',
  background: 'linear-gradient(to right, #fff, #cbd5e1)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
  textAlign: 'center',
  position: 'relative',
  fontSize: '2rem',
  marginBottom: '1rem',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-8px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60%',
    height: '2px',
    background: 'linear-gradient(to right, #fff, #cbd5e1)',
    borderRadius: '2px'
  }
});
const UtilitiesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  width: '100%',
  alignItems: 'center'
});
const ActionButton = styled(Button)({
  borderColor: 'rgba(255, 255, 255, 0.23)',
  color: 'white',
  padding: '12px 24px',
  width: '250px',
  fontSize: '1rem',
  textTransform: 'none',
  borderRadius: '12px',
  background: 'rgb(79, 70, 229)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'rgba(79, 70, 229, 0.8)',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(79, 70, 229, 0.3)'
  },
  '& .emoji': {
    marginRight: '8px',
    fontSize: '1.2rem'
  }
});
const StyledTokenSection = styled('div')({
  background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))',
  borderRadius: '16px',
  padding: '1.5rem',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem'
});
const ContractInfoBox = styled('div')(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '8px',
  padding: '0.75rem',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
}));

const AddressContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  overflow: 'hidden',
  width: '100%'
});

const ContractLabel = styled(Typography)({
  color: 'rgba(255, 255, 255, 0.7)',
  fontFamily: 'monospace',
  fontSize: '0.85rem',
  whiteSpace: 'nowrap',
  flexShrink: 0
});

const ContractAddress = styled(Typography)({
  color: '#4ade80',
  fontFamily: 'monospace',
  fontSize: '0.85rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minWidth: 0,
  flex: 1
});
const CopyButton = styled(IconButton)({
  color: 'white',
  padding: '4px',
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }
});

const LogosContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2rem',
  margin: '1rem 0 0 0',
  '@keyframes float': {
    '0%, 100%': {
      transform: 'translateY(0)'
    },
    '50%': {
      transform: 'translateY(-10px)'
    }
  }
});

const Logo = styled('img')({
  width: '100px',
  height: 'auto',
  animation: 'float 3s ease-in-out infinite',
  filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2))',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  }
});

const StyledPremiumSection = styled('div')({
  background: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("https://cdn.pixabay.com/photo/2020/08/26/11/10/banana-5519304_1280.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: '2.5rem',
  borderRadius: '20px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  margin: '2rem auto',
  maxWidth: '1200px',
});

const StyledPremiumHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  marginBottom: '2rem',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '200px',
    height: '2px',
    background: 'linear-gradient(to right, transparent, #cbd5e1, transparent)',
  }
});
const StyledStar = styled(Star)({
  color: '#fbbf24',
  fontSize: '2rem',
  filter: 'drop-shadow(0 0 8px rgba(251, 191, 36, 0.3))',
  animation: 'pulse 2s infinite',
  '@keyframes pulse': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.1)' },
    '100%': { transform: 'scale(1)' },
  }
});

const ButtonsContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
  gap: '1.5rem',
  width: '100%',
  marginTop: '2rem',
});

const PremiumButton = styled(Button)({
  padding: '1.25rem',
  borderRadius: '12px',
  fontSize: '1rem',
  fontWeight: '600',
  textTransform: 'none',
  transition: 'all 0.3s ease',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  background: 'linear-gradient(to right, #ff4d4d, #ff1a1a)', // Red gradient
  color: 'white', // Ensure text is visible on red background
  '&:hover': {
    background: 'linear-gradient(to right, #ff1a1a, #e60000)', // Darker red on hover
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
  }
});

// Animations
const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;
// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.9), rgba(31, 41, 55, 0.9))',
  backdropFilter: 'blur(16px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
}));
const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(12), // Add padding at bottom for the fixed footer
  background: 'linear-gradient(to bottom right, #111827, #000000)',
  overflowY: 'auto', // Allow vertical scrolling
  position: 'relative', // Add this to ensure proper stacking
}));
const SocialFooter = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.7))',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(3),
  zIndex: 1000,
}));
const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px)',
    color: '#3b82f6',
  },
}));
const GoldButton = styled(Button)({
  background: 'linear-gradient(45deg, #FFD700, #FFA500)',
  color: '#000',
  fontWeight: 'bold',
  border: '1px solid rgba(255, 215, 0, 0.5)',
  boxShadow: '0 2px 4px rgba(255, 215, 0, 0.2)',
  '&:hover': {
    background: 'linear-gradient(45deg, #FFA500, #FFD700)',
    boxShadow: '0 4px 8px rgba(255, 215, 0, 0.3)',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.3s ease',
});


const MetallicButton = styled(Button)(({ variant }) => {
  const gradients = {
    gold: 'linear-gradient(45deg, #FFD700, #FFA500)',
    silver: 'linear-gradient(45deg, #C0C0C0, #E8E8E8)',
    bronze: 'linear-gradient(45deg, #CD7F32, #B8860B)'
  };

  const hoverGradients = {
    gold: 'linear-gradient(45deg, #FFA500, #FFD700)',
    silver: 'linear-gradient(45deg, #E8E8E8, #C0C0C0)',
    bronze: 'linear-gradient(45deg, #B8860B, #CD7F32)'
  };

  return {
    background: gradients[variant] || gradients.gold,
    color: variant === 'silver' ? '#000' : '#FFF',
    fontWeight: 'bold',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    padding: '0.75rem 1rem',
    borderRadius: '12px',
    fontSize: '1rem',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    minWidth: '150px',
    maxWidth: '200px',
    flex: 1,
    '&:hover': {
      background: hoverGradients[variant] || hoverGradients.gold,
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    }
  };
});
// Add this styled component with your other styled components
const DisclaimerLink = styled('button')({
  background: 'none',
  border: 'none',
  color: '#94a3b8',
  fontSize: '0.75rem',
  textDecoration: 'underline',
  cursor: 'pointer',
  padding: '4px 8px',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: '#e2e8f0'
  }
});





const TASK_POINTS = {
  walletConnected: 100,
  tradingWalletCreated: 200,
  boughtBnna: 300,
  demoAutoApe: 400
};
const FETCH_DELAY = 2000; // 2 seconds between fetches
const SIGNATURE_STATE_KEY = 'signature_state_v1';




const AuthFlow = ({ onComplete, fetchUserData, setTradingWallet, tradingWallet, handleBuy, setWallet, wallet, walletConnected, sendDevFee, points, ownedTokens, handleSell,
  setPoints,
  completedTasks,
  setCompletedTasks, setTradeSettings, tradeSettings, handleAutoTPSLSell, setBoughtTokens, setOwnedTokens, amount, isProcessing, toastConfig, devFeeSet, getPrivateKeyForPublicKey, boughtTokens, handleBuyRaydium }) => {
  const { publicKey, connected, signMessage, disconnect } = useWallet();
  const [isCreatingWallet, setIsCreatingWallet] = useState(false);
  const [hasExistingWallet, setHasExistingWallet] = useState(false);
  const [userTradingWallet, setUserTradingWallet] = useState('');
  const [lastAttempt, setLastAttempt] = useState(0);
  const [hasCheckedWallet, setHasCheckedWallet] = useState(false);
  const previousPublicKey = useRef(null);
  // const [points, setPoints] = useState(0);
  const [showPumpAssessment, setShowPumpAssessment] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [connectedWalletBalance, setConnectedWalletBalance] = useState(null);
  const [tradingWalletBalance, setTradingWalletBalance] = useState(null);
  const [bnnaBalance, setBnnaBalance] = useState(0);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(0.01);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const [isSignatureVerified, setIsSignatureVerified] = useState(false);


  const isFetching = useRef(false);
  const BALANCE_CHECK_INTERVAL = 5 * 60 * 1000; // 5 minutes
  const RETRY_DELAY = 30 * 1000; // 30 seconds for retries
  const [isBnnaLoading, setIsBnnaLoading] = useState(true);
  const [isSolLoading, setIsSolLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const DEV_WALLET = '5LW5YcDNQipJPzEUXCuwTu6gE4vzaEuiAqFSyjfpjBZx'; // Replace with your actual dev wallet address
  const [showAutoBumpModal, setShowAutoBumpModal] = useState(false);
  const navigate = useNavigate();
  const [showConsoleModal, setShowConsoleModal] = useState(false);
  const getPumpProfileUrl = (walletAddress) => {
    return `https://pump.fun/profile/${walletAddress}`;
  };
  const [showOwnedTokens, setShowOwnedTokens] = useState(false);
  const [isLoadingBalances, setIsLoadingBalances] = useState(false);
  const [tokenPurchases, setTokenPurchases] = useState([]);
  const [tokenDetailsMap, setTokenDetailsMap] = useState({});

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [isAchievementsOpen, setIsAchievementsOpen] = useState(false);
  const [isEarnModalOpen, setIsEarnModalOpen] = useState(false);
  const [isTokenDetailsOpen, setIsTokenDetailsOpen] = useState(false);
  const [isUtilitiesModalOpen, setIsUtilitiesModalOpen] = useState(false);



  



// Add this button somewhere in your admin section or where appropriate
const ConsoleButton = styled(Button)({
  background: 'linear-gradient(45deg, #333, #666)',
  color: '#00ff00',
  '&:hover': {
    background: 'linear-gradient(45deg, #444, #777)',
  },
});

const handleBuyWithRaydium = async (coin, purchaseAmount, slippage, priorityFee) => {
  // Add at the start of handleBuy
  if (isProcessing.current) {
    console.log('Transaction already processing');
    return false;
  }

  // Ensure coin is a valid base58 string
  const sanitizedCoin = coin.replace(/[^A-Za-z0-9]/g, '')

  // let purchaseAmount = 0.0008
 
  // Check if the coin is our platform token and set hardcoded amount
  if (coin === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump' || coin.mint === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump') {
    purchaseAmount = 0.0008; // Hardcoded amount for platform token
  } else {
    purchaseAmount = 0.0008
  }

  if (isNaN(amount) || amount <= 0) {
    toast.error("Please enter a valid amount");
    return false;
  }
  if (!wallet) {
    toast.error("Wallet not connected!");
    return false;
  }
  if (!tradingWallet) {
    toast.error("Trading wallet not created!");
    return false;
  }
  
  const toastId = toast.loading(
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <span>🔄</span>
      <div>
        <div style={{ fontWeight: 500 }}>Processing Buy Order</div>
        <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
          Please wait while your transaction is being processed...
        </div>
      </div>
    </div>,
    {
      closeButton: ({ closeToast }) => (
        <button 
          onClick={() => { 
            cancelSwap = true;
            closeToast();
          }}
          style={{
            background: 'transparent',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '8px',
            color: 'white',
            padding: '6px 12px',
            cursor: 'pointer',
            fontSize: '12px',
            transition: 'all 0.2s ease',
            ':hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderColor: 'rgba(255, 255, 255, 0.3)',
            }
          }}
        >
          Cancel Swap
        </button>
      ),
      ...toastConfig
    }
  );
  let cancelSwap = false;

  const cancelButton = (
    <button onClick={() => { 
      cancelSwap = true;
      toast.dismiss(toastId);
    }}>
      Cancel Swap
    </button>
  );

  toast.update(toastId, { 
    render: (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <span>🔄</span>
        <div>
          <div style={{ fontWeight: 500 }}>Processing Buy Order</div>
          <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
            Please wait while your transaction is being processed...
          </div>
        </div>
      </div>
    ), 
    type: "info", 
    isLoading: true, 
    closeButton: cancelButton,
    ...toastConfig
  });

  try {
    isProcessing.current = true;

    if (cancelSwap) {
      toast.dismiss(toastId);
      return false;
    }
  
    const apiKey = tradingWallet.apiKey;
    const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${apiKey}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'buy',
        amount: 0.001,
        denominatedInSol: 'true',
        mint: coin,
        pool: 'raydium',
        priorityFee: 0.001,
        slippage: 15
      }),
    });
    const swapResult = await response.json();
   
    if (!swapResult.signature) {
      throw new Error(`HTTP error! status: ${swapResult.errors}`);
    } else if (swapResult.signature) {
    
      toast.update(toastId, { 
        render: (
          <div>
            Swap successful!<br/>
            <a href={`https://solscan.io/tx/${swapResult.signature}`} target="_blank" rel="noopener noreferrer">
              View on Solscan
            </a>
          </div>
        ), 
        type: "success", 
        isLoading: false, 
        autoClose: 5000 
      });
    } else {
      console.log(swapResult)
      toast.update(toastId, { render: `An Unknown Error Occured`, type: "error", isLoading: false, autoClose: 5000 });

      throw new Error(`An Unknown Error Occured: ${swapResult.errors}`);
    }
    
    if (devFeeSet){
      // Send fee to dev wallet
      const feeAmount = 0.0008
      const privateKey = getPrivateKeyForPublicKey(tradingWallet.publicKey);
      if (!privateKey) {
        throw new Error("Private key not found for trading wallet");
      }
      const feeResponse = await fetch(`${process.env.REACT_APP_API_URL}api/send-fee-auto`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: feeAmount,
          senderPublicKey: tradingWallet.publicKey,
          recipientAddress: DEV_WALLET, // Make sure this is defined
          privateKey: privateKey
        }),
      });
      if (!feeResponse.ok) {
        throw new Error(`Failed to create fee transaction: ${feeResponse.statusText}`);
      }
      const feeData = await feeResponse.json();
      if (feeData.success && feeData.signature) {
        const signature = feeData.signature;
        toast.update(toastId, { render: "Dev Fee Sent Successfully!", type: "success", isLoading: false, autoClose: 5001 });
        console.log(signature);
        console.log(`Fee of ${feeAmount} SOL sent to dev wallet. Signature: ${signature}`);
      } else {
        throw new Error(`Failed to create fee transaction: ${feeData.error || 'Failed to Transfer DEV fee'}`);
      }

      const result = await feeResponse.json();
      console.log('FEE result:', result);
    }
    
    setOwnedTokens(prev => ({
      ...prev,
      [coin.name]: (prev[coin.name] || 0) + parseFloat(amount)
    }));
    // Add the bought token to the boughtTokens list
    const newBoughtTokens = [...boughtTokens, coin];
    setBoughtTokens(newBoughtTokens);
    localStorage.setItem('boughtTokens', JSON.stringify(newBoughtTokens));
    
    return true;
  } catch (error) {
    console.error('Error processing swap:', error);
    toast.update(toastId, { 
      render: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>❌</span>
          <div>
            <div style={{ fontWeight: 500 }}>Swap Failed</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              {error.message}
            </div>
          </div>
        </div>
      ), 
      type: "error", 
      isLoading: false, 
      autoClose: 5000,
      ...toastConfig
    });

    return false;
  } finally {
    isProcessing.current = false;
  }
};
  
const BuyModal = ({ open, onClose, onBuy }) => {
  const amounts = [0.001, 0.01, 0.1, 0.5, 1];
  
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
          backdropFilter: 'blur(16px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: 2,
          maxWidth: '90vw',
          width: 'auto'
        }
      }}
    >
      <DialogTitle sx={{ color: 'white', textAlign: 'center' }}>
        Select Amount to Buy
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <ButtonGroup
          orientation="vertical"
          sx={{ width: '100%', gap: 1 }}
        >
          {amounts.map((amount) => (
            <Button
              key={amount}
              variant="contained"
              onClick={() => {
                onBuy(amount);
                onClose();
              }}
              sx={{
                py: 1.5,
                px: 4,
                background: selectedAmount === amount 
                  ? 'linear-gradient(to right, #10b981, #059669)'
                  : 'rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  background: 'linear-gradient(to right, #059669, #047857)',
                },
                color: 'white',
                fontSize: '1.1rem'
              }}
            >
              {amount} SOL
            </Button>
          ))}
        </ButtonGroup>
      </DialogContent>
    </Dialog>
  );
};
const resetWalletState = () => {
  setHasExistingWallet(false);
  setUserTradingWallet('');
  setTradingWallet(null);
  setPoints(0);
  setCompletedTasks(prev => ({
    ...prev,
    walletConnected: false
  }));
  setIsSignatureVerified(false);
  localStorage.removeItem('walletConnected');
  localStorage.removeItem('tradingWallet');
  setHasCheckedWallet(false);
  previousPublicKey.current = null;
};
const handleBuyClick = async (amount) => {
  if (!connected || !publicKey) {
    toast.error("Please connect your wallet first");
    return;
  }
  if (!tradingWallet) {
    toast.error("Trading wallet not created!");
    return;
  }
  
  try {
    // Wait for handleBuy to return success/failure
    const buySuccess = await handleBuyRaydium('DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump', amount);
    
    // Only proceed with points update if buy was successful
    if (buySuccess) {
      let token = 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump'
     
      console.debug('Updating points for BNNA purchase');
      // Add delay to allow transaction to settle
      await new Promise(resolve => setTimeout(resolve, 2000));
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/update-user-points`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress: publicKey.toString(),
          taskName: 'boughtBnna',
          taskName: 'boughtBNNA',
        })
      });

      if (!response.ok) {
        console.error('Failed to update points:', await response.text());
        return; // Don't throw here since the purchase was successful
      }

      const updatedData = await response.json();
      if (updatedData.success) {
        setPoints(updatedData.points);
        setCompletedTasks(updatedData.completedTasks);
        toast.success('BNNA purchase completed! Points awarded!');
      }
    }
     // Update balances
     await throttledBalanceCheck();
  } catch (error) {
    console.error('Error during buy process:', error);
    toast.error('Transaction failed');
  }
};
const updateUserPoints = async (walletAddress, completedTask) => {
  console.log(`Updating points for ${walletAddress}, task: ${completedTask}`);
  
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/user-points/${walletAddress}`);
    const userData = await response.json();
    
    // Only award points if task wasn't previously completed
    if (!userData.completedTasks[completedTask]) {
      const updatedTasks = {
        ...userData.completedTasks,
        [completedTask]: true
      };
      
      const newPoints = userData.points + (TASK_POINTS[completedTask] || 0);
      
      console.log(`Awarding ${TASK_POINTS[completedTask]} points for ${completedTask}`);
      
      const updateResponse = await fetch(`${process.env.REACT_APP_API_URL}api/update-user-points`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          walletAddress,
          points: newPoints,
          completedTasks: updatedTasks
        })
      });
      
      if (!updateResponse.ok) {
        throw new Error('Failed to update points');
      }
      
      return { points: newPoints, completedTasks: updatedTasks };
    }
    
    return userData;
  } catch (error) {
    console.error('Error updating user points:', error);
    throw error;
  }
};
const fetchBnnaBalance = async () => {
  await requestQueue.add(async () => {
    setIsBnnaLoading(true);
  if (!tradingWallet?.walletPublicKey) return;
  
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/bnna-balance/${tradingWallet.walletPublicKey.toString()}`);
    const data = await response.json();
    
    if (data.success) {
      setBnnaBalance(data.balance);
      await new Promise(resolve => setTimeout(resolve, 5000));
      await checkRichApeStatus();
    }
  } catch (error) {
    console.error('Error fetching BNNA balance:', error);
  } finally { 
    setIsBnnaLoading(false);
  }
  });
  
};
const completeWalletCreationTask = async () => {
  try {
    console.debug('Checking wallet creation task status');
    
    if (!publicKey || !tradingWallet?.walletPublicKey) {
      return;
    }

    // Check server-side task status first
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/user-points/${publicKey.toString()}`
    );
    const userData = await response.json();

    // Only proceed if task isn't already completed on server
    if (!userData.completedTasks?.tradingWalletCreated) {
      console.debug('Trading wallet task not completed, updating...');
      
      const updateResponse = await fetch(
        `${process.env.REACT_APP_API_URL}api/update-user-points`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            walletAddress: publicKey.toString(),
            taskName: 'tradingWalletCreated'
          })
        }
      );

      const updatedData = await updateResponse.json();
      
      if (updatedData.success) {
        setCompletedTasks(updatedData.completedTasks);
        setPoints(updatedData.points);
        toast.success('🎉 Trading Wallet Creation task completed!');
      }
    } else {
      console.debug('Trading wallet task already completed');
    }
  } catch (error) {
    console.error('Error completing wallet creation task:', error);
  }
};
const handleRefreshBalances = async () => {
  try {
    setIsLoadingBalances(true);
    // Add your balance refresh logic here
    // Similar to what's in DemoAutoApeModal
  } catch (error) {
    console.error('Error refreshing balances:', error);
    toast.error('Failed to refresh balances');
  } finally {
    setIsLoadingBalances(false);
  }
};

const checkWallet = async () => {
  if (isFetching.current) return;
  
  try {
    isFetching.current = true;
    
    // Initial delay before first request
    await new Promise(r => setTimeout(r, 2000));
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${publicKey.toString()}`);
    
    if (!response.ok) {
      if (response.status === 404) {
        setHasExistingWallet(false);
        return;
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const existingUser = await response.json();
    
    // Delay before next request
    await new Promise(r => setTimeout(r, 2000));
    
    if (existingUser?.tradingWallet) {
      const syncResponse = await fetch(`${process.env.REACT_APP_API_URL}api/sync-wallet-status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          walletAddress: publicKey.toString(),
          tradingWallet: existingUser.tradingWallet
        })
      });

      // Delay before fetching user points
      await new Promise(r => setTimeout(r, 2000));
      
      await fetchUserPoints(publicKey.toString());

      // Final delay before wallet sync
      await new Promise(r => setTimeout(r, 2000));
      
      setHasExistingWallet(true);
      setTradingWallet(existingUser.tradingWallet);
      await fetchUserData();
    }
  } catch (error) {
    console.error('Error checking wallet:', error);
  } finally {
    isFetching.current = false;
  }
};
const fetchUserPoints = async (walletAddress) => {
  try {
    await new Promise(r => setTimeout(r, 2000));
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/user-points/${walletAddress}`);
    if (!response.ok) throw new Error('Failed to fetch points');
    const data = await response.json();
    setPoints(data.points);
    setCompletedTasks(data.completedTasks);
  } catch (error) {
    console.error('Error fetching points:', error);
  }
};
const createTradingWallet = async () => {
  if (!publicKey) {
    toast.error("Please connect your wallet first");
    return;
  }

  setIsCreatingWallet(true);
  console.debug('Starting trading wallet creation for:', publicKey.toString());
  
  try {
    // Add delay to prevent rapid requests
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/create-wallet`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ walletAddress: publicKey.toString() })
    });

    if (!response.ok) {
      throw new Error(`Failed to create wallet: ${response.status}`);
    }

    const tradingWalletData = await response.json();
    console.debug('Received trading wallet data:', tradingWalletData);
    
    if (!tradingWalletData.walletPublicKey) {
      throw new Error('Invalid trading wallet data received');
    }

    const newUser = {
      walletAddress: publicKey.toString(),
      tradingWallet: tradingWalletData
    };

    console.debug('Creating user with trading wallet:', newUser);
    
    const saveResponse = await fetch(`${process.env.REACT_APP_API_URL}api/create-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newUser)
    });

    const savedUser = await saveResponse.json();
    console.debug('User saved successfully:', savedUser);
    
    // Add delay to ensure MongoDB has processed the save
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    setHasExistingWallet(true);
    setTradingWallet(savedUser.tradingWallet);
    setUserTradingWallet(savedUser.tradingWallet.walletPublicKey);
    localStorage.setItem('tradingWallet', JSON.stringify(savedUser.tradingWallet));
    
    // Force a check of the wallet after saving
    await checkWallet();
    toast.success('Trading wallet created successfully!');
    await completeWalletCreationTask();
    
    // Update completed tasks
    const updatedUserData = await updateUserPoints(publicKey.toString(), 'tradingWalletCreated');
    if (updatedUserData) {
      setCompletedTasks(updatedUserData.completedTasks);
      setPoints(updatedUserData.points);
    }

  } catch (error) {
    console.error('Failed to create/retrieve trading wallet:', error);
    toast.error(`Failed to create/retrieve trading wallet: ${error.message}`);
  } finally {
    setIsCreatingWallet(false);
  }
};
const clearWalletState = () => {
  console.debug('Clearing wallet state');
  setIsSignatureVerified(false);
  localStorage.removeItem('signatureVerified');
  setWallet(null);
  setHasExistingWallet(false);
  setUserTradingWallet('');
  setTradingWallet(null);
  setPoints(0);
  setCompletedTasks(prev => ({
    ...prev,
    walletConnected: false
  }));
  localStorage.removeItem('walletConnected');
  localStorage.removeItem('tradingWallet');
};
const checkRichApeStatus = async () => {
  console.debug('Checking Rich Ape status...', {
    bnnaBalance,
    publicKey: publicKey?.toString()
  });

  if (!publicKey || !bnnaBalance) {
    console.debug('Skipping Rich Ape check - missing requirements');
    return;
  }

  const RICH_APE_THRESHOLD = 50000000;
  // const RICH_APE_THRESHOLD = 10000;

  const isRichApe = bnnaBalance >= RICH_APE_THRESHOLD;
  
  console.debug('Rich Ape status check:', {
    currentBalance: bnnaBalance,
    threshold: RICH_APE_THRESHOLD,
    qualifies: isRichApe,
    currentTaskStatus: completedTasks?.richApe
  });

  // Only proceed if user qualifies AND task isn't already completed
  if (isRichApe && completedTasks?.richApe === false) {
    try {
      console.log('Updating rich ape status to true');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/update-user-points`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress: publicKey.toString(),
          taskName: 'richApe',
          taskName: 'bananaMillionaire'
        })
      });

      const data = await response.json();
      console.debug('Rich Ape status update response:', data);

      if (data.success) {
        setCompletedTasks(data.completedTasks);
        setPoints(data.points);
        toast.success('🎉 Congratulations! You\'ve become a Rich Ape!');
      }
    } catch (error) {
      console.error('Error updating Rich Ape status:', error);
    }
  } else {
    console.debug('Skipping Rich Ape update:', {
      alreadyCompleted: completedTasks?.richApe,
      meetsThreshold: isRichApe
    });
  }
};
const requestSignature = async () => {
  console.debug('Requesting signature verification');
  if (!publicKey || !signMessage) {
    console.error('Wallet not properly connected or doesn\'t support signing');
    throw new Error('Wallet not properly configured for signing');
  }

  const message = new TextEncoder().encode(
    'Please sign this message to verify your wallet ownership and complete the connection process.'
  );

  try {
    console.debug('Initiating signature request');
    const signature = await signMessage(message);
    console.debug('Signature received, verifying...');
    
    // Convert signature to Uint8Array if it isn't already
    const signatureUint8 = new Uint8Array(signature);
    
    // Verify using native verify method from PublicKey
    const isValid = publicKey.toBytes().length === 32 && signatureUint8.length === 64;
    
    if (!isValid) {
      console.error('Signature verification failed - invalid format');
      await disconnect()
      clearWalletState();
      throw new Error('Invalid signature format');
    }

    console.debug('Signature verified successfully:', bs58encode(signature));
    setIsSignatureVerified(true);
    return { signature, verified: true };
  } catch (error) {
    console.error('Signature request failed:', error);
    
    // Specifically handle user rejection without throwing
    if (error.message.includes('User rejected')) {
      console.debug('User rejected signature request');
      toast.error('You must sign the message to complete the connection');
      await disconnect();
      clearWalletState();
      return { signature: null, verified: false, userRejected: true };
    }
    
    toast.error('Failed to verify wallet ownership');
    await disconnect();
    clearWalletState();
    return { signature: null, verified: false, error };
  }
};
const formatBalance = (balance) => {
  if (balance === null || balance === undefined) {
    console.debug('Formatting null/undefined balance to 0');
    return '0.0000';
  }
  try {
    const num = parseFloat(balance);
    return isNaN(num) ? '0.0000' : num.toFixed(4);
  } catch (error) {
    console.error('Balance formatting error:', error);
    return '0.0000';
  }
};
const handleConnection = async () => {
  console.debug('Handling wallet connection:', {
    connected,
    publicKey: publicKey?.toString(),
    isSignatureVerified,
    hasExistingWallet
  });

  if (!connected || !publicKey) {
    console.debug('Wallet disconnected or no public key, clearing state');
    clearWalletState();
    return;
  }

  const wasVerified = localStorage.getItem('signatureVerified');
  
  if (wasVerified === 'true') {
    console.debug('Found previous signature verification');
    setIsSignatureVerified(true);
  }

  if (!isSignatureVerified && !wasVerified) {
    console.debug('Requesting signature verification');
    const { verified, userRejected } = await requestSignature();
    
    if (userRejected || !verified) {
      console.debug('Signature verification failed or rejected');
      clearWalletState();
      return;
    }
  }

  console.debug('Signature verified, fetching user data');
  try {
    // Fetch complete user details
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/users/${publicKey.toString()}`
    );

    if (!response.ok) {
      if (response.status === 404) {
        console.debug('No existing user found');
        setHasExistingWallet(false);
        return;
      }
      throw new Error(`Failed to fetch user data: ${response.status}`);
    }

    const userData = await response.json();
    setUserDetails(userData);
    console.debug('Received user data:', userData);

    // Update all relevant state from user data
    if (userData.tradingWallet) {
      setTradingWallet(userData.tradingWallet);
      setUserTradingWallet(userData.tradingWallet.walletPublicKey);
      setHasExistingWallet(true);
      localStorage.setItem('tradingWallet', JSON.stringify(userData.tradingWallet));
    }

    // Update points and completed tasks
    if (userData.points !== undefined) {
      setPoints(userData.points);
    }
    if (userData.completedTasks) {
      setCompletedTasks(userData.completedTasks);
    }

    // Update premium status if available
    if (userData.premium) {
      setIsPremium(userData.premium.tier === 'PREMIUM');  // Will be true/false
    }

    // Set wallet connection state
    localStorage.setItem('walletConnected', 'true');
    setWallet({
      publicKey: publicKey,
      connected: true
    });

    // Update check status
    previousPublicKey.current = publicKey.toString();
    setHasCheckedWallet(true);

  } catch (error) {
    console.error('User data fetch error:', error);
    clearWalletState();
    toast.error('Failed to complete wallet setup');
  }
};

const balanceCheckThrottle = useRef({
  lastCheck: 0,
  minInterval: 8000, // Increase to 15 seconds minimum between checks
  inProgress: false,
  retryCount: 0,
  maxRetries: 3,
  retryDelay: 1000 // Base retry delay
});
const updateTask = async (taskName) => {
  if (!publicKey) {
    toast.error("Please connect your wallet first");
    return;
  }

  try {
    console.log(`Updating task: ${taskName}`);
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/update-user-points`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress: publicKey.toString(),
        taskName,
      }),
    });

    const data = await response.json();
    console.log('Task update response:', data);

    if (data.success) {
      setCompletedTasks(data.completedTasks);
      setPoints(data.points);
      toast.success(`Task ${taskName} completed!`);
    } else {
      console.error(`Failed to update task ${taskName}:`, data.error);
      toast.error('Failed to update task');
    }
  } catch (error) {
    console.error('Error updating task:', error);
    toast.error('Error updating task');
  }
};
const verifyTaskState = async () => {
  if (!publicKey) return;
  
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/user-points/${publicKey.toString()}`);
    const data = await response.json();
    
    console.log('UI State:', completedTasks);
    console.log('Server State:', data.completedTasks);
    
    // Check for mismatches
    Object.keys(data.completedTasks).forEach(task => {
      if (completedTasks[task] !== data.completedTasks[task]) {
        console.warn(`Task state mismatch for ${task}:`, {
          ui: completedTasks[task],
          server: data.completedTasks[task]
        });
      }
    });
  } catch (error) {
    console.error('Error verifying task state:', error);
  }
};
useEffect(() => {
  if (connected && publicKey) {
    verifyTaskState();
  }
}, [connected, publicKey]);
const throttledBalanceCheck = async () => {
  setIsSolLoading(true);

  await requestQueue.add(async () => {
    const now = Date.now();
  if (
    balanceCheckThrottle.current.inProgress ||
    now - balanceCheckThrottle.current.lastCheck < balanceCheckThrottle.current.minInterval
  ) {
    console.debug('Skipping balance check due to throttling.');
    return null;
  }

  try {
    console.debug('Starting balance check...');
    balanceCheckThrottle.current.inProgress = true;
    balanceCheckThrottle.current.lastCheck = now;

    const balancePromises = [];
    if (publicKey) {
      console.debug('Fetching connected wallet balance for:', publicKey.toString());
      balancePromises.push(
        fetch(`${process.env.REACT_APP_API_URL}api/sol-balance/${publicKey.toString()}`)
          .then((res) => (res.ok ? res.json() : Promise.reject('Failed to fetch connected wallet balance')))
      );
    } else {
      console.warn('No public key found for connected wallet.');
    }
    await new Promise(resolve => setTimeout(resolve, 3000));

    if (tradingWallet?.walletPublicKey) {
      console.debug('Fetching trading wallet balance for:', tradingWallet.walletPublicKey);
      balancePromises.push(
        fetch(`${process.env.REACT_APP_API_URL}api/sol-balance/${tradingWallet.walletPublicKey}`)
          .then((res) => (res.ok ? res.json() : Promise.reject('Failed to fetch trading wallet balance')))
      );
    } else {
      console.warn('No trading wallet public key found.');
    }

    const results = await Promise.allSettled(balancePromises);
    const [connectedBal, tradingBal] = results.map((result) =>
      result.status === 'fulfilled' ? result.value?.balance : null
    );

    if (connectedBal !== null) setConnectedWalletBalance(connectedBal);
    if (tradingBal !== null) setTradingWalletBalance(tradingBal);

    return {
      connectedBalance: connectedBal,
      tradingBalance: tradingBal,
    };
  } catch (error) {
    console.error('Error during balance check:', error);
    return null;
  } finally {
    balanceCheckThrottle.current.inProgress = false;
    setIsSolLoading(false);
  }
  });
  
};
useEffect(() => {
  let isActive = true;
  const DELAY = 2000; // Base delay between operations

  const handleWalletOperations = async () => {
    if (!isActive) return;

    try {
      // 1. Handle initial connection
      if (connected && publicKey) {
        console.debug('Initializing wallet connection...');
        await handleConnection();
        // Only check task completion once after wallet connection
        if (tradingWallet?.walletPublicKey && !completedTasks?.tradingWalletCreated) {
          await completeWalletCreationTask();
        }
        await new Promise(resolve => setTimeout(resolve, DELAY));

        // 2. Sync wallet state if needed
        if (!wallet?.publicKey) {
          console.log('Syncing wallet state...');
          setWallet({
            publicKey: publicKey,
            connected: true
          });
          await new Promise(resolve => setTimeout(resolve, DELAY));
        }

        // 3. Check balances
        if (isActive) {
          console.debug('Checking balances...');
          const balances = await throttledBalanceCheck();
          if (balances && isActive) {
            if (balances.connectedBalance !== null) {
              setConnectedWalletBalance(balances.connectedBalance);
            }
            if (balances.tradingBalance !== null) {
              setTradingWalletBalance(balances.tradingBalance);
            }
          }
          await new Promise(resolve => setTimeout(resolve, DELAY));
        }

        const checkPremiumStatus = async () => {
          if (!publicKey) return;
          
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}api/premium/status/${publicKey.toString()}`
            );
            const data = await response.json();
            setIsPremium(data.isPremium);
          } catch (error) {
            console.error('Premium status check failed:', error);
          }
        };
      
        checkPremiumStatus();
        await new Promise(resolve => setTimeout(resolve, DELAY));


        // 4. Check BNNA balance if trading wallet exists
        if (isActive && tradingWallet?.walletPublicKey) {
          console.debug('Fetching BNNA balance...');
          await fetchBnnaBalance();
          await new Promise(resolve => setTimeout(resolve, DELAY));

          // 5. Update trading wallet task status
          if (!completedTasks?.tradingWalletCreated) {
            console.log('Updating trading wallet task status...');
            setCompletedTasks(prev => ({
              ...prev,
              tradingWalletCreated: true
            }));
            setPoints(prev => prev + 200);
            toast.success('🎉 Trading Wallet Creation task completed!');
          }
        }
      } else if (!connected && wallet?.publicKey) {
        console.log('Clearing wallet state due to disconnection');
        clearWalletState();
      }
    } catch (error) {
      console.error('Wallet operations error:', error);
      if (isActive) {
        clearWalletState();
      }
    }
  };

  // Initial delay before starting operations
  const initTimeout = setTimeout(handleWalletOperations, 1000);

  // Set up interval for balance checks
  const checkInterval = setInterval(() => {
    if (connected && publicKey) {
      throttledBalanceCheck();
    }
  }, BALANCE_CHECK_INTERVAL);

  // Visibility change handler
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && connected && publicKey) {
      setTimeout(throttledBalanceCheck, DELAY);
    }
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);

  return () => {
    isActive = false;
    clearTimeout(initTimeout);
    clearInterval(checkInterval);
    document.removeEventListener('visibilitychange', handleVisibilityChange);
    console.debug('Cleanup: wallet operations effect');
  };
}, [connected, publicKey, tradingWallet?.walletPublicKey, wallet?.publicKey, isSignatureVerified, completedTasks?.tradingWalletCreated]);

  return (
    
    <StyledContainer maxWidth="sm" >
       <StyledWalletButton>
              <WalletMultiButton />
            </StyledWalletButton>
     <Box 
  sx={{ 
    position: 'relative', // Add this to contain absolute video
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 3,
    mb: 8,
    minHeight: '100%',
    overflow: 'hidden', // Ensure video doesn't spill out
    backgroundImage: 'url(https://cdn.pixabay.com/photo/2024/04/25/06/50/banana-8719086_1280.jpg)',
    
  }}
>
  
  
        <StyledPaper  elevation={24}>
          
          <Stack spacing={3} alignItems="center">
           
          <EnhancedContainer  logoImage={logoImage} hasExistingWallet={hasExistingWallet} LatestTrades={LatestTrades}>
  <Button
    variant="contained"
    onClick={() => setShowVideoModal(true)}
    startIcon={<PlayCircle />}
    sx={{
      width: '100%',
      mt: 2,
      py: 2,
      background: 'linear-gradient(to right, #3b82f6, #2563eb)',
      '&:hover': {
        background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
      },
    }}
  >
    Watch How It Works
  </Button>
  <Button
  variant="contained"
  onClick={() => setIsTokenDetailsOpen(true)}
  startIcon={<ContentCopy />}
  sx={{
    width: '100%',
    py: 2,
    background: 'linear-gradient(to right, #3b82f6, #2563eb)',
    '&:hover': {
      background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
    }
  }}
>
  View Token Details
</Button>

  
  {/* Other components */}
  <Button
  variant="contained"
  onClick={() => setIsUtilitiesModalOpen(true)}
  startIcon={<Calculate />}
  sx={{
    width: '100%',
    py: 2,
    background: 'linear-gradient(to right, #10b981, #059669)',
    '&:hover': {
      background: 'linear-gradient(to right, #059669, #047857)',
    }
  }}
>
  Open Utilities
</Button>
<PremiumTriggerButton 
  setShowPremiumModal={setShowPremiumModal}
  isPremium={isPremium}
  className="my-4" // Optional: Add additional classes
/>

</EnhancedContainer>

            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
             
              {connected && hasExistingWallet && userTradingWallet && (

  <Box 
    sx={{ 
      width: '100%',
      p: 2,
      mt: 2,
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '8px',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    }}
  >{/* Add null check for tradingWallet */}
  {tradingWallet?.walletPublicKey && (
    <>
      <Button
  variant="contained"
  onClick={() => setIsAchievementsOpen(true)}
  startIcon={<EmojiEvents />}
  sx={{
    width: '100%',
    py: 1.5,
    background: 'linear-gradient(to right, #fbbf24, #d97706)',
    '&:hover': {
      background: 'linear-gradient(to right, #d97706, #b45309)',
    }
  }}
>
  View Achievements
</Button>
   
    </>
   )}
  <Box>
  <SectionDivider 
    title="Wallet Information" 
    icon={<Wallet sx={{ color: '#fbbf24' }} />} 
  />
    <Typography 
  variant="h5" // Changed from "title" to "h5" for larger size
  sx={{ 
    color: '#FFFFFF', // Changed to solid white
    mb: 1,
    paddingTop: '10px',
    marginTop: '10px',
    fontWeight: 600, // Added bold weight
    letterSpacing: '0.5px', // Added letter spacing for better readability
    textShadow: '0 2px 4px rgba(0,0,0,0.2)', // Added subtle text shadow
    // textDecoration: 'underline',
  }}
>
  Your Trading Wallet
</Typography>
<a 
  href="https://apeout.meme/trading-wallet" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-sm hover:text-gray-300 transition-colors"
  style={{ 
    textDecoration: 'underline', 
    fontSize: '0.75rem',
    marginTop: '4px',
    marginBottom: '4px',
    display: 'block',
    color: '#FFFFFF',  // Changed to white
    opacity: 0.9,      // Added slight transparency
    '&:hover': {       // Added hover effect
      opacity: 1,
      textShadow: '0 0 8px rgba(255,255,255,0.3)'
    }
  }}
>
  How Does It Work?
</a>
<Box sx={{ 
  display: 'flex', 
  gap: 2, 
  mt: 2,
  mb: 2,
  width: '100%'
}}>
  <Button
    onClick={() => {
      if (tradingWallet?.walletPublicKey) {
        window.open(getPumpProfileUrl(tradingWallet.walletPublicKey), '_blank', 'noopener,noreferrer');
      } else {
        toast.warn('Please connect your trading wallet to view your profile');
      }
    }}
    variant="contained"
    fullWidth
    sx={{
      py: 1.5,
      background: 'linear-gradient(to right, #3b82f6, #2563eb)',
      '&:hover': {
        background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
      },
    }}
    startIcon={<LaunchIcon />}
  >
    View Profile
  </Button>

  <Button
    onClick={() => setShowOwnedTokens(true)}
    variant="contained"
    fullWidth
    sx={{
      py: 1.5,
      background: 'linear-gradient(to right, #10b981, #059669)',
      '&:hover': {
        background: 'linear-gradient(to right, #059669, #047857)',
      },
    }}
    startIcon={<AccountBalanceWalletIcon />}
  >
    View Balances
  </Button>
</Box>
<Dialog 
        open={showOwnedTokens} 
        onClose={() => setShowOwnedTokens(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle sx={{ 
          color: 'black', 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center' 
        }}>
          Owned Tokens
          <IconButton 
            onClick={() => setShowOwnedTokens(false)} 
            sx={{ '& svg': { color: '#22c55e' } }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TokenBalanceDisplay 
            tradingWallet={tradingWallet}
            handleSell={handleSell}
            tokenPurchases={tokenPurchases}
            isLoading={isLoadingBalances}
            onRefresh={handleRefreshBalances}
          />
          <TokenPurchaseHistory 
            tokenPurchases={tokenPurchases}
            currentMarketCaps={Object.fromEntries(
              Object.entries(tokenDetailsMap).map(([mint, details]) => [
                mint, 
                details.marketCap
              ])
            )}
          />
        </DialogContent>
      </Dialog>
      {tradingWalletBalance !== null && typeof tradingWalletBalance === 'number' ? (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <Typography variant="subtitle1" sx={{ color: '#4ade80', display: 'flex', alignItems: 'center', gap: 1 }}>
      Wallet Balance: {isSolLoading ? (
        <CircularProgress size={16} sx={{ color: '#4ade80' }} />
      ) : (
        `${formatBalance(tradingWalletBalance)} SOL`
      )}
    </Typography>
    <IconButton
      onClick={() => {
        navigator.clipboard.writeText(formatBalance(tradingWalletBalance));
        toast.success('Balance copied to clipboard!');
      }}
      size="small"
      sx={{ color: '#4ade80' }}
    >
      <ContentCopy fontSize="small" />
    </IconButton>
  </Box>
) : (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
  {/* SOL Balance */}
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <Typography variant="subtitle2" sx={{ color: '#4ade80', display: 'flex', alignItems: 'center', gap: 1 }}>
      Wallet Balance: {isSolLoading ? (
        <CircularProgress size={16} sx={{ color: '#4ade80' }} />
      ) : (
        '0.0000 SOL'
      )}
    </Typography>
    <IconButton
      onClick={() => {
        navigator.clipboard.writeText('0.0000');
        toast.success('Balance copied to clipboard!');
      }}
      size="small"
      sx={{ color: '#4ade80' }}
    >
      <ContentCopy fontSize="small" />
    </IconButton>
  </Box>

  {/* BNNA Balance */}
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <Typography variant="subtitle2" sx={{ color: '#fbbf24', display: 'flex', alignItems: 'center', gap: 1 }}>
      BNNA Balance: {isBnnaLoading ? (
        <CircularProgress size={16} sx={{ color: '#fbbf24' }} />
      ) : (
        `${bnnaBalance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })} BNNA`
      )}
    </Typography>
    <IconButton
      onClick={() => {
        navigator.clipboard.writeText(bnnaBalance.toString());
        toast.success('BNNA balance copied to clipboard!');
      }}
      size="small"
      sx={{ color: '#fbbf24' }}
    >
      <ContentCopy fontSize="small" />
    </IconButton>
  </Box>
</Box>
)}
<Box 
  sx={{ 
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    background: 'rgba(0, 0, 0, 0.2)',
    p: 1.5,
    borderRadius: '6px',
    wordBreak: 'break-all',
    mb: 2 // Add margin bottom for separation
  }}
>
  <Typography 
    variant="body2" 
    sx={{ 
      color: 'white',
      flex: 1,
      fontFamily: 'monospace'
    }}
  >
    Public Address: {userTradingWallet}
  </Typography>
  <Button
    size="small"
    onClick={() => {
      navigator.clipboard.writeText(tradingWallet?.walletPublicKey);
      toast.success('Public address copied to clipboard!');
    }}
    sx={{
      minWidth: 'auto',
      p: 1,
      color: 'white',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.1)'
      }
    }}
  >
    <Receipt sx={{ fontSize: 20 }} />
  </Button>
</Box>

{/* Second box for private key */}
<Box 
  sx={{ 
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    background: 'rgba(255, 0, 0, 0.1)', // Slightly red background to indicate sensitivity
    p: 1.5,
    borderRadius: '6px',
    wordBreak: 'break-all',
    border: '1px solid rgba(255, 0, 0, 0.2)' // Red border for emphasis
  }}
>
  <Typography 
    variant="body2" 
    sx={{ 
      color: 'white',
      flex: 1,
      fontFamily: 'monospace'
    }}
  >
    PRIVATE KEY (KEEP THIS SECRET!)
  </Typography>
  <Button
    size="small"
    onClick={() => {
      navigator.clipboard.writeText(tradingWallet.privateKey);
      toast.success('Private key copied to clipboard!');
    }}
    sx={{
      minWidth: 'auto',
      p: 1,
      color: 'white',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.1)'
      }
    }}
  >
    <Receipt sx={{ fontSize: 20 }} />
  </Button>
</Box>
</Box>
   
  </Box>
)}

{connected && (
                hasExistingWallet ? (
                  <>
                  <SectionDivider 
  title="Explore" 
  icon={<Update sx={{ color: '#fbbf24' }} />} 
/>
                  <Button 
  variant="contained"
  onClick={() => setShowBuyModal(true)}
  sx={{
    width: '100%',
    py: 2,
    background: 'linear-gradient(to right, #10b981, #059669)',
    '&:hover': {
      background: 'linear-gradient(to right, #059669, #047857)',
    },
  }}
>
  Buy $BNNA
  <span style={{ 
    marginLeft: '8px', 
    fontSize: '0.8em', 
    backgroundColor: 'rgba(255,255,255,0.1)',
    padding: '2px 6px',
    borderRadius: '4px',
    display: 'inline-flex',
    alignItems: 'center'
  }}>
    {isBnnaLoading ? (
      <CircularProgress size={12} sx={{ color: 'white' }} />
    ) : bnnaBalance > 0 ? (
      bnnaBalance.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    ) : '0.00'}
  </span>
</Button>
                  <Button disabled
                    variant="contained"
                    onClick={() => setShowBuyModal(true)}
                    sx={{
                      width: '100%',
                      py: 2,
                      background: 'linear-gradient(to right, #dc2626, #b91c1c)', // Changed to red gradient
                      '&:hover': {
                        background: 'linear-gradient(to right, #b91c1c, #991b1b)', // Darker red on hover
                      },
                      transition: 'all 0.3s ease', // Smooth transition
                      boxShadow: '0 4px 6px rgba(220, 38, 38, 0.2)', // Red-tinted shadow
                      '&:active': {
                        transform: 'translateY(1px)', // Subtle press effect
                      }
                    }}
                  >
                    Burn $BNNA
                    {bnnaBalance > 0 && (
                      <span style={{ 
                        marginLeft: '8px', 
                        fontSize: '0.8em', 
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        padding: '2px 6px',
                        borderRadius: '4px'
                      }}>
                        {bnnaBalance.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </span>
                    )}
                  </Button>
                  <Button 
                    variant="contained"
                    onClick={() => navigate('/banana-stand')}
                    sx={{
                      width: '100%',
                      py: 2,
                      background: 'linear-gradient(to right, #fbbf24, #d97706)', // Changed to yellow/gold gradient
                      '&:hover': {
                        background: 'linear-gradient(to right, #d97706, #b45309)', // Darker yellow/gold on hover
                      },
                      transition: 'all 0.3s ease',
                      boxShadow: '0 4px 6px rgba(251, 191, 36, 0.2)', // Yellow-tinted shadow
                      '&:active': {
                        transform: 'translateY(1px)',
                      }
                    }}
                  >
                    Lock $BNNA
                    {bnnaBalance > 0 && (
                      <span style={{ 
                        marginLeft: '8px', 
                        fontSize: '0.8em', 
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        padding: '2px 6px',
                        borderRadius: '4px'
                      }}>
                        {bnnaBalance.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </span>
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setIsEarnModalOpen(true)}
                    sx={{
                      width: '100%',
                      py: 2,
                      background: 'linear-gradient(to right, #fbbf24, #d97706)',
                      '&:hover': {
                        background: 'linear-gradient(to right, #d97706, #b45309)',
                      }
                    }}
                    startIcon={<Savings sx={{ color: '#fff' }} />}
                  >
                    Earn More $BNNA
                  </Button>
                  
                 
                  </>
                  
                ) : (
                  <Button
  variant="contained"
  onClick={createTradingWallet}
  disabled={isCreatingWallet}
  sx={{
    width: '100%',
    py: 2,
    background: 'linear-gradient(to right, #22c55e, #10b981)',
    '&:hover': {
      background: 'linear-gradient(to right, #16a34a, #059669)',
    },
    '&:disabled': {
      background: 'linear-gradient(to right, #6b7280, #4b5563)',
      cursor: 'not-allowed',
    },
  }}
>
  {isCreatingWallet ? (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <CircularProgress size={20} color="inherit" />
      Creating Wallet...
    </Box>
  ) : (
    'Create Trading Wallet'
  )}
</Button>
                )
              )}
            </Box>
          </Stack>
        </StyledPaper>
      </Box>
     
   
            

            

<VideoModal
  open={showVideoModal}
  onClose={() => {
    setShowVideoModal(false);
    setIsPlaying(false); // Reset playing state when modal closes
  }}
  isPlaying={isPlaying}
  setIsPlaying={setIsPlaying}
/>



{/* {publicKey?.toString() === process.env.REACT_APP_ADMIN_WALLET && (
    <ConsoleButton
      onClick={() => setShowConsoleModal(true)}
      startIcon={<Terminal />}
    >
      View Console
    </ConsoleButton>
  )} */}

<SocialFooter>
<SocialIconButton
href="https://x.com/apeoutmeme"
target="blank"
aria-label="Twitter"
>
<TwitterIcon />
</SocialIconButton>
<SocialIconButton
href="https://t.me/apeoutmeme
"
target="blank"
aria-label="Telegram"
>
<TelegramIcon />
</SocialIconButton>
<SocialIconButton
href="https://github.com/apeoutmeme/apeoutdotfun-
"
target="blank"
aria-label="GitHub"
>
<GitHubIcon />
</SocialIconButton>
<SocialIconButton
href="https://www.youtube.com/shorts/f4OeszM6ukE"
target="blank"
aria-label="YouTube"
>

<YouTubeIcon />
</SocialIconButton>
<SocialIconButton
href="https://apeout.meme/whitepaper"
target="blank"
aria-label="Whitepaper"
>
<Receipt />
</SocialIconButton>
<SocialIconButton
href="https://apeout.com"
target="blank"
aria-label="Whitepaper"
>
<VideogameAsset />
</SocialIconButton>
<SocialIconButton
href="https://medium.com/@apeoutcrypto"
target="blank"
aria-label="Whitepaper"
>
<Book />
</SocialIconButton>
<SocialIconButton
  href="https://tiktok.com/@apeoutmeme" // Add your TikTok URL
  target="_blank"
  rel="noopener noreferrer"
  aria-label="TikTok"
>
  <FaTiktok />
</SocialIconButton>
{showPumpAssessment && (
  <PumpAssessmentModal
    open={showPumpAssessment}
    onClose={() => setShowPumpAssessment(false)}
    publicKey={publicKey}
    setCompletedTasks={setCompletedTasks}
    setPoints={setPoints}
  />
)}
</SocialFooter>

<ToastContainer />
<div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4">
  {/* <VideoPlayer src={dexPaidVideo} orientation="horizontal" /> */}
  {/* <VideoPlayer src={wojakVideo} orientation="vertical" /> */}
  <WojakStorySection wojakVideo={wojakVideo} />
</div>
<Box sx={{
position: 'fixed',
bottom: 70, // Positioned above the social footer
left: 0,
width: '100%',
display: 'flex',
justifyContent: 'center',
padding: '8px',
background: 'rgba(0,0,0,0.5)',
backdropFilter: 'blur(5px)'
}}>
<DisclaimerLink onClick={() => setShowDisclaimer(true)}>
Trading Disclaimer & Risk Warning
</DisclaimerLink>
</Box>

    <DemoAutoApeModal
      open={showDemoModal}
      onClose={() => setShowDemoModal(false)}
      tradingWallet={tradingWallet}
      handleBuy={handleBuy}
      ownedTokensData={Array.isArray(ownedTokens) ? ownedTokens : []}
      handleSell={handleSell}
      tradeSettings={tradeSettings}
      setTradeSettings={setTradeSettings}
      tokenPurchases={userDetails?.tokenPurchases || []} 


    />
    <BuyModal
  open={showBuyModal}
  onClose={() => setShowBuyModal(false)}
  onBuy={handleBuyClick}
/>

{/* <VideoPlayer src={aiVideo}className="custom-video-class" /> */}

{/* <button 
    className="test-fee-button"
    onClick={sendDevFee}
  >
    💰 Test Fee
  </button> */}

  <PremiumModal 
  open={showPremiumModal}
  onClose={() => setShowPremiumModal(false)}
  wallet={wallet}
  connection={connected}
  tradingWallet={tradingWallet}
  publicKey={publicKey}
/>
<AutoBumpDemoModal
  open={showAutoBumpModal}
  onClose={() => setShowAutoBumpModal(false)}
  tradingWallet={tradingWallet}
  handleBuy={handleBuy}
  tradeSettings={tradeSettings}
  setTradeSettings={setTradeSettings}
/>
<ConsoleLogModal
    open={showConsoleModal}
    onClose={() => setShowConsoleModal(false)}
  />

<DisclaimerModal
open={showDisclaimer}
onClose={() => setShowDisclaimer(false)}
/>
<AchievementsModal
  isAchievementsOpen={isAchievementsOpen}
  onClose={() => setIsAchievementsOpen(false)}
  connected={connected}
  hasExistingWallet={hasExistingWallet}
  userTradingWallet={userTradingWallet}
  tradingWallet={tradingWallet}
  points={points}
  completedTasks={completedTasks}
  isSignatureVerified={isSignatureVerified}
  publicKey={publicKey}
/>
<Modal
  open={isEarnModalOpen}
  onClose={() => setIsEarnModalOpen(false)}
  aria-labelledby="earn-bnna-modal"
>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '90vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    p: 4,
  }}>
    <StyledBannerSection>
      <IconButton
        onClick={() => setIsEarnModalOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
        }}
      >
        <CloseIcon />
      </IconButton>
      
      <StyledTitle>
        <Savings sx={{ color: '#fbbf24' }} />
        Earn More $BNNA
        <Savings sx={{ color: '#fbbf24' }} />
      </StyledTitle>
      
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Box
          component="img"
          src={bnnaImage}
          alt="App Logo"
          sx={{
            width: { 
              xs: 120,
              sm: 150,
              md: 180
            },
            height: 'auto',
            animation: `${bounce} 2s ease-in-out infinite`,
            margin: '10px 0',
          }}
        />
      </Box>
      
      <ButtonContainer>
        <MetallicButton
          variant="gold"
          onClick={async () => {
            if (!publicKey) {
              toast.error("Please connect your wallet first");
              return;
            }
            await updateTask('bananaResearcher');
            window.open('https://apeout.meme/whitepaper', '_blank', 'noopener,noreferrer');
          }}
          startIcon={<span role="img" aria-label="banana">🍌</span>}
        >
          Go To Website
        </MetallicButton>

        <MetallicButton
          variant="contained"
          onClick={async () => {
            await updateTask('bananaGamer');
            window.open('https://apeout.meme/game/game.html', '_blank', 'noopener,noreferrer');
          }}
          startIcon={<span role="img" aria-label="banana">🍌</span>}
        >
          Play Our Game
        </MetallicButton>

        <MetallicButton
          variant="contained"
          onClick={async () => {
            window.open('https://t.me/apeoutmeme', '_blank', 'noopener,noreferrer');
          }}
          startIcon={<span role="img" aria-label="banana">🍌</span>}
        >
          Join Giveaway
        </MetallicButton>

        <MetallicButton
          variant="contained"
          onClick={async () => {
            window.open('https://apeout.fun/burn', '_blank', 'noopener,noreferrer');
          }}
          startIcon={<span role="img" aria-label="banana">🍌</span>}
        >
          View Burn Dashboard
        </MetallicButton>

        <MetallicButton
          variant="contained"
          onClick={async () => {
            window.open('https://apeout.fun/banana-stand', '_blank', 'noopener,noreferrer');
          }}
          startIcon={<span role="img" aria-label="banana">🍌</span>}
        >
          Visit Banana Stand
        </MetallicButton>
      </ButtonContainer>
    </StyledBannerSection>
  </Box>
</Modal>
<Modal
  open={isTokenDetailsOpen}
  onClose={() => setIsTokenDetailsOpen(false)}
  aria-labelledby="token-details-modal"
>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '90vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    p: 4,
  }}>
    <IconButton
      onClick={() => setIsTokenDetailsOpen(false)}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: 'white',
        zIndex: 1,
      }}
    >
      <CloseIcon />
    </IconButton>

    <StyledTokenSection>
      <ContractInfoBox>
        <ContractLabel>Contract Address:</ContractLabel>
        <AddressContainer>
          <ContractAddress>
            DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump
          </ContractAddress>
          <CopyButton
            size="small"
            onClick={() => {
              navigator.clipboard.writeText('DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump');
              toast.success('Contract address copied!');
            }}
            sx={{
              color: 'white',
              padding: '4px',
              minWidth: 'auto',
              flexShrink: 0,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }
            }}
          >
            <ContentCopy fontSize="small" />
          </CopyButton>
        </AddressContainer>
      </ContractInfoBox>

      <TokenChart />
      <TokenStats />

      <LogosContainer>
        <Logo 
          src={bnnaImage} 
          alt="BNNA Logo"
        />
        <Logo 
          src={pumpImage} 
          alt="Dex Paid Logo"
        />
      </LogosContainer>
    </StyledTokenSection>
  </Box>
</Modal>
<Modal
  open={isUtilitiesModalOpen}
  onClose={() => setIsUtilitiesModalOpen(false)}
  aria-labelledby="utilities-modal"
>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '90vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    p: 4,
  }}>
    <IconButton
      onClick={() => setIsUtilitiesModalOpen(false)}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: 'white',
        zIndex: 1,
      }}
    >
      <CloseIcon />
    </IconButton>

    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      gap: 3,
      pb: 2 
    }}>
      <Typography variant="h5" sx={{ 
        textAlign: 'center',
        color: 'white',
        mb: 2,
        fontWeight: 'bold'
      }}>
        Trading Tools
      </Typography>

      <WalletAndBuyButtons />
      <BnnaConverterModal />
      <GainsCalculator />
      
    </Box>
  </Box>
</Modal>
<PremiumModal 
        isOpen={showPremiumModal}
        setIsOpen={setShowPremiumModal}
        publicKey={publicKey}
        tradingWallet={tradingWallet}
        handleBuy={handleBuy}
        handleAutoTPSLSell={handleAutoTPSLSell}
        isPremium={isPremium}
        handleBuyWithRaydium={handleBuyWithRaydium}
        navigate={navigate}
        setShowPumpAssessment={setShowPumpAssessment}
        setShowDemoModal={setShowDemoModal}
        setShowAutoBumpModal={setShowAutoBumpModal}
      />
    </StyledContainer>
  );
};

export default AuthFlow;