import React, { useState, useEffect } from 'react';
import './XRPTokenDashboard.css';

const TokenMetricsCard = ({ label, value, change, prefix, suffix }) => {
  const isPositive = change > 0;
  return (
    <div className="metrics-card">
      <div className="metrics-label">{label}</div>
      <div className="metrics-value">
        {prefix}{value.toLocaleString('en-US', { maximumFractionDigits: 6 })}{suffix}
      </div>
      {change !== undefined && (
        <div className={`metrics-change ${isPositive ? 'positive' : 'negative'}`}>
          <span className="arrow">{isPositive ? '▲' : '▼'}</span>
          <span>{Math.abs(change)}%</span>
        </div>
      )}
    </div>
  );
};

const XRPTokenDashboard = () => {
  const [tokenData, setTokenData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/xrp/token-data`);
        if (!response.ok) throw new Error('Failed to fetch token data');
        const data = await response.json();
        setTokenData(data.pairs);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchTokenData();
    const interval = setInterval(fetchTokenData, 60000);
    return () => clearInterval(interval);
  }, []);

  if (loading) return (
    <div className="loading-container">
      <div className="loading-spinner"></div>
    </div>
  );

  if (error) return (
    <div className="error-message">
      Error loading token data: {error}
    </div>
  );

  return (
    <div className="xrp-dashboard">
      {tokenData.map((token) => (
        <div key={token.pairAddress} className="token-card">
          <div className="token-header">
            <div className="token-info">
              {token.info?.imageUrl && (
                <img 
                  src={token.info.imageUrl} 
                  alt={token.baseToken.name} 
                  className="token-image"
                />
              )}
              <div className="token-name-container">
                <h3 className="token-name">{token.baseToken.name}</h3>
                <p className="token-symbol">{token.baseToken.symbol}</p>
              </div>
            </div>
            <div className="social-links">
              {token.info?.socials?.map((social) => (
                <a
                  key={social.url}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  {social.type === 'twitter' ? '𝕏' : 
                   social.type === 'telegram' ? '📱' : 
                   social.type === 'discord' ? '💬' : '🌐'}
                </a>
              ))}
            </div>
          </div>

          <div className="metrics-grid">
            <TokenMetricsCard
              label="Price"
              value={parseFloat(token.priceUsd)}
              change={token.priceChange.h24}
              prefix="$"
            />
            <TokenMetricsCard
              label="24h Volume"
              value={token.volume.h24}
              prefix="$"
            />
            <TokenMetricsCard
              label="Market Cap"
              value={token.marketCap}
              prefix="$"
            />
            <TokenMetricsCard
              label="Liquidity"
              value={token.liquidity.usd}
              prefix="$"
            />
          </div>

          <div className="trading-info">
            <div className="transactions-card">
              <h4 className="card-title">24h Transactions</h4>
              <div className="transactions-data">
                <div className="buys">Buys: {token.txns.h24.buys}</div>
                <div className="sells">Sells: {token.txns.h24.sells}</div>
              </div>
            </div>
            <div className="price-changes">
              <h4 className="card-title">Price Change</h4>
              <div className="timeframes">
                {['5m', '1h', '6h', '24h'].map((period, index) => {
                  const periodKey = Object.keys(token.priceChange)[index];
                  const change = token.priceChange[periodKey];
                  const isPositive = change > 0;
                  return (
                    <div key={period} className="timeframe">
                      <div className="period">{period}</div>
                      <div className={`change ${isPositive ? 'positive' : 'negative'}`}>
                        <span className="arrow">{isPositive ? '▲' : '▼'}</span>
                        {Math.abs(change)}%
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {token.info?.websites && token.info.websites.length > 0 && (
            <div className="website-link">
              <a 
                href={token.info.websites[0].url}
                target="_blank"
                rel="noopener noreferrer"
                className="website-button"
              >
                🌐 Website
              </a>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default XRPTokenDashboard;