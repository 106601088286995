import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Alert,
  Link,
  styled 
} from '@mui/material';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LaunchIcon from '@mui/icons-material/Launch';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#F3BA2F', // BNB Yellow
        light: '#FEFFEB', // ApeOut Light Beige
        dark: '#C59E57', // ApeOut Gold
      },
      secondary: {
        main: '#322E29', // ApeOut Dark
        light: '#423D36',
        dark: '#9B7940',
      },
      background: {
        default: '#14151A', // BNB Dark
        paper: 'rgba(50, 46, 41, 0.9)', // ApeOut Dark with transparency
      },
    },
  });



const StyledPaper = styled(Paper)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(243, 186, 47, 0.1), rgba(197, 158, 87, 0.2))',
  backdropFilter: 'blur(16px)',
  border: '1px solid rgba(243, 186, 47, 0.2)',
  borderRadius: '16px',
  padding: '24px',
  margin: '24px 0'
}));

const StyledListItem = styled(ListItem)({
  padding: '16px 0',
  '&:hover': {
    background: 'rgba(243, 186, 47, 0.1)',
    transition: 'all 0.3s ease'
  }
});

const StyledAlert = styled(Alert)({
  backgroundColor: 'rgba(243, 186, 47, 0.1)',
  color: '#FEFFEB',
  border: '1px solid rgba(243, 186, 47, 0.3)',
  '& .MuiAlert-icon': {
    color: '#F3BA2F'
  }
});

const IconWrapper = styled(Box)({
    color: '#F3BA2F',
    '& svg': {
      fontSize: '28px'
    }
  });
const BSCInstructions = () => {
    return (
        <StyledPaper>
          <Typography variant="h4" sx={{ color: '#FEFFEB', mb: 3, fontWeight: 'bold' }}>
            Getting Started on BSC
          </Typography>
    
          <StyledAlert severity="warning" sx={{ mb: 3 }}>
            ⚠️ Springboard is unavailable to U.S. Citizens or other restricted countries. 
            We recommend using a VPN if you are in a restricted region.
          </StyledAlert>
    
          <List>
            <StyledListItem>
              <ListItemIcon>
                <IconWrapper>
                  <VpnLockIcon />
                </IconWrapper>
              </ListItemIcon>
              <ListItemText 
                primary={
                  <Typography variant="h6" sx={{ color: '#FEFFEB' }}>
                    Ensure Safe Access
                  </Typography>
                }
                secondary={
                  <Typography sx={{ color: '#C59E57' }}>
                    Use a VPN if you're in a restricted region
                  </Typography>
                }
              />
            </StyledListItem>
    
            <StyledListItem>
              <ListItemIcon>
                <IconWrapper>
                  <AccountBalanceWalletIcon />
                </IconWrapper>
              </ListItemIcon>
              <ListItemText 
                primary={
                  <Typography variant="h6" sx={{ color: '#FEFFEB' }}>
                    Create MetaMask Wallet
                  </Typography>
                }
                secondary={
                  <Typography sx={{ color: '#C59E57' }}>
                    Phantom wallet doesn't support BNB chain. Install MetaMask from the official website
                  </Typography>
                }
              />
            </StyledListItem>
    
            <StyledListItem>
              <ListItemIcon>
                <IconWrapper>
                  <ShoppingCartIcon />
                </IconWrapper>
              </ListItemIcon>
              <ListItemText 
                primary={
                  <Typography variant="h6" sx={{ color: '#FEFFEB' }}>
                    Purchase BNB
                  </Typography>
                }
                secondary={
                  <Box>
                    <Typography sx={{ color: '#C59E57' }}>
                      Buy BNB from reputable exchanges like Binance or KuCoin
                    </Typography>
                    <Link 
                      href="https://www.coingecko.com/en/coins/bnb"
                      target="_blank"
                      sx={{ 
                        color: '#F3BA2F',
                        display: 'flex',
                        alignItems: 'center',
                        mt: 1,
                        '&:hover': {
                          color: '#C59E57'
                        }
                      }}
                    >
                      Find exchanges here <LaunchIcon sx={{ ml: 0.5, fontSize: 16 }} />
                    </Link>
                  </Box>
                }
              />
            </StyledListItem>
    
            <StyledListItem>
              <ListItemIcon>
                <IconWrapper>
                  <LaunchIcon />
                </IconWrapper>
              </ListItemIcon>
              <ListItemText 
                primary={
                  <Typography variant="h6" sx={{ color: '#FEFFEB' }}>
                    Access Springboard
                  </Typography>
                }
                secondary={
                  <Typography sx={{ color: '#C59E57' }}>
                    Visit Springboard and purchase tokens directly through the dApp
                  </Typography>
                }
              />
            </StyledListItem>
          </List>
        </StyledPaper>
      );
};

export default BSCInstructions;