import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import { EmojiEvents } from '@mui/icons-material';
import { toast } from 'react-toastify';

const INITIAL_DELAY = 2000; // Start with 2 second delay
const MAX_RETRIES = 3;
const BACKOFF_MULTIPLIER = 1.5;


const Leaderboard = ({ currentWallet }) => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [leaderboard, setLeaderboard] = useState([]);
  const fetchLeaderboardWithRetry = async (retryCount = 0, delay = INITIAL_DELAY) => {
    try {
      console.debug(`Attempting to fetch leaderboard (attempt ${retryCount + 1}/${MAX_RETRIES})`);
      
      await new Promise(resolve => setTimeout(resolve, delay));
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/leaderboard`);
      
      if (response.status === 429 && retryCount < MAX_RETRIES) {
        console.debug(`Rate limited, retrying in ${delay * BACKOFF_MULTIPLIER}ms`);
        await new Promise(resolve => setTimeout(resolve, delay * BACKOFF_MULTIPLIER));
        return fetchLeaderboardWithRetry(retryCount + 1, delay * BACKOFF_MULTIPLIER);
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.debug('Leaderboard fetched successfully:', data);
      return data;
      
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      if (retryCount < MAX_RETRIES) {
        return fetchLeaderboardWithRetry(retryCount + 1, delay * BACKOFF_MULTIPLIER);
      }
      throw error;
    }
  };

  useEffect(() => {
    let isSubscribed = true;
  
    const getLeaderboard = async () => {
      try {
        setLoading(true);
        const data = await fetchLeaderboardWithRetry();
        if (isSubscribed && data?.leaderboard) {
          setLeaderboardData(data.leaderboard);
        }
      } catch (error) {
        console.error('Final leaderboard fetch error:', error);
        if (isSubscribed) {
          toast.error('Unable to load leaderboard. Please try again later.');
        }
      } finally {
        if (isSubscribed) {
          setLoading(false);
        }
      }
    };
  
    getLeaderboard();
  
    return () => {
      isSubscribed = false;
    };
  }, []);

  const getMedalColor = (index) => {
    switch(index) {
      case 0: return '#FFD700'; // Gold
      case 1: return '#C0C0C0'; // Silver
      case 2: return '#CD7F32'; // Bronze
      default: return 'transparent';
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 600, margin: '0 auto', p: 2 }}>
      <Typography 
        variant="h5" 
        sx={{ 
          textAlign: 'center', 
          mb: 2,
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1
        }}
      >
        <EmojiEvents sx={{ color: 'black' }} />
        Top Banana Hunters
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: 'white' }}>Rank</TableCell>
                <TableCell sx={{ color: 'white' }}>Wallet</TableCell>
                <TableCell align="right" sx={{ color: 'white' }}>Points</TableCell>
                <TableCell align="right" sx={{ color: 'white' }}>Tasks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaderboardData.map((user, index) => (
                <TableRow 
                  key={user.fullAddress}
                  sx={{ 
                    backgroundColor: user.fullAddress === currentWallet ? 'rgba(79, 70, 229, 0.1)' : 'transparent',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.05)' }
                  }}
                >
                  <TableCell sx={{ color: 'white' }}>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 1
                    }}>
                      {index < 3 && (
                        <EmojiEvents sx={{ color: getMedalColor(index), fontSize: 20 }} />
                      )}
                      {index + 1}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>{user.address}</TableCell>
                  <TableCell align="right" sx={{ color: 'white' }}>{user.points}</TableCell>
                  <TableCell align="right" sx={{ color: 'white' }}>{user.completedTasks}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Leaderboard;