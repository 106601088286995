import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon, Calculate } from '@mui/icons-material';
import BnnaConverter from './BnnaConverter';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
    backdropFilter: 'blur(16px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: theme.spacing(2),
    maxWidth: '450px',
    width: '100%'
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  color: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const TriggerButton = styled(Button)({
  background: 'linear-gradient(to right, #3b82f6, #2563eb)',
  color: 'white',
  padding: '10px 24px',
  borderRadius: '12px',
  fontSize: '1rem',
  textTransform: 'none',
  '&:hover': {
    background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
  }
});

const BnnaConverterModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <TriggerButton
        onClick={handleOpen}
        startIcon={<Calculate />}
      >
        BNNA Calculator
      </TriggerButton>

      <StyledDialog
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <StyledDialogTitle>
          BNNA/USD Calculator
          <IconButton
            onClick={handleClose}
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <BnnaConverter />
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default BnnaConverterModal;