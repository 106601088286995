// components/AdminWhitelist.js
import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Stack, List, ListItem } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const AdminWhitelist = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [type, setType] = useState('WHITELIST');
  const [bulkAddresses, setBulkAddresses] = useState('');
  const [whitelistStatus, setWhitelistStatus] = useState(null);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState(
    'System maintenance in progress. Some features may be unavailable.'
  );
  const [estimatedDuration, setEstimatedDuration] = useState('1 hour');

  const handleMaintenanceToggle = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/maintenance`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          enabled: !maintenanceMode,
          message: maintenanceMessage,
          estimatedDuration
        })
      });
      const data = await response.json();
      if (data.success) {
        setMaintenanceMode(!maintenanceMode);
        toast.success(`Maintenance mode ${!maintenanceMode ? 'enabled' : 'disabled'}`);
      }
    } catch (error) {
      toast.error('Failed to toggle maintenance mode');
    }
  };

  const handleFetchWhitelist = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/premium/whitelist/${walletAddress}`);
      const data = await response.json();
      setWhitelistStatus(data);
      toast.success('Fetched whitelist status');
    } catch (error) {
      toast.error('Failed to fetch whitelist status');
    }
  };
 

  const handleSingleAdd = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/premium/add-whitelist`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          walletAddress,
          type,
          expiryDays: 30
        })
      });
      const data = await response.json();
      if (data.success) toast.success('User added to whitelist');
    } catch (error) {
      toast.error('Failed to add user');
    }
  };

  const handleBulkAdd = async () => {
    try {
      const addresses = bulkAddresses.split('\n').map(addr => addr.trim());
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/premium/add-whitelist-bulk`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          addresses,
          type,
          expiryDays: 30
        })
      });
      const data = await response.json();
      if (data.success) toast.success(`Added ${data.count} users to whitelist`);
    } catch (error) {
      toast.error('Failed to add users');
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Typography variant="h5">Admin Whitelist Management</Typography>
          {/* Add this new section */}
          <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
            <Typography variant="h6" gutterBottom>Maintenance Mode Settings</Typography>
            <Stack spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={maintenanceMode}
                    onChange={handleMaintenanceToggle}
                    color="warning"
                  />
                }
                label="Maintenance Mode"
              />
              <TextField
                label="Maintenance Message"
                value={maintenanceMessage}
                onChange={(e) => setMaintenanceMessage(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
              <TextField
                label="Estimated Duration"
                value={estimatedDuration}
                onChange={(e) => setEstimatedDuration(e.target.value)}
                fullWidth
                placeholder="e.g., 1 hour"
              />
            </Stack>
          </Box>
          
          <Stack spacing={2}>
            <TextField 
              label="Wallet Address"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
            />
            <Button onClick={handleSingleAdd}>Add Single User</Button>
            <Button onClick={handleFetchWhitelist}>Check Whitelist Status</Button>

          </Stack>
          {whitelistStatus && (
           <Box sx={{ mt: 2 }}>
             <Typography variant="h6">Whitelist Status:</Typography>
             <List>
               <ListItem>Is Whitelisted: {whitelistStatus.isWhitelisted ? 'Yes' : 'No'}</ListItem>
               <ListItem>Type: {whitelistStatus.type || 'N/A'}</ListItem>
             </List>
           </Box>
         )}

          <Stack spacing={2}>
            <TextField
              multiline
              rows={4}
              label="Bulk Addresses (One per line)"
              value={bulkAddresses}
              onChange={(e) => setBulkAddresses(e.target.value)}
            />
            <Button onClick={handleBulkAdd}>Add Multiple Users</Button>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default AdminWhitelist;