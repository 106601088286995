import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Slider, 
  Chip,
  Button,
  Box,
  Typography,
  Paper
} from '@mui/material';
import './CreateAgent.css';

const CreateAgent = ({ publicKey, setActiveTab }) => {
    const [formData, setFormData] = useState({
        name: '',
        personality: 'balanced',
        customTraits: {
          riskTolerance: 5,
          tradingStyle: '',
          preferredTokens: [],
          customInstructions: ''
        },
        settings: {
          maxTradeAmount: 0.1, // Default value
          stopLoss: 5,         // Default percentage
          takeProfit: 10,      // Default percentage
          tradingPairs: []
        }
      });

  const [newToken, setNewToken] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleRiskToleranceChange = (_, newValue) => {
    setFormData(prev => ({
      ...prev,
      customTraits: {
        ...prev.customTraits,
        riskTolerance: newValue
      }
    }));
  };

  const handleAddToken = () => {
    if (newToken && !formData.customTraits.preferredTokens.includes(newToken)) {
      setFormData(prev => ({
        ...prev,
        customTraits: {
          ...prev.customTraits,
          preferredTokens: [...prev.customTraits.preferredTokens, newToken]
        }
      }));
      setNewToken('');
    }
  };

  const handleRemoveToken = (tokenToRemove) => {
    setFormData(prev => ({
      ...prev,
      customTraits: {
        ...prev.customTraits,
        preferredTokens: prev.customTraits.preferredTokens.filter(token => token !== tokenToRemove)
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!publicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/ai-agents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: publicKey.toString(),
          ...formData
        })
      });

      const data = await response.json();
      
      if (data.success) {
        toast.success('AI Agent created successfully!');
        // Reset form
        setFormData({
          name: '',
          personality: 'balanced',
          customTraits: {
            riskTolerance: 5,
            tradingStyle: '',
            preferredTokens: [],
            customInstructions: ''
          },
          settings: {
            maxTradeAmount: 0,
            stopLoss: 0,
            takeProfit: 0,
            tradingPairs: []
          }
        });
        setActiveTab('manage');
      } else {
        toast.error(data.error || 'Failed to create AI Agent');
      }
    } catch (error) {
      console.error('Error creating AI agent:', error);
      toast.error('Failed to create AI Agent');
    }
  };

  return (
    <Paper className="create-agent-form" elevation={3}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create New AI Agent
      </Typography>
      
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Agent Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
          required
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Personality Type</InputLabel>
          <Select
            name="personality"
            value={formData.personality}
            onChange={handleChange}
            required
          >
            <MenuItem value="aggressive">Aggressive</MenuItem>
            <MenuItem value="balanced">Balanced</MenuItem>
            <MenuItem value="conservative">Conservative</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </Select>
        </FormControl>

        {formData.personality === 'custom' && (
          <Box className="custom-traits">
            <Typography gutterBottom>
              Risk Tolerance (1-10)
            </Typography>
            <Slider
              value={formData.customTraits.riskTolerance}
              onChange={handleRiskToleranceChange}
              min={1}
              max={10}
              marks
              valueLabelDisplay="auto"
            />

            <TextField
              fullWidth
              label="Trading Style"
              name="customTraits.tradingStyle"
              value={formData.customTraits.tradingStyle}
              onChange={handleChange}
              margin="normal"
            />

            <Box className="token-input">
              <TextField
                label="Add Preferred Token"
                value={newToken}
                onChange={(e) => setNewToken(e.target.value)}
                margin="normal"
              />
              <Button onClick={handleAddToken}>Add</Button>
            </Box>

            <Box className="token-chips">
              {formData.customTraits.preferredTokens.map((token) => (
                <Chip
                  key={token}
                  label={token}
                  onDelete={() => handleRemoveToken(token)}
                  className="token-chip"
                />
              ))}
            </Box>

            <TextField
              fullWidth
              multiline
              rows={4}
              label="Custom Instructions"
              name="customTraits.customInstructions"
              value={formData.customTraits.customInstructions}
              onChange={handleChange}
              margin="normal"
            />
          </Box>
        )}

        <Box className="trading-settings">
          <Typography variant="h6" gutterBottom>
            Trading Settings
          </Typography>
          
          <TextField
            type="number"
            label="Max Trade Amount (SOL)"
            name="settings.maxTradeAmount"
            value={formData.settings.maxTradeAmount}
            onChange={handleChange}
            margin="normal"
            fullWidth
          />

            <FormControl fullWidth margin="normal">
            <InputLabel>Stop Loss (%)</InputLabel>
            <Select
                name="settings.stopLoss"
                value={formData.settings.stopLoss}
                onChange={handleChange}
            >
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={10}>10%</MenuItem>
                <MenuItem value={15}>15%</MenuItem>
                <MenuItem value={20}>20%</MenuItem>
                <MenuItem value={25}>25%</MenuItem>
                <MenuItem value={30}>30%</MenuItem>
                <MenuItem value={35}>35%</MenuItem>
                <MenuItem value={40}>40%</MenuItem>
                <MenuItem value={45}>45%</MenuItem>
                <MenuItem value={50}>50%</MenuItem>
            </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
            <InputLabel>Take Profit (%)</InputLabel>
            <Select
                name="settings.takeProfit"
                value={formData.settings.takeProfit}
                onChange={handleChange}
            >
                <MenuItem value={10}>10%</MenuItem>
                <MenuItem value={20}>20%</MenuItem>
                <MenuItem value={30}>30%</MenuItem>
                <MenuItem value={40}>40%</MenuItem>
                <MenuItem value={50}>50%</MenuItem>
                <MenuItem value={60}>60%</MenuItem>
                <MenuItem value={70}>70%</MenuItem>
                <MenuItem value={80}>80%</MenuItem>
                <MenuItem value={90}>90%</MenuItem>
                <MenuItem value={100}>100%</MenuItem>
            </Select>
            </FormControl>
        </Box>

        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth
          className="submit-button"
        >
          Create Agent
        </Button>
      </form>
    </Paper>
  );
};

export default CreateAgent;