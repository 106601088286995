import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress,
  Paper,
  Tooltip,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { getTokenBurnData } from './utils/generic';
import { toast } from 'react-toastify';
import BurnImage from './assets/burn.webp'
import LogoImage from './assets/logo-tp.png'


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'rgba(254, 255, 235, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  border: '1px solid rgba(197, 158, 87, 0.1)',
}));

const MetricBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  background: 'rgba(197, 158, 87, 0.1)',
  borderRadius: theme.spacing(1),
}));

const TokenBurnTracker = ({ mintAddress }) => {
  const [burnData, setBurnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const BNNA_MINT_ADDRESS = "DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump";


  const fetchBurnData = async () => {
    if (!BNNA_MINT_ADDRESS) {
      setError('Mint address not configured');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const data = await getTokenBurnData(BNNA_MINT_ADDRESS);
      setBurnData(data);
    } catch (err) {
      setError(err.message);
      toast.error('Failed to fetch burn data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBurnData();
  }, [mintAddress]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={3}>
        <CircularProgress sx={{ color: '#C59E57' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center">
        {error}
      </Typography>
    );
  }

  return (
    <StyledPaper>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
      <Box display="flex" alignItems="center" gap={1}>
    <Box
      component="img"
      src={LogoImage}
      alt="Logo"
      sx={{
        width: 32,
        height: 32,
        objectFit: 'contain'
      }}
    />
    <Typography variant="h6" color="black">
      Token Burn Tracker
    </Typography>
  </Box>
        <Tooltip title="Refresh">
          <IconButton onClick={fetchBurnData} sx={{ color: '#C59E57' }}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={2}>
        <MetricBox>
          <Typography variant="subtitle2" color="black">
            Current Supply
          </Typography>
          <Typography variant="h6" color="#C59E57">
            {Number(burnData.currentSupply).toLocaleString()} BNNA
          </Typography>
        </MetricBox>

        <MetricBox>
          <Typography variant="subtitle2" color="black">
            Burned Amount
          </Typography>
          <Typography variant="h6" color="#C59E57">
            {Number(burnData.burnedAmount).toLocaleString()} BNNA
          </Typography>
        </MetricBox>

        <MetricBox>
          <Typography variant="subtitle2" color="black">
            Burn Percentage
          </Typography>
          <Typography variant="h6" color="#C59E57">
            {burnData.burnPercentage}%
          </Typography>
        </MetricBox>
      </Box>

      <Box
        sx={{
          mt: 3,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          borderRadius: 2
        }}
      >
        <Box
          component="img"
          src={BurnImage}
          alt="Token Burn Visualization"
          sx={{
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'contain',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.02)'
            }
          }}
        />
      </Box>
    </StyledPaper>
  );
};

export default TokenBurnTracker;