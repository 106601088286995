import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const MetallicButton = styled(Button)(({ metalType = 'gold' }) => {
  const gradients = {
    gold: 'linear-gradient(45deg, #FFD700, #FFA500)',
    silver: 'linear-gradient(45deg, #C0C0C0, #E8E8E8)',
    bronze: 'linear-gradient(45deg, #CD7F32, #B8860B)'
  };

  const hoverGradients = {
    gold: 'linear-gradient(45deg, #FFA500, #FFD700)',
    silver: 'linear-gradient(45deg, #E8E8E8, #C0C0C0)',
    bronze: 'linear-gradient(45deg, #B8860B, #CD7F32)'
  };

  return {
    background: gradients[metalType],
    color: metalType === 'silver' ? '#000' : '#FFF',
    fontWeight: 'bold',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    padding: '0.75rem 1rem',
    borderRadius: '12px',
    fontSize: '1rem',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    minWidth: '150px',
    maxWidth: '200px',
    flex: 1,
    '&:hover': {
      background: hoverGradients[metalType],
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    },
    // Override MUI's default styles
    '&.MuiButton-root': {
      background: gradients[metalType],
      '&:hover': {
        background: hoverGradients[metalType],
      }
    }
  };
});

const PremiumFeature = ({ children, publicKey, onClick, disabled, ...props }) => {
  const [isPremium, setIsPremium] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkPremium = async () => {
      if (!publicKey) return;
      
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/premium/status/${publicKey.toString()}`
        );
        const data = await response.json();
        setIsPremium(data.premiumDetails?.tier === 'PREMIUM');
      } catch (error) {
        console.error('Premium check failed:', error);
      } finally {
        setIsChecking(false);
      }
    };

    checkPremium();
  }, [publicKey]);

  // if (!isPremium) {
  //   return (
  //     <Tooltip title="Premium feature - Upgrade to access">

  //       <MetallicButton
  //         variant="gold"
  //         {...props}
  //         // disabled={true}
  //         startIcon={<Lock sx={{ color: 'rgba(255, 215, 0, 0.5)' }} />}
  //         sx={{
  //           ...props.sx,
  //           background: 'linear-gradient(45deg, rgba(64, 64, 64, 0.4), rgba(96, 96, 96, 0.4))',
  //           border: '1px solid rgba(255, 215, 0, 0.2)',
  //           color: 'rgba(255, 255, 255, 0.5)',
  //           backdropFilter: 'blur(4px)',
  //           transition: 'all 0.3s ease',
  //           '&:hover': {
  //             background: 'linear-gradient(45deg, rgba(64, 64, 64, 0.5), rgba(96, 96, 96, 0.5))',
  //             border: '1px solid rgba(255, 215, 0, 0.3)',
  //             transform: 'translateY(-1px)',
  //           },
  //           '&::before': {
  //             content: '""',
  //             position: 'absolute',
  //             top: 0,
  //             left: 0,
  //             right: 0,
  //             bottom: 0,
  //             borderRadius: 'inherit',
  //             background: 'linear-gradient(45deg, transparent, rgba(255, 215, 0, 0.1))',
  //             opacity: 0,
  //             transition: 'opacity 0.3s ease',
  //           },
  //           '&:hover::before': {
  //             opacity: 1,
  //           },
  //           boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
  //           position: 'relative',
  //         }}
  //       >
  //         {children}
  //       </MetallicButton>
  //     </Tooltip>
  //   );
  // }

  return (
    <MetallicButton
      variant="gold"
      {...props}
      onClick={onClick}
      // disabled={disabled || isChecking}
    >
      {children}
    </MetallicButton>
  );
};

export default PremiumFeature;