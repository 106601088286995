import React from 'react';
import { Link } from 'react-router-dom';
import './XRPTeaser.css';
import xrpLogo from '../../../assets/xrp/xrpl_logo.jpeg';
import XRPTokenDashboard from './XRPTokenDashboard';


const XRPTeaser = () => {
  return (
    <div className="xrp-teaser-container">
      <div className="xrp-header">
        <h1>🦍 ApeOut is Coming to XRPL in 2025! 🚀</h1>
        <p className="subtitle">Automate your aping into XRP memecoins using apeout.fun</p>
      </div>
      <div className="xrp-section">
        <h2>📊 Live Token Dashboard</h2>
        <XRPTokenDashboard />
        </div>

      <div className="xrp-section">
        <h2>🔥 Hot XRPL Memecoin Plays</h2>
        <div className="token-grid">
          {['$PWATY', '$PTN', '$XRPEPE', '$BRITTO', '$DOGE'].map(token => (
            <div key={token} className="token-card">
              <h3>{token}</h3>
              <img src={xrpLogo} alt={token} />
              <button className="coming-soon-btn">Coming Soon</button>
            </div>
          ))}
        </div>
      </div>

      <div className="xrp-section">
        <h2>🛠️ Resources</h2>
        <div className="resources-list">
          <a href="https://firstledger.net?ref=dMjPiAVvudIO" target="_blank" rel="noopener noreferrer">
            Create Your Own XRPL Memecoin →
          </a>
          <a href="https://dexscreener.com/xrpl" target="_blank" rel="noopener noreferrer">
            XRPL DexScreener →
          </a>
          <a href="https://firstledger.net/tokens" target="_blank" rel="noopener noreferrer">
            FirstLedger Token List →
          </a>
        </div>
      </div>

      <div className="xrp-section roadmap">
        <h2>🗺️ XRPL Integration Roadmap</h2>
        <div className="roadmap-timeline">
          <div className="roadmap-item">
            <div className="roadmap-date">Q1 2025</div>
            <div className="roadmap-content">
              <h3>Initial Development</h3>
              <p>Research and development of XRPL integration begins</p>
            </div>
          </div>
          
          <div className="roadmap-item">
            <div className="roadmap-date">Q2 2025</div>
            <div className="roadmap-content">
              <h3>Beta Testing</h3>
              <p>Launch of beta testing program for XRPL features</p>
            </div>
          </div>
          
          <div className="roadmap-item">
            <div className="roadmap-date">Q3 2025</div>
            <div className="roadmap-content">
              <h3>Full Launch</h3>
              <p>Official release of ApeOut XRPL integration</p>
            </div>
          </div>
          
          <div className="roadmap-item">
            <div className="roadmap-date">Q4 2025</div>
            <div className="roadmap-content">
              <h3>Advanced Features</h3>
              <p>Implementation of advanced trading features and automation</p>
            </div>
          </div>
        </div>
      </div>

      <div className="xrp-footer">
        <p>Stay updated with our XRPL integration progress!</p>
        <div className="social-links">
          <a href="https://twitter.com/apeoutmeme" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://t.me/apeoutmeme" target="_blank" rel="noopener noreferrer">Telegram</a>
        </div>
      </div>
    </div>
  );
};

export default XRPTeaser;