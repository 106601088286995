import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
    backdropFilter: 'blur(16px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '16px',
    padding: theme.spacing(2),
    maxWidth: '600px',
    width: '90%'
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  color: '#fff',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px'
});

const StyledDialogContent = styled(DialogContent)({
  color: '#fff',
  '& p': {
    marginBottom: '1rem'
  }
});

const DisclaimerModal = ({ open, onClose }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        Important Disclaimer
        <IconButton onClick={onClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography paragraph>
          Trading cryptocurrencies and digital assets involves substantial risk of loss and is not suitable for all investors. The high degree of leverage can work against you as well as for you.
        </Typography>
        <Typography paragraph>
          Past performance is not indicative of future results. The value of your investments can go down as well as up, and you may get back less than you invested.
        </Typography>
        <Typography paragraph>
          Our trading tools and features are provided for educational and informational purposes only. We do not guarantee any particular outcome or profit.
        </Typography>
        <Typography paragraph>
          Before trading, you should carefully consider your investment objectives, level of experience, and risk appetite. Do not invest money you cannot afford to lose.
        </Typography>
        <Typography>
          By using our platform, you acknowledge that you understand and accept these risks.
        </Typography>
      </StyledDialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button 
          onClick={onClose}
          variant="contained"
          sx={{
            background: 'linear-gradient(to right, #3b82f6, #2563eb)',
            color: 'white',
            '&:hover': {
              background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
            }
          }}
        >
          I Understand
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default DisclaimerModal;