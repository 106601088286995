import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import CoinSwiper from './CoinSwiper.js';
import SettingsModal from './SettingsModal.js';
import FavoritesModal from './FavoritesModal.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Connection, PublicKey, Transaction, SystemProgram, Keypair, LAMPORTS_PER_SOL, VersionedTransaction } from '@solana/web3.js';
import logo from './assets/logo.webp';
import logotp from './assets/logo-tp.png';
import { WalletProvider, ConnectionProvider, useWallet, useConnection } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import bs58 from 'bs58';
import '@solana/wallet-adapter-react-ui/styles.css';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { clusterApiUrl } from '@solana/web3.js';
import { useMemo } from 'react';
import { useCallback } from 'react';
import AuthFlow from './AuthFlow.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PumpCalculator from './GainsCalculator.js';
import GainsCalculator from './GainsCalculator.js';
import BananaStandDashboard from './ad/BananaStandDashboard.js';
import TokenBurnTracker from './BurnData.js';
import VideoPlayer from './VideoPlayer.js';
import wojakVideo from './assets/wojakbuysbanana.mp4'
import kodakVideo from './assets/promo.mp4'
import TokenWheel from './TokenWheel.js';
import AdminWhitelist from './components/AdminWhitelist.js';
import AIAgentDashboard from './components/AIAgents/AIAgentDashboard.js';
import AgentsManagement from './components/AIAgents/AgentsManagements.js';
import { RuleTwoTone } from '@mui/icons-material';
import XRPTeaser from './components/Expansions/XRP/XRPTeaser.js';
import BSCDashboard from './components/BSC/BSCDashboard.js';
import MaintenanceBanner from './components/MaintenanceBanner.js';
import AIAgentTester from './components/AIAgents/AIAgentTester.js';
import PropTypes from 'prop-types';


const RPC_URL_UTILITY = 'https://api.mainnet-beta.solana.com'; // or your preferred RPC endpoint
export const requestQueue = {
  queue: [],
  processing: false,
  async add(request) {
    return new Promise((resolve) => {
      this.queue.push(async () => {
        const result = await request();
        resolve(result);
      });
      if (!this.processing) this.process();
    });
  },
  async process() {
    if (this.processing || this.queue.length === 0) return;
    this.processing = true;
    
    while (this.queue.length > 0) {
      const request = this.queue.shift();
      await request();
      await new Promise(resolve => setTimeout(resolve, 2000)); // 2s delay between requests
    }
    
    this.processing = false;
  }
};

const AIAgentDashboardWrapper = ({ handleBuy, handleSell }) => {
  const { publicKey } = useWallet();
  
  console.log('Wrapper props check:', {
    hasHandleBuy: typeof handleBuy === 'function',
    hasHandleSell: typeof handleSell === 'function',
    publicKey: publicKey?.toString()
  });
  

  return (
    <AIAgentDashboard 
      publicKey={publicKey}
      handleBuy={handleBuy}
      handleSell={handleSell}
    />
  );
};
const App = ( props) => {
  const [showSettings, setShowSettings] = useState(false);
  const [showFavorites, setShowFavorites] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [buyAmount, setBuyAmount] = useState('custom');
  const [favorites, setFavorites] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [ownedTokens, setOwnedTokens] = useState({});
  const [boughtTokens, setBoughtTokens] = useState([]);
  const [customAmount, setCustomAmount] = useState(localStorage.getItem('customAmount') || '');
  const [latestMintAddress, setLatestMintAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [ownedTokensData, setOwnedTokensData] = useState([]);
  const { publicKey, connected, sendTransaction } = useWallet();
  const [tradingWallet, setTradingWallet] = useState(null);
  const [latestMintAddresses, setLatestMintAddresses] = useState([]); 
  const [wsConnected, setWsConnected] = useState(false);
  const [wsRetryCount, setWsRetryCount] = useState(0);
  const [tokens, setTokens] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [hasRequiredTokens, setHasRequiredTokens] = useState(false);
  const [phaseOneActive, setPhaseOneActive] = useState(false);
  const [devFeeSet, setDevFeeSet] = useState(false)
  const [walletConnected, setWalletConnected] = useState(false);
  const isProcessing = useRef(false);
  const PLATFORM_TOKENS = [
    'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump',
  ];
  const [points, setPoints] = useState(0);
  const [completedTasks, setCompletedTasks] = useState({
    walletConnected: false,
    tradingWalletCreated: false,
    bananaMillionaire: false,
    bananaBurner: false,
    bananaResearcher: false,
    bananaGamer: false,
    apeOfLegend: false,
    richApe: false,
    apenoob: false,
    boughtBNNA: false,
    usedPumpCalculator: false,
    usedTokenAssessor: false,
  });
  const toastConfig = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      background: 'rgba(23, 32, 42, 0.95)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      borderRadius: '12px',
      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
      color: 'white',
      fontSize: '14px',
      padding: '16px',
    },
    progressStyle: {
      background: 'linear-gradient(to right, #4CAF50, #81C784)',
    },
  };
  const [coins, setCoins] = useState([
    { 
      name: 'ApeOut: Swift Exit Strategy for Crypto Traders', 
      description: 'ApeOut is a cutting-edge application designed for crypto traders who need to quickly "ape out" of their token positions, especially in the volatile world of memecoins and pump-and-dump schemes. Built with a focus on the pump.fun ecosystem, ApeOut provides a streamlined interface for rapid selling of tokens to maximize profits or minimize losses.',
      image: logo,
      mint: 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump'
    },
  ]);
  const DEV_WALLET = '5LW5YcDNQipJPzEUXCuwTu6gE4vzaEuiAqFSyjfpjBZx'; // Replace with your actual dev wallet address
  const FEE_PERCENTAGE = 0.009; // 0.9%
  const SELL_FEE_AMOUNT = 0.0001
  const [autoSnipeEnabled, setAutoSnipeEnabled] = useState(false);
  const [tradeSettings, setTradeSettings] = useState({
    priorityFee: 0.00001,
    slippage: 15,
    amount: 0.001 // Add default amount
  });
  const [tokenPurchaseHistory, setTokenPurchaseHistory] = useState(
    JSON.parse(localStorage.getItem('tokenPurchaseHistory') || '{}')
  );

 
  const validateEnvironment = () => {
    const required = ['REACT_APP_API_URL'];
    const missing = required.filter(key => !process.env[key]);
    
    if (missing.length > 0) {
      console.error(`Missing required environment variables: ${missing.join(', ')}`);
      return false;
    }
    return true;
  };
  const { connection } = useConnection();

  // Update your useEffect for WebSocket connection status
  useEffect(() => {
    if (!wsConnected && wsRetryCount > 0) {
      toast.warning(`Connection lost. Retrying... (${wsRetryCount}/5)`);
    } else if (wsConnected && wsRetryCount > 0) {
      toast.success('Connection restored');
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>❌</span>
          <div>
            <div style={{ fontWeight: 500 }}>Connection Restored</div>
            
          </div>
        </div>,
        toastConfig
      );
    }
  }, [wsConnected, wsRetryCount]);
  useEffect(() => {
    if (!validateEnvironment()) {
      toast.error('Application not properly configured. Check console for details.');
    }
    console.log('Current API URL:', process.env.REACT_APP_API_URL);
  }, []);
  // Modify the checkExistingAuth function in the useEffect
  useEffect(() => {
    const checkExistingAuth = async () => {
      const storedTradingWallet = localStorage.getItem('tradingWallet');
      
      if (connected && publicKey) {
        await new Promise(resolve => setTimeout(resolve, 5000));

        setWalletConnected(true);
        setWallet(publicKey);
        await new Promise(resolve => setTimeout(resolve, 5000));

        try {
          // Check token balance first
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/user_balances/${publicKey.toString()}`);
          const data = await response.json();
          await new Promise(resolve => setTimeout(resolve, 5000));

          
          const hasToken = data.balances?.some(
            token => token.mint === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump' && token.balance > 0
          );
          console.log(hasToken)

          setHasRequiredTokens(hasToken);

          // Only set authenticated if we have both trading wallet and tokens
          if (hasToken && storedTradingWallet) {
            const parsedWallet = JSON.parse(storedTradingWallet);
            setTradingWallet(parsedWallet);
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            setWalletConnected(false);
            setWallet(null);
          }
        } catch (error) {
          console.error('Error checking authentication:', error);
          setIsAuthenticated(false);
          setHasRequiredTokens(false);
        }
      } else {
        setIsAuthenticated(false);
        setHasRequiredTokens(false);
      }
      setIsInitialLoad(false);
    };

    checkExistingAuth();
  }, [connected, publicKey]);
  // Function to clear tokens
  const clearTokens = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/clear-tokens`, {
        method: 'POST',
      });
      
      if (!response.ok) {
        throw new Error('Failed to clear tokens');
      }
      
      setTokens([]);
      setPage(1);
      setHasMore(true);
      await fetchNewTokens(1); // Fetch first page after clearing
      toast.success('Token list cleared successfully');
    } catch (error) {
      console.error('Error clearing tokens:', error);
      toast.error('Failed to clear tokens');
    }
  };
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(storedFavorites);
    const storedBuyAmount = localStorage.getItem('buyAmount') || 'custom';
    setBuyAmount(storedBuyAmount);
    const storedBoughtTokens = JSON.parse(localStorage.getItem('boughtTokens')) || [];
    setBoughtTokens(storedBoughtTokens);
    const storedSettings = localStorage.getItem('tradeSettings');
    if (storedSettings) {
      setTradeSettings(JSON.parse(storedSettings));
    }
  }, []);
  // Consolidate wallet connection state management
  useEffect(() => {
    const handleWalletConnection = async () => {
      if (connected && publicKey) {
        setIsWalletConnected(true);
        setWallet(publicKey);
        
        try {
          // Check token balance
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/user_balances/${publicKey.toString()}`);
          const data = await response.json();
          
          const hasToken = data.balances?.some(
            token => token.mint === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump' && token.balance > 0
          );

          setHasRequiredTokens(hasToken);
          
          // Check for trading wallet in localStorage
          const storedTradingWallet = localStorage.getItem('tradingWallet');
          if (storedTradingWallet) {
            const parsedWallet = JSON.parse(storedTradingWallet);
            setTradingWallet(parsedWallet);
          }

          // Update authentication state
          setIsAuthenticated(hasToken && storedTradingWallet);
          
        } catch (error) {
          console.error('Error checking wallet status:', error);
          setIsAuthenticated(false);
          setHasRequiredTokens(false);
        }
      } else {
        // Reset states on disconnect
        setIsWalletConnected(false);
        setWallet(null);
        setIsAuthenticated(false);
        setHasRequiredTokens(false);
        setTradingWallet(null);
        localStorage.removeItem('walletConnected');
      }
    };

    handleWalletConnection();
  }, [connected, publicKey]); 
    // Add this useEffect to check localStorage on component mount
  useEffect(() => {
      const persistedWalletConnection = localStorage.getItem('walletConnected');
      if (persistedWalletConnection === 'true' && connected && publicKey) {
        setIsWalletConnected(true);
        setWallet(publicKey);
      }
  }, []);

  useEffect(() => {
    const checkTokenAndBuy = async () => {
      if (autoSnipeEnabled && latestMintAddress) {
        try {
          // Call handleBuy directly since the balance check is already done via the button
          handleBuy(latestMintAddress);
        } catch (error) {
          console.error('Error during auto-snipe:', error);
          toast.error('An error occurred during the auto-snipe process.');
        }
      }
    };

    checkTokenAndBuy();
  }, [autoSnipeEnabled, latestMintAddress]); // Add autoSnipeEnabled as a dependency
  useEffect(() => {
    const loadPurchaseHistory = async () => {
      if (publicKey) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}api/user/${publicKey.toString()}`
          );
          const userData = await response.json();
          if (userData.tokenPurchases) {
            const historyMap = userData.tokenPurchases.reduce((acc, purchase) => {
              acc[purchase.tokenMint] = purchase;
              return acc;
            }, {});
            setTokenPurchaseHistory(prevHistory => ({
              ...prevHistory,
              ...historyMap
            }));
            localStorage.setItem('tokenPurchaseHistory', JSON.stringify(historyMap));
          }
        } catch (error) {
          console.error('Error loading purchase history:', error);
        }
      }
    };
  
    loadPurchaseHistory();
  }, [publicKey]);


  const updateTokenData = async (mintAddress) => {
    const loadingToast = toast.loading(`Fetching data for ${mintAddress.slice(0, 6)}...`);
    try {
      const tokenData = await fetchTokenData(mintAddress);
      
      setCoins(prevCoins => prevCoins.map(coin => 
        coin.mint === mintAddress ? {
          ...coin,
          name: tokenData.name,
          description: tokenData.description,
          image: tokenData.imageUri || coin.image,
          marketCap: tokenData.marketCap,
          usdMarketCap: tokenData.usdMarketCap,
          socialLinks: tokenData.socialLinks,
          metrics: tokenData.metrics,
          isPlaceholder: false
        } : coin
      ));
  
      toast.update(loadingToast, {
        render: "Token data updated successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000
      });
    } catch (error) {
      console.error('Error updating token data:', error);
      toast.update(loadingToast, {
        render: `Failed to fetch token data: ${error.message}`,
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    }
  };
  const handleAuthComplete = async (tradingWallet) => {
    return requestQueue.add(async () => {
      console.log('Auth completed, setting up trading wallet:', tradingWallet);
    if (connected && publicKey) {
      try {
        // Fetch token balance once during auth completion
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/user_balances/${publicKey.toString()}`);
        const data = await response.json();
        
        const hasToken = data.balances?.some(
          token => token.mint === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump' && token.balance > 0
        );
  
        if (hasToken) {
          setTradingWallet(tradingWallet);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          toast.error('Required tokens not found');
        }
      } catch (error) {
        console.error('Error checking token balance:', error);
        setIsAuthenticated(false);
        toast.error('Error verifying token balance');
      }
    } else {
      console.error('Wallet not connected');
      setIsAuthenticated(false);
    }
    });
    
  };
  const fetchNewTokens = async () => {

    return requestQueue.add(async () => {
      // ... existing fetchNewTokens code ...
      setIsLoading(true);
    try {
      console.log('Fetching new tokens');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/latest_mint_addresses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        
      });
  
      if (!response.ok) {
        if (response.status === 429) {
          throw new Error('Rate limited - please wait before trying again');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      
      if (!data.success) {
        throw new Error(data.error || 'Failed to fetch mint addresses');
      }
  
      // Create placeholder data for each mint address
      const newCoins = data.mintAddresses.map(mintAddress => ({
        name: `Token (${mintAddress.slice(0, 6)}...)`,
        description: 'Click "Fetch Details" to load token information',
        image: 'placeholder_image_url',
        mint: mintAddress,
        timestamp: Date.now(),
        isPlaceholder: true // Flag to indicate this is placeholder data
      }));
  
      setCoins(prevCoins => {
        const existingMints = new Set(prevCoins.map(coin => coin.mint));
        const filteredNewCoins = newCoins.filter(coin => !existingMints.has(coin.mint));
        return [...prevCoins, ...filteredNewCoins].slice(0, 25); // Keep only latest 25 tokens
      });
  
    } catch (error) {
      console.error('Error fetching new tokens:', error);
      toast.error(error.message || 'Failed to fetch new tokens');
    } finally {
      setIsLoading(false);
    }
    });
    
  };
  const fetchUserData = async (forceUpdate = false) => {
    return requestQueue.add(async () => {
      if (!publicKey && !forceUpdate) {
        console.warn('Wallet not connected');
        setIsWalletConnected(false);
        return;
      }
      setIsWalletConnected(true);
  
      if (!tradingWallet || !tradingWallet.walletPublicKey) {
        console.warn('Trading wallet not initialized');
        return;
      }
    
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/user_balances/${tradingWallet.publicKey}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data)
        if (data.success) {
          const tokenBalances = data.balances.reduce((acc, token) => {
            acc[token.mint] = token.balance;
            return acc;
          }, {});
          setOwnedTokens(tokenBalances);
          setOwnedTokensData(data.ownedTokensData);
    
          console.log('Updated ownedTokens:', tokenBalances);
          console.log('Updated ownedTokensData:', data.ownedTokensData);
        } else {
          console.error('Failed to fetch user data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    });
    
  };
  const toggleFavorite = (coin) => {
    const newFavorites = favorites.includes(coin)
      ? favorites.filter(fav => fav !== coin)
      : [...favorites, coin];
    setFavorites(newFavorites);
    localStorage.setItem('favorites', JSON.stringify(newFavorites));
    
    if (favorites.includes(coin)) {
      toast.success(`Removed ${coin.name} from favorites`);
    } else {
      toast.success(`Added ${coin.name} to favorites`);
    }
  };
  const removeFavorite = (coin) => {
    const newFavorites = favorites.filter(fav => fav !== coin);
    setFavorites(newFavorites);
    localStorage.setItem('favorites', JSON.stringify(newFavorites));
    toast.success(`Removed ${coin.name} from favorites`);
  };
  const sendFunds = async () => {
    if (!publicKey || !tradingWallet) {
      toast.error("Wallet not connected or trading wallet not created");
      return;
    }
  
    const amount = 0.00001; // You might want to make this configurable
    try {
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(tradingWallet.walletPublicKey),
          lamports: amount * LAMPORTS_PER_SOL,
        })
      );
      console.log(amount * LAMPORTS_PER_SOL)
  
      const signature = await sendTransaction(transaction, connection);
      const latestBlockhash = await connection.getLatestBlockhash();
      await connection.confirmTransaction({
        signature,
        blockhash: latestBlockhash.blockhash,
        lastValidBlockHeight: latestBlockhash.lastValidBlockHeight
      }, 'confirmed');
        
      toast.success(`Successfully sent ${amount} SOL to trading wallet. Signature: ${signature}`);
    } catch (error) {
      console.error('Error sending funds:', error);
      toast.error(`Failed to send funds: ${error.message}`);
    }
  };
  const sendDevFee = async (tradeAmount) => {
    const toastId = toast.loading(
      <div>
        <div>Processing dev fee...</div>
        <div style={{fontSize: '0.8em', opacity: 0.8}}>
          Trade Amount: {tradeAmount} SOL
          Expected Fee: {(tradeAmount * FEE_PERCENTAGE).toFixed(6)} SOL
        </div>
      </div>
    );
    let retries = 3;
    
    while (retries > 0) {
      try {
        if (!tradingWallet) {
          throw new Error("Trading wallet not initialized");
        }
  
        // const feeAmount = 0.000000001; // Match Python script's minimal amount
        const feeAmount = tradeAmount * FEE_PERCENTAGE;
        toast.update(toastId, {
          render: (
            <div>
              <div>Sending fee transaction...</div>
              <div style={{fontSize: '0.8em', opacity: 0.8}}>
                Amount: {feeAmount.toFixed(6)} SOL
                From: {tradingWallet.walletPublicKey.slice(0, 6)}...
                To: {DEV_WALLET.slice(0, 6)}...
              </div>
            </div>
          ),
          isLoading: true
        });
        
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/send-fee-auto`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: feeAmount,
            senderPublicKey: tradingWallet.walletPublicKey,
            recipientAddress: DEV_WALLET,
            privateKey: tradingWallet.privateKey
          }),
        });
  
        const data = await response.json();
        
        if (data.success) {
          toast.update(toastId, { 
            render: (
              <div>
                <div>Dev Fee Sent Successfully!</div>
                <div style={{fontSize: '0.8em', opacity: 0.8}}>
                  Amount: {feeAmount.toFixed(6)} SOL
                  TX: {data.signature?.slice(0, 8)}...
                </div>
              </div>
            ),
            type: "success", 
            isLoading: false, 
            autoClose: 5000 
          });
          return;
        }
        
        throw new Error(data.error || 'Transaction failed');
  
      } catch (error) {
        console.error('Error sending dev fee:', error);
        retries--;
        
        if (retries === 0) {
          toast.update(toastId, { 
            render: (
              <div>
                <div>Failed to send dev fee</div>
                <div style={{fontSize: '0.8em', opacity: 0.8}}>
                  Error: {error.message}
                  Attempts: {3 - retries}/3
                </div>
              </div>
            ),
            type: "error", 
            isLoading: false, 
            autoClose: 5000 
          });
          return false;
        } else {
          toast.update(toastId, {
            render: (
              <div>
                <div>Retrying fee transaction...</div>
                <div style={{fontSize: '0.8em', opacity: 0.8}}>
                  Attempts remaining: {retries}
                  Last error: {error.message}
                </div>
              </div>
            ),
            isLoading: true
          });
          console.log(`Retrying... ${retries} attempts left`);
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      }
    }
    return false;
  };
  const getPrivateKeyForPublicKey = (publicKey) => {
    const storedTradingWallet = JSON.parse(localStorage.getItem('tradingWallet'));
    if (storedTradingWallet && storedTradingWallet.publicKey === publicKey) {
      return storedTradingWallet.privateKey;
    }
    return null;
  };
  const handleBuy = async (coin, amount) => {
    if (isProcessing.current) {
      console.log('Transaction already processing');
      return false;
    }
  
    toast.dismiss();
    
    if (!wallet?.publicKey || !tradingWallet?.walletPublicKey) {
      console.error('Wallet validation failed:', {
        mainWallet: !!wallet?.publicKey,
        tradingWallet: !!tradingWallet?.walletPublicKey
      });
      toast.error("Please ensure both wallets are connected");
      return false;
    }
  
    const tradeAmount = amount;
    const { feeAmount, effectiveAmount } = calculateFeeAmount(tradeAmount);
    
    const toastId = toast.loading(
      <div>
        <div style={{ fontWeight: 500 }}>Processing Buy Order</div>
        <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
          Trade Amount: {tradeAmount} SOL<br/>
          Fee: {feeAmount.toFixed(6)} SOL<br/>
          Net Amount: {effectiveAmount.toFixed(6)} SOL
        </div>
      </div>,
      toastConfig
    );
  
    try {
      isProcessing.current = true;
      
      if (!tradingWallet?.apiKey) {
        throw new Error("Trading wallet not properly initialized");
      }
  
      // Get current market cap before purchase
      const marketCapResponse = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${coin}`);
      const marketCapData = await marketCapResponse.json();
      console.log(marketCapData)
      const currentMarketCap = marketCapData.data?.usdMarketCap || 0;
      
      // Determine pool based on market cap
      const poolToUse = currentMarketCap > 100000 ? 'raydium' : 'pump';
  
      const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'buy',
          mint: coin,
          amount: effectiveAmount,
          denominatedInSol: 'true',
          slippage: Number(tradeSettings.slippage),
          priorityFee: Number(tradeSettings.priorityFee),
          pool: poolToUse,
          publicKey: tradingWallet.walletPublicKey,
        }),
      });
  
      if (!response.ok) {
        toast.error('Swapping with Raydium. Please try again later.');
        const raydiumResponse = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'buy',
            mint: coin,
            amount: effectiveAmount,
            denominatedInSol: 'true',
            slippage: 15,
            priorityFee: 0.00001,
            pool: 'raydium',
          }),
        });
        const raydiumSwapResult = await raydiumResponse.json();


        if (!raydiumResponse.ok) {
          throw new Error(`Raydium API error: ${raydiumResponse.status}`);
        }

      }
  
      const swapResult = await response.json();
      
      if (!swapResult.signature ) {
        throw new Error(swapResult.errors || 'Transaction failed');
      }
  
      const purchaseInfo = {
        tokenMint: coin,
        purchaseMarketCap: currentMarketCap,
        purchaseTimestamp: new Date().toISOString(),
        amount: amount
      };
  
      setTokenPurchaseHistory(prev => ({
        ...prev,
        [coin]: purchaseInfo
      }));
  
      localStorage.setItem('tokenPurchaseHistory', JSON.stringify({
        ...JSON.parse(localStorage.getItem('tokenPurchaseHistory') || '{}'),
        [coin]: purchaseInfo
      }));
  
      await fetch(`${process.env.REACT_APP_API_URL}api/store-purchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          walletAddress: publicKey.toString(),
          purchaseInfo
        })
      });
  
      await sendDevFee(tradeAmount);
    
      if (coin === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump' && wallet?.publicKey) {
        const pointsResponse = await fetch(`${process.env.REACT_APP_API_URL}api/update-user-points`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            walletAddress: wallet.publicKey.toString(),
            taskName: 'boughtBnna'
          })
        });
  
        if (pointsResponse.ok) {
          const updatedData = await pointsResponse.json();
          if (updatedData.success) {
            setPoints(updatedData.points);
            setCompletedTasks(updatedData.completedTasks);
          }
        }
      }
  
      toast.update(toastId, {
        render: (
          <div>
            <div>Swap successful!</div>
            <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
              <a 
                href={`https://solscan.io/tx/${swapResult.signature}`} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: '#4ade80' }}
              >
                View on Solscan
              </a>
            </div>
          </div>
        ),
        type: "success",
        isLoading: false,
        autoClose: 5000
      });
  
      setOwnedTokens(prev => ({
        ...prev,
        [coin]: (prev[coin] || 0) + parseFloat(effectiveAmount)
      }));
      
      const newBoughtTokens = [...boughtTokens, coin];
      setBoughtTokens(newBoughtTokens);
      localStorage.setItem('boughtTokens', JSON.stringify(newBoughtTokens));
  
      return true;
  
    } catch (error) {
      console.error('Error processing swap:', error);
      
      toast.update(toastId, {
        render: (
          <div>
            <div style={{ fontWeight: 500 }}>Swap Failed</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              {error.message}
            </div>
          </div>
        ),
        type: "error",
        isLoading: false,
        autoClose: 5000
      });
      
      return false;
    } finally {
      isProcessing.current = false;
    }
  };
  // const handleBuyRaydium = async (coin, amount) => {
  //   if (isProcessing.current) {
  //     console.log('Transaction already processing');
  //     return false;
  //   }
  
  //   toast.dismiss();
    
  //   if (!wallet?.publicKey || !tradingWallet?.walletPublicKey) {
  //     console.error('Wallet validation failed:', {
  //       mainWallet: !!wallet?.publicKey,
  //       tradingWallet: !!tradingWallet?.walletPublicKey
  //     });
  //     toast.error("Please ensure both wallets are connected");
  //     return false;
  //   }
  
  //   const tradeAmount = amount;
  //   const { feeAmount, effectiveAmount } = calculateFeeAmount(tradeAmount);
    
  //   const toastId = toast.loading(
  //     <div>
  //       <div style={{ fontWeight: 500 }}>Processing Buy Order</div>
  //       <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
  //         Trade Amount: {tradeAmount} SOL<br/>
  //         Fee: {feeAmount.toFixed(6)} SOL<br/>
  //         Net Amount: {effectiveAmount.toFixed(6)} SOL
  //       </div>
  //     </div>,
  //     toastConfig
  //   );
  
  //   try {
  //     isProcessing.current = true;
      
  //     if (!tradingWallet?.apiKey) {
  //       throw new Error("Trading wallet not properly initialized");
  //     }
  
  //     // Get current market cap before purchase
  //     const marketCapResponse = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${coin}`);
  //     const marketCapData = await marketCapResponse.json();
  //     console.log(marketCapData)
  //     const currentMarketCap = marketCapData.data?.usdMarketCap || 0;
      
  //     // Determine pool based on market cap
  //     const poolToUse = currentMarketCap > 100000 ? 'raydium' : 'pump';
  
  //     const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         publicKey: tradingWallet.walletPublicKey,
  //         action: 'buy',
  //         mint: coin,
  //         amount: 0.0001,
  //         denominatedInSol: 'true',
  //         slippage: 20,
  //         priorityFee: 0.0001,
  //         pool: 'raydium',
  //       }),
  //     });
  
  //     if (!response.ok) {
  //       toast.error('Swapping with Raydium. Please try again later.');
  //       throw new Error(`Trade API error: ${response.status}`);
  //     }
  
  //     const swapResult = await response.json();
      
  //     if (!swapResult.signature ) {
  //       throw new Error(swapResult.errors || 'Transaction failed');
  //     }
  
  //     const purchaseInfo = {
  //       tokenMint: coin,
  //       purchaseMarketCap: currentMarketCap,
  //       purchaseTimestamp: new Date().toISOString(),
  //       amount: amount
  //     };
  
  //     setTokenPurchaseHistory(prev => ({
  //       ...prev,
  //       [coin]: purchaseInfo
  //     }));
  
  //     localStorage.setItem('tokenPurchaseHistory', JSON.stringify({
  //       ...JSON.parse(localStorage.getItem('tokenPurchaseHistory') || '{}'),
  //       [coin]: purchaseInfo
  //     }));
  
  //     await fetch(`${process.env.REACT_APP_API_URL}api/store-purchase`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         walletAddress: publicKey.toString(),
  //         purchaseInfo
  //       })
  //     });
  
  //     await sendDevFee(tradeAmount);
    
  //     if (coin === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump' && wallet?.publicKey) {
  //       const pointsResponse = await fetch(`${process.env.REACT_APP_API_URL}api/update-user-points`, {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify({
  //           walletAddress: wallet.publicKey.toString(),
  //           taskName: 'boughtBnna'
  //         })
  //       });
  
  //       if (pointsResponse.ok) {
  //         const updatedData = await pointsResponse.json();
  //         if (updatedData.success) {
  //           setPoints(updatedData.points);
  //           setCompletedTasks(updatedData.completedTasks);
  //         }
  //       }
  //     }
  
  //     toast.update(toastId, {
  //       render: (
  //         <div>
  //           <div>Swap successful!</div>
  //           <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
  //             <a 
  //               href={`https://solscan.io/tx/${swapResult.signature}`} 
  //               target="_blank" 
  //               rel="noopener noreferrer"
  //               style={{ color: '#4ade80' }}
  //             >
  //               View on Solscan
  //             </a>
  //           </div>
  //         </div>
  //       ),
  //       type: "success",
  //       isLoading: false,
  //       autoClose: 5000
  //     });
  
  //     setOwnedTokens(prev => ({
  //       ...prev,
  //       [coin]: (prev[coin] || 0) + parseFloat(effectiveAmount)
  //     }));
      
  //     const newBoughtTokens = [...boughtTokens, coin];
  //     setBoughtTokens(newBoughtTokens);
  //     localStorage.setItem('boughtTokens', JSON.stringify(newBoughtTokens));
  
  //     return true;
  
  //   } catch (error) {
  //     console.error('Error processing swap:', error);
      
  //     toast.update(toastId, {
  //       render: (
  //         <div>
  //           <div style={{ fontWeight: 500 }}>Swap Failed</div>
  //           <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
  //             {error.message}
  //           </div>
  //         </div>
  //       ),
  //       type: "error",
  //       isLoading: false,
  //       autoClose: 5000
  //     });
      
  //     return false;
  //   } finally {
  //     isProcessing.current = false;
  //   }
  // };
  
  
  const handleBuyRaydium = async (coin, amount) => {
    if (isProcessing.current) {
        console.log('Transaction already processing');
        return false;
    }

    toast.dismiss();
    
    try {
        isProcessing.current = true;

        // Create keypair from trading wallet's private key
        const tradingKeypair = Keypair.fromSecretKey(
            bs58.decode(tradingWallet.privateKey)
        );

        // Set up transaction arguments exactly as in docs
        const bundledTxArgs = [{
            publicKey: tradingKeypair.publicKey.toBase58(),
            action: "buy",
            mint: coin,
            denominatedInSol: "true",
            amount: amount, // Convert to lamports
            slippage: Number(tradeSettings.slippage),
            priorityFee: Number(tradeSettings.priorityFee),

            pool: "raydium"
        }];

        // First API call to get unsigned transactions
        const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(bundledTxArgs)
        });

        if (response.status === 200) {
            const transactions = await response.json();
            console.log('Received transactions:', transactions);

            let encodedSignedTransactions = [];
            let signatures = [];

            // Sign each transaction
            for (let i = 0; i < bundledTxArgs.length; i++) {
                const tx = VersionedTransaction.deserialize(
                    new Uint8Array(bs58.decode(transactions[i]))
                );
                tx.sign([tradingKeypair]);
                encodedSignedTransactions.push(bs58.encode(tx.serialize()));
                signatures.push(bs58.encode(tx.signatures[0]));
            }

            // Send to Jito
            try {
                const jitoResponse = await fetch(`https://mainnet.block-engine.jito.wtf/api/v1/bundles`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "jsonrpc": "2.0",
                        "id": 1,
                        "method": "sendBundle",
                        "params": [
                            encodedSignedTransactions
                        ]
                    })
                });

                console.log('Jito response:', jitoResponse);

                if (jitoResponse.ok) {
                    // Update UI with success
                    toast.success(
                        <div>
                            <div>Swap successful!</div>
                            <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
                                <a 
                                    href={`https://solscan.io/tx/${signatures[0]}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    style={{ color: '#4ade80' }}
                                >
                                    View on Solscan
                                </a>
                            </div>
                        </div>
                    );

                    // Log each transaction
                    for (let i = 0; i < signatures.length; i++) {
                        console.log(`Transaction ${i}: https://solscan.io/tx/${signatures[i]}`);
                    }

                    return true;
                } else {
                    throw new Error('Jito bundle submission failed');
                }
            } catch (e) {
                console.error('Jito error:', e.message);
                throw e;
            }
        } else {
            console.error('API error:', response.statusText);
            throw new Error(`API error: ${response.statusText}`);
        }
    } catch (error) {
        console.error('Swap error:', error);
        toast.error(`Swap failed: ${error.message}`);
        return false;
    } finally {
        isProcessing.current = false;
    }
};


  const handleSell = async (coin, amount) => {
    if (isProcessing.current) {
      console.log('Transaction already processing');
      return false;
    }
  
    toast.dismiss();
  
    if (!wallet?.publicKey || !tradingWallet?.walletPublicKey) {
      console.error('Wallet validation failed:', {
        mainWallet: !!wallet?.publicKey,
        tradingWallet: !!tradingWallet?.walletPublicKey
      });
      toast.error("Please ensure both wallets are connected");
      return false;
    }
  
    const { feeAmount, effectiveAmount } = calculateFeeAmount(amount);
  
    const toastId = toast.loading(
      <div>
        <div style={{ fontWeight: 500 }}>Processing Sell Order</div>
        <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
          Trade Amount: {amount} Tokens<br/>
         
        </div>
      </div>,
      toastConfig
    );
  
    try {
      isProcessing.current = true;
  
      if (!tradingWallet?.apiKey) {
        throw new Error("Trading wallet not properly initialized");
      }
  
      const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'sell',
          mint: coin,
          amount: amount,
          denominatedInSol: 'false',
          slippage: 15,
          priorityFee: 0.00001,
          pool: 'pump',
        }),
      });
  
      if (!response.ok) {
        toast.error('Swapping with Raydium. Please try again later.');
        const sellSuccess = await handleSellRaydium(coin, amount);
        if (sellSuccess) {
          return true;
        }
        
        throw new Error(`Trade API error: ${response.status}`);

      }
  
      const sellResult = await response.json();
      
      if (!sellResult.signature) {
        throw new Error(sellResult.errors || 'Transaction failed');
      }
  
      // Send dev fee after successful trade
      // await sendDevFee(0.0001);
  
      // Update UI after success
      toast.update(toastId, {
        render: (
          <div>
            <div>Sell successful!</div>
            <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
              <a 
                href={`https://solscan.io/tx/${sellResult.signature}`} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: '#4ade80' }}
              >
                View on Solscan
              </a>
            </div>
          </div>
        ),
        type: "success",
        isLoading: false,
        autoClose: 5000
      });
  
      // Update token states
      setOwnedTokens(prev => ({
        ...prev,
        [coin]: Math.max(0, (prev[coin] || 0) - amount)
      }));
  
      if (ownedTokens[coin] - amount <= 0) {
        const newBoughtTokens = boughtTokens.filter(token => token !== coin);
        setBoughtTokens(newBoughtTokens);
        localStorage.setItem('boughtTokens', JSON.stringify(newBoughtTokens));
      }
  
      return true;
  
    } catch (error) {
      console.error('Error processing sell:', error);
      
      toast.update(toastId, {
        render: (
          <div>
            <div style={{ fontWeight: 500 }}>Sell Failed</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              {error.message}
            </div>
          </div>
        ),
        type: "error",
        isLoading: false,
        autoClose: 5000
      });
      
      return false;
    } finally {
      isProcessing.current = false;
    }
  };
  const handleAutoTPSLSell = async (coin, amount) => {
    if (isProcessing.current) {
      console.log('Transaction already processing');
      return false;
    }
  
    toast.dismiss();
  
    if (!wallet?.publicKey || !tradingWallet?.walletPublicKey) {
      console.error('Wallet validation failed:', {
        mainWallet: !!wallet?.publicKey,
        tradingWallet: !!tradingWallet?.walletPublicKey
      });
      toast.error("Please ensure both wallets are connected");
      return false;
    }
  
    const { feeAmount, effectiveAmount } = calculateFeeAmount(amount);
  
    const toastId = toast.loading(
      <div>
        <div style={{ fontWeight: 500 }}>Processing Sell Order</div>
        <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
          Trade Amount: {amount} Tokens<br/>
         
        </div>
      </div>,
      toastConfig
    );
  
    try {
      isProcessing.current = true;
  
      if (!tradingWallet?.apiKey) {
        throw new Error("Trading wallet not properly initialized");
      }
  
      const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'sell',
          mint: coin,
          amount: amount,
          denominatedInSol: 'true',
          slippage: 15,
          priorityFee: 0.0005,
          pool: 'pump',
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Trade API error: ${response.status}`);
      }
  
      const sellResult = await response.json();
      
      if (!sellResult.signature) {
        throw new Error(sellResult.errors || 'Transaction failed');
      }
  
      // Send dev fee after successful trade
      // await sendDevFee(0.0001);
  
      // Update UI after success
      toast.update(toastId, {
        render: (
          <div>
            <div>Sell successful!</div>
            <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
              <a 
                href={`https://solscan.io/tx/${sellResult.signature}`} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: '#4ade80' }}
              >
                View on Solscan
              </a>
            </div>
          </div>
        ),
        type: "success",
        isLoading: false,
        autoClose: 5000
      });
  
      // Update token states
      setOwnedTokens(prev => ({
        ...prev,
        [coin]: Math.max(0, (prev[coin] || 0) - amount)
      }));
  
      if (ownedTokens[coin] - amount <= 0) {
        const newBoughtTokens = boughtTokens.filter(token => token !== coin);
        setBoughtTokens(newBoughtTokens);
        localStorage.setItem('boughtTokens', JSON.stringify(newBoughtTokens));
      }
  
      return true;
  
    } catch (error) {
      console.error('Error processing sell:', error);
      
      toast.update(toastId, {
        render: (
          <div>
            <div style={{ fontWeight: 500 }}>Sell Failed</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              {error.message}
            </div>
          </div>
        ),
        type: "error",
        isLoading: false,
        autoClose: 5000
      });
      
      return false;
    } finally {
      isProcessing.current = false;
    }
  };
  const handleSellRaydium = async (coin, amount) => {
    if (isProcessing.current) {
        console.log('Transaction already processing');
        return false;
    }

    toast.dismiss();
    
    try {
        isProcessing.current = true;

        // Create keypair from trading wallet's private key
        const tradingKeypair = Keypair.fromSecretKey(
            bs58.decode(tradingWallet.privateKey)
        );

        // Set up transaction arguments exactly as in docs
        const bundledTxArgs = [{
            publicKey: tradingKeypair.publicKey.toBase58(),
            action: "sell",
            mint: coin,
            denominatedInSol: "true",
            amount: amount, // Convert to lamports
            slippage: Number(tradeSettings.slippage),
            priorityFee: Number(tradeSettings.priorityFee),
            pool: "raydium"
        }];

        // First API call to get unsigned transactions
        const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(bundledTxArgs)
        });

        if (response.status === 200) {
            const transactions = await response.json();
            console.log('Received transactions:', transactions);

            let encodedSignedTransactions = [];
            let signatures = [];

            // Sign each transaction
            for (let i = 0; i < bundledTxArgs.length; i++) {
                const tx = VersionedTransaction.deserialize(
                    new Uint8Array(bs58.decode(transactions[i]))
                );
                tx.sign([tradingKeypair]);
                encodedSignedTransactions.push(bs58.encode(tx.serialize()));
                signatures.push(bs58.encode(tx.signatures[0]));
            }

            // Send to Jito
            try {
                const jitoResponse = await fetch(`https://mainnet.block-engine.jito.wtf/api/v1/bundles`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "jsonrpc": "2.0",
                        "id": 1,
                        "method": "sendBundle",
                        "params": [
                            encodedSignedTransactions
                        ]
                    })
                });

                console.log('Jito response:', jitoResponse);

                if (jitoResponse.ok) {
                    // Update UI with success
                    toast.success(
                        <div>
                            <div>Swap successful!</div>
                            <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
                                <a 
                                    href={`https://solscan.io/tx/${signatures[0]}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    style={{ color: '#4ade80' }}
                                >
                                    View on Solscan
                                </a>
                            </div>
                        </div>
                    );

                    // Log each transaction
                    for (let i = 0; i < signatures.length; i++) {
                        console.log(`Transaction ${i}: https://solscan.io/tx/${signatures[i]}`);
                    }

                    return true;
                } else {
                    throw new Error('Jito bundle submission failed');
                }
            } catch (e) {
                console.error('Jito error:', e.message);
                throw e;
            }
        } else {
            console.error('API error:', response.statusText);
            throw new Error(`API error: ${response.statusText}`);
        }
    } catch (error) {
        console.error('Swap error:', error);
        toast.error(`Swap failed: ${error.message}`);
        return false;
    } finally {
        isProcessing.current = false;
    }
};


  const handleBuyAmountChange = (value) => {
    setBuyAmount(value);
    localStorage.setItem('buyAmount', value);
    if (value === 'custom') {
      setCustomAmount('');
      localStorage.removeItem('customAmount');
    }
  };
  const handleCustomAmountChange = (value) => {
    setCustomAmount(value);
    localStorage.setItem('customAmount', value);
  };
  const fetchTokenData = async (mintAddress) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${mintAddress}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error('Error fetching token data:', error);
      throw error;
    }
  };
  const manualFetchTokens = () => {
    console.log("Manual fetch triggered");
    fetchNewTokens();
  };
  const handleScroll = useCallback((event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (!isLoading && hasMore) {
        fetchNewTokens(page + 1);
      }
    }
  }, [isLoading, hasMore, page]);
  const calculateFeeAmount = (tradeAmount) => {
    const fee = tradeAmount * FEE_PERCENTAGE;
    return {
      feeAmount: fee,
      effectiveAmount: tradeAmount - fee
    };
  };
  const storePurchaseInfo = async (mintAddress, amount) => {
    try {
      console.debug('Storing token purchase info:', { mintAddress, amount });
      
      // Fetch current market cap
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${mintAddress}`);
      const data = await response.json();
      
      if (!data.success) {
        console.error('Failed to fetch token data for purchase tracking');
        return;
      }
  
      const purchaseInfo = {
        tokenMint: mintAddress,
        purchaseMarketCap: data.data.marketCap,
        purchaseTimestamp: new Date(),
        amount: amount
      };
  
      // Store in database
      await fetch(`${process.env.REACT_APP_API_URL}api/store-purchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          walletAddress: publicKey.toString(),
          purchaseInfo
        })
      });
  
      console.debug('Successfully stored purchase info:', purchaseInfo);
    } catch (error) {
      console.error('Error storing purchase info:', error);
    }
  };


  return (
    <div className="app-container">
    {props.render && props.render({ handleBuy, handleSell })}

      {isInitialLoad ? (
        <div className="loading-screen">
          <div className="spinner"></div>
        </div>
      ) : !phaseOneActive ? (
        <>
        <MaintenanceBanner />
        <AuthFlow onComplete={handleAuthComplete} hasRequiredTokens={hasRequiredTokens} setTradingWallet={setTradingWallet} tradingWallet={tradingWallet} fetchUserData={fetchUserData} handleBuy={handleBuy} handleSell={handleSell} ownedTokens={ownedTokens} wallet={wallet} setWallet={setWallet} walletConnected={walletConnected} connected={connected}
        sendDevFee={sendDevFee} points={points}
        setPoints={setPoints}
        completedTasks={completedTasks}
        setCompletedTasks={setCompletedTasks} setTradeSettings={setTradeSettings} tradeSettings={tradeSettings} setTokenPurchaseHistory={setTokenPurchaseHistory} tokenPurchaseHistory={tokenPurchaseHistory} handleAutoTPSLSell={handleAutoTPSLSell} handleBuyRaydium={handleBuyRaydium} />
        </>
      ) : (
        <>
          <img src={logotp} alt="ApeOut Logo" className="app-logo" />
          <WalletMultiButton />
          
          {tradingWallet && tradingWallet.publicKey && (
            <div className="trading-wallet-info">
              Trading Wallet: {tradingWallet.publicKey.slice(0, 4)}...{tradingWallet.publicKey.slice(-4)}
            </div>
          )}
          
          {latestMintAddress && (
            <div className="latest-mint-address">
              Latest Mint Address: {latestMintAddress}
            </div>
          )}
          
          <div className="disclaimer">
            ⚠️ Beta version. Trading involves high risk. Use at your own discretion.
          </div>
          
          <button className="clear-tokens-button" onClick={clearTokens}>
            Clear Tokens
          </button>
          
          <button className="fetch-tokens-button" onClick={manualFetchTokens}>
            Fetch Tokens
          </button>
          
          <CoinSwiper 
            coins={coins}
            onFavorite={toggleFavorite} 
            onBuy={handleBuy}
            favorites={favorites}
            onScroll={handleScroll}
            onSell={handleSell}
            key={coins.map(coin => coin.mint).join(',')}
            updateTokenData={updateTokenData}
          />  
          
          <div className="scroll-indicator">
            <span className="arrow">⬇️</span>
            <p>Swipe for more coins</p>
          </div>
          
          <div className="auto-snipe-container">
            <button 
              className={`auto-snipe-button ${autoSnipeEnabled ? 'enabled' : ''}`}
              onClick={async () => {
                if (!ownedTokens['DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump']) {
                  document.querySelector('.auto-snipe-button').classList.add('shake');
                  setTimeout(() => {
                    document.querySelector('.auto-snipe-button').classList.remove('shake');
                  }, 500);
                  toast.warning("You must own ApeOut Tokens such as $BNNA to Turn On Auto APE!");
                } else {
                  setAutoSnipeEnabled(prev => !prev);
                }
              }}
            >
              🤖🦍
              {autoSnipeEnabled ? 'Turn Auto APE OFF' : 'Turn Auto APE ON'}
            </button>
          </div>
          
          <div className="button-row">
            <button 
              className="settings-button"
              onClick={() => setShowSettings(true)}
            >
              ⚙️
            </button>
            <button 
              className="favorites-button"
              onClick={() => setShowFavorites(true)}
            >
              ❤️
            </button>
          </div>
          
          {showSettings && (
            <SettingsModal 
              onClose={() => setShowSettings(false)}
              buyAmount={buyAmount}
              setBuyAmount={handleBuyAmountChange}
              customAmount={customAmount}
              setCustomAmount={handleCustomAmountChange}
              tradingWallet={tradingWallet}
              sendFunds={sendFunds}
              wallet={wallet}
            />
          )}
          
          {showFavorites && (
            <FavoritesModal 
              onClose={() => setShowFavorites(false)}
              favorites={favorites}
              onBuy={handleBuy}
              onSell={handleSell}
              ownedTokens={ownedTokens}
              ownedTokensData={ownedTokensData}
              onRemoveFavorite={removeFavorite}
              boughtTokens={boughtTokens}
              wallet={wallet}
              isWalletConnected={isWalletConnected}
              fetchOwnedTokens={fetchUserData}
            />
          )}
          
          <ToastContainer position="bottom-right" />
        </>
      )}
    </div>
  );
};

// Add PropTypes validation
App.propTypes = {
  render: PropTypes.func
};

// Add default props
App.defaultProps = {
  render: null
};

const AppWrapper = () => {
  const network = 'https://mainnet.helius-rpc.com/?api-key=03227d28-b6de-4a36-9d90-cd0cc7c2f8eb' || clusterApiUrl('mainnet-beta');
  const wallets = useMemo(() => [new PhantomWalletAdapter()], []);

  return (
    <Router basename="/">
    <ConnectionProvider endpoint={network}>
    <WalletProvider wallets={wallets} autoConnect>
      <WalletModalProvider>
      <Routes>
          <Route path="/" element={<App />} />
          <Route path="/pump-calculator" element={<GainsCalculator />} />
          <Route path="/banana-stand" element={<BananaStandDashboard />} />
          <Route path="/burn" element={<TokenBurnTracker />} />
          <Route path="/ai-agents" element={<AIAgentDashboardWrapper 
           
          />} />
          <Route path="/video" element={<VideoPlayer src={wojakVideo}className="custom-video-class" />} />
          <Route path="/video2" element={<VideoPlayer src={kodakVideo}className="custom-video-class" />} />
          <Route path="/wheel" element={
  <TokenWheel 
    
  />
} />

<Route 
  path="/admin/whitelist" 
  element={<AdminWhitelist />}
/>
<Route 
                path="/agents" 
                element={
                  <App 
                    render={props => (
                      <AIAgentDashboardWrapper
                        {...props}
                        handleBuy={props.handleBuy}
                        handleSell={props.handleSell}
                      />
                    )}
                  />
                }
              />
              <Route path="/xrp" element={<XRPTeaser />} />
<Route path="/bsc" element={<BSCDashboard />} /> {/* Add this line */}
<Route path="/test-agents" element={<AIAgentTester />} />
        </Routes>
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
  </Router>
  );
};

export default AppWrapper;


