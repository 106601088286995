import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './AIAgentTester.css';

const AIAgentTester = () => {
  const [testResults, setTestResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);

  // Test agent functionality
  const runQuickTest = async () => {
    if (!selectedAgent) {
      toast.warning('Please select or create an agent first');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`/api/agents/${selectedAgent.id}/test`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      });

      const data = await response.json();
      
      if (response.ok) {
        setTestResults(data.results);
        
        // Display test results in toast notifications
        data.results.trades.forEach(trade => {
          toast.info(`${trade.type}: ${trade.responseTime}ms`, {
            position: "bottom-right"
          });
        });

        data.results.posts.forEach(post => {
          toast.success(`Social Post Created: ${post.responseTime}ms`, {
            position: "bottom-right"
          });
        });

      } else {
        toast.error(`Test failed: ${data.error}`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="agent-tester">
      <h2>AI Agent Tester</h2>
      
      {/* Quick Test Button */}
      <button 
        onClick={runQuickTest}
        disabled={loading || !selectedAgent}
        className="test-button"
      >
        {loading ? 'Running Tests...' : 'Run Quick Test'}
      </button>

      {/* Test Results Display */}
      {testResults && (
        <div className="test-results">
          <h3>Test Results</h3>
          
          {/* Market Analysis Results */}
          <div className="result-section">
            <h4>Market Analysis</h4>
            {testResults.trades
              .filter(t => t.type === 'MARKET_ANALYSIS')
              .map((trade, i) => (
                <div key={i} className="result-item">
                  <span>Response Time: {trade.responseTime}ms</span>
                  <span>Status: {trade.result.success ? '✅' : '❌'}</span>
                </div>
              ))}
          </div>

          {/* Social Posts Results */}
          <div className="result-section">
            <h4>Social Integration</h4>
            {testResults.posts.map((post, i) => (
              <div key={i} className="result-item">
                <span>Platform: {post.result.platform}</span>
                <span>Response Time: {post.responseTime}ms</span>
              </div>
            ))}
          </div>

          {/* Trading Decisions Results */}
          <div className="result-section">
            <h4>Trading Decisions</h4>
            {testResults.trades
              .filter(t => t.type === 'TRADING_DECISION')
              .map((trade, i) => (
                <div key={i} className="result-item">
                  <span>Decision: {trade.result.decision}</span>
                  <span>Confidence: {trade.result.confidence}%</span>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AIAgentTester;

