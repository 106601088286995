import React, { useState, useEffect, useCallback } from 'react';
import { Box, Card, Typography, Grid, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';

const StatsCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: '12px',
  width: '100%',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const StatItem = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1),
  '& .label': {
    color: '#888',
    fontSize: '0.875rem',
    marginBottom: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
  },
  '& .value': {
    color: '#fff',
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  '& .icon': {
    fontSize: '1.125rem',
    verticalAlign: 'middle',
  },
}));

const safeNumber = (value, defaultValue = 0) => {
    if (value === undefined || value === null) {
      return defaultValue;
    }
    return Number(value);
  };


const STATS_FETCH_INTERVAL = 60000; // 1 minute
const STATS_RETRY_DELAY = 5000; // 5 seconds
  
const TokenStats = () => {
  
  const [loading, setLoading] = useState(true);
  const [tokenStats, setTokenStats] = useState({
    usd_market_cap: 0,
    market_cap: 0,
    total_supply: 0,
    virtual_sol_reserves: 0,
    created_timestamp: Date.now(),
    raydium_pool: false,
    king_of_the_hill_timestamp: Date.now(),
    reply_count: 0  // Add default value
  });
  const [lastFetchTime, setLastFetchTime] = useState(0);
  const BNNA_TOKEN_MINT = "DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump";


  
  const fetchStats = useCallback(async () => {
    const now = Date.now();
    if (now - lastFetchTime < STATS_RETRY_DELAY) {
      console.debug('Skipping stats fetch - too soon');
      return;
    }

    try {
      setLastFetchTime(now);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${BNNA_TOKEN_MINT}`);
      
      if (response.status === 429) {
        console.debug('Stats rate limited, will retry later');
        return;
      }

      const { data, success } = await response.json();
      
      if (success && data) {
        setTokenStats({
          usd_market_cap: data.usdMarketCap,
          market_cap: data.marketCap,
          total_supply: data.totalSupply,
          virtual_sol_reserves: data.virtualSolReserves || 0,
          created_timestamp: data.createdTimestamp,
          raydium_pool: data.raydium_pool, // This might need to be fetched from somewhere else
          king_of_the_hill_timestamp: Date.now(), // This might need to be fetched from somewhere else
          reply_count: data.metrics?.replyCount || 0
        });
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching token stats:', error);
      setLoading(false);
    }
  }, [lastFetchTime]);

  
  // Add this useEffect
  useEffect(() => {
    fetchStats();
    
    // Optional: Set up polling interval
    const interval = setInterval(fetchStats, STATS_FETCH_INTERVAL);
    return () => clearInterval(interval);
  }, [fetchStats]);

  if (loading || !tokenStats) {
    return null;
  }

  const formatNumber = (value) => {
    if (value === undefined || value === null) {
      return '0.00';
    }
    
    try {
      const num = parseFloat(value);
      return isNaN(num) ? '0.00' : num.toFixed(2);
    } catch (error) {
      console.error('Error formatting number:', error);
      return '0.00';
    }
  };

  const formatSol = (num) => {
    return `◎${Number(num).toFixed(2)}`;
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  

  return (
    <StatsCard elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <StatItem>
            <Typography className="label">Market Cap (USD)</Typography>
            <Typography className="value">
              {formatNumber(tokenStats.usd_market_cap)}
            </Typography>
          </StatItem>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem>
            <Typography className="label">Market Cap (SOL)</Typography>
            <Typography className="value">
              {formatSol(tokenStats.market_cap)}
            </Typography>
          </StatItem>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem>
            <Typography className="label">Total Supply</Typography>
            <Typography className="value">
              {(tokenStats.total_supply / 1e9).toFixed(0)}
            </Typography>
          </StatItem>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem>
            <Typography className="label">Virtual SOL Reserves</Typography>
            <Typography className="value">
              {formatSol(tokenStats.virtual_sol_reserves / LAMPORTS_PER_SOL)}
            </Typography>
          </StatItem>
        </Grid>

        {/* New Stats Row */}
        <Grid item xs={6} sm={3}>
          <StatItem>
            <Typography className="label">
              <AccessTimeIcon className="icon" /> Created
            </Typography>
            <Tooltip title={formatDate(tokenStats.created_timestamp)}>
              <Typography className="value">
                {new Date(tokenStats.created_timestamp).toLocaleDateString()}
              </Typography>
            </Tooltip>
          </StatItem>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem>
            <Typography className="label">
              Raydium Pool
            </Typography>
            <Typography className="value">
              {tokenStats.raydium_pool ? (
                <CheckCircleIcon sx={{ color: '#10b981' }} />
              ) : (
                <CancelIcon sx={{ color: '#ef4444' }} />
              )}
            </Typography>
          </StatItem>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem>
            <Typography className="label">
              <EmojiEventsIcon className="icon" /> King of Hill
            </Typography>
            <Tooltip title={formatDate(tokenStats.king_of_the_hill_timestamp)}>
              <Typography className="value">
                {new Date(tokenStats.king_of_the_hill_timestamp).toLocaleDateString()}
              </Typography>
            </Tooltip>
          </StatItem>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem>
            <Typography className="label">
              <ChatBubbleOutlineIcon className="icon" /> Replies
            </Typography>
            <Typography className="value">
              {safeNumber(tokenStats?.reply_count, 0).toLocaleString()}
            </Typography>
          </StatItem>
        </Grid>
      </Grid>
    </StatsCard>
  );
};

export default TokenStats;