import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Box, 
  Card, 
  CardHeader, 
  CardContent, 
  Typography, 
  Button, 
  IconButton,
  CircularProgress
} from '@mui/material';
import { 
  TrendingUp, 
  TrendingDown, 
  Refresh as RefreshIcon, 
  Launch as LaunchIcon,
  Info as InfoIcon 
} from '@mui/icons-material';

const TokenCard = ({ mint, balance, handleSell, onLoadDetails }) => {
  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadDetails = async () => {
    if (isLoading || details) return;
    setIsLoading(true);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${mint}`);
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          setDetails(data.data);
          if (onLoadDetails) onLoadDetails(mint, data.data);
        }
      }
    } catch (error) {
      console.error(`Error loading details for ${mint}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{
      p: 2,
      border: 1,
      borderColor: 'divider',
      borderRadius: 1,
      bgcolor: 'background.paper',
      '&:hover': { bgcolor: 'action.hover' }
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography sx={{ fontWeight: 'bold' }}>
          {details?.name || `${mint.slice(0, 8)}...${mint.slice(-4)}`}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {!details && (
            <IconButton 
              size="small" 
              onClick={loadDetails}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={20} /> : <InfoIcon />}
            </IconButton>
          )}
          <IconButton
            size="small"
            href={`https://pump.fun/coin/${mint}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LaunchIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="body2" color="text.secondary">
            Balance: {balance.toFixed(2)}
          </Typography>
          {details && (
            <Typography 
              variant="body2" 
              color={details.marketCap > details.purchaseMarketCap ? 'success.main' : 'error.main'}
              sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
            >
              {details.marketCap > details.purchaseMarketCap ? <TrendingUp fontSize="small" /> : <TrendingDown fontSize="small" />}
              {Math.abs(((details.marketCap - details.purchaseMarketCap) / details.purchaseMarketCap) * 100).toFixed(2)}%
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => handleSell(mint, balance)}
        >
          Sell
        </Button>
      </Box>
    </Box>
  );
};

const TokenBalanceDisplay = ({ tradingWallet, handleSell }) => {
  const [balances, setBalances] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchInProgress = useRef(false);
  const lastFetchTime = useRef(0);
  const FETCH_COOLDOWN = 2000; // 2s cooldown between fetches

  const fetchBalances = useCallback(async (force = false) => {
    if (!tradingWallet?.walletPublicKey) return;
    if (fetchInProgress.current) return;
    
    const now = Date.now();
    if (!force && now - lastFetchTime.current < FETCH_COOLDOWN) return;

    try {
      fetchInProgress.current = true;
      lastFetchTime.current = now;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/user_balances/${tradingWallet.walletPublicKey}`
      );
      
      if (!response.ok) throw new Error('Failed to fetch balances');
      
      const data = await response.json();
      if (data.success && data.balances) {
        const formattedBalances = {};
        data.balances.forEach(token => {
          if (token.balance > 0) {
            formattedBalances[token.mint] = token.balance;
          }
        });
        setBalances(formattedBalances);
      }
    } catch (error) {
      console.error('Balance fetch error:', error);
      setError('Failed to update balances');
    } finally {
      setIsLoading(false);
      fetchInProgress.current = false;
    }
  }, [tradingWallet?.walletPublicKey]);

  useEffect(() => {
    let mounted = true;
    
    const loadData = async () => {
      if (!mounted) return;
      await fetchBalances();
    };

    loadData();
    const interval = setInterval(loadData, 10000); // 10s refresh

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [fetchBalances]);

  const handleRefresh = () => {
    setIsLoading(true);
    fetchBalances(true);
  };

  return (
    <Card>
      <CardHeader
        title="Token Balances"
        action={
          <IconButton onClick={handleRefresh} disabled={isLoading}>
            <RefreshIcon sx={{ animation: isLoading ? 'spin 1s linear infinite' : 'none' }} />
          </IconButton>
        }
      />
      <CardContent>
        {error ? (
          <Box sx={{ textAlign: 'center', color: 'error.main', p: 2 }}>
            {error}
            <Button onClick={handleRefresh} color="primary" sx={{ ml: 2 }}>
              Retry
            </Button>
          </Box>
        ) : isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : Object.keys(balances).length === 0 ? (
          <Typography sx={{ textAlign: 'center', py: 3, color: 'text.secondary' }}>
            No tokens found in this wallet
          </Typography>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {Object.entries(balances).map(([mint, balance]) => (
              <TokenCard
                key={mint}
                mint={mint}
                balance={balance}
                handleSell={handleSell}
              />
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default TokenBalanceDisplay;