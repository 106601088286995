import { useState, useRef, useEffect } from 'react';

const useAutoTPSL = ({ handleBuy, handleBuyWithRaydium, handleAutoTPSLSell, tradingWallet }) => {
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [currentMarketCap, setCurrentMarketCap] = useState(null);
  const [initialMarketCap, setInitialMarketCap] = useState(null);
  const [targetTp, setTargetTp] = useState(null);
  const [targetSl, setTargetSl] = useState(null);
  const [monitoredToken, setMonitoredToken] = useState(() => {
    const saved = localStorage.getItem('monitoredToken');
    return saved ? JSON.parse(saved) : null;
  });
  const monitoringRef = useRef(null);
  const retryCount = useRef(0);
  const MAX_RETRIES = 3;
  const POLL_INTERVAL = 10000; // 10 seconds

  useEffect(() => {
    if (monitoredToken) {
      setIsMonitoring(true);
      setInitialMarketCap(monitoredToken.initialMc);
      setTargetTp(monitoredToken.tpTarget);
      setTargetSl(monitoredToken.slTarget);
      startMonitoringLoop(monitoredToken);
    }
  }, []);

  const startMonitoringLoop = (tokenData) => {
    monitoringRef.current = setInterval(async () => {
      try {
        const mcResponse = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${tokenData.mintAddress}`);
        const mcData = await mcResponse.json();
        
        if (!mcData.success || !mcData.data.marketCap) {
          throw new Error('Failed to fetch current market cap');
        }

        const currentMc = mcData.data.marketCap;
        setCurrentMarketCap(currentMc);

        if (currentMc >= tokenData.tpTarget || currentMc <= tokenData.slTarget) {
          await handleAutoTPSLSell(tokenData.mintAddress, tokenData.buyAmount);
          stopMonitoring();
        }
      } catch (error) {
        retryCount.current++;
        console.error('Error in monitoring loop:', error);
        
        if (retryCount.current >= MAX_RETRIES) {
          stopMonitoring();
        }
      }
    }, POLL_INTERVAL);
  };

  const startMonitoring = async (mintAddress, buyAmount, takeProfitPercent, stopLossPercent) => {
    try {
      // Prompt user to confirm the token's origin
      const isPumpToken = window.confirm('Is this a Pump.fun token? Click OK for Pump.fun, Cancel for Raydium/DEX');
  
      // Select appropriate buy method based on user input
      const buySuccess = isPumpToken 
        ? await handleBuy(mintAddress, buyAmount) 
        : await handleBuyWithRaydium(mintAddress, buyAmount);
  
      if (!buySuccess) {
        throw new Error('Initial buy failed');
      }
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${mintAddress}`);
      const data = await response.json();
      
      if (!data.success || !data.data.marketCap) {
        throw new Error('Failed to fetch initial market cap');
      }
  
      const initialMc = data.data.marketCap;
      const tpTarget = initialMc * (1 + (takeProfitPercent / 100));
      const slTarget = initialMc * (1 - (stopLossPercent / 100));
      
      const tokenData = {
        mintAddress,
        buyAmount,
        initialMc,
        tpTarget,
        slTarget,
        takeProfitPercent,
        stopLossPercent,
        isPumpToken  // Store the token origin for potential future reference
      };
  
      localStorage.setItem('monitoredToken', JSON.stringify(tokenData));
      setMonitoredToken(tokenData);
      
      setInitialMarketCap(initialMc);
      setCurrentMarketCap(initialMc);
      setTargetTp(tpTarget);
      setTargetSl(slTarget);
      setIsMonitoring(true);
  
      startMonitoringLoop(tokenData);
  
    } catch (error) {
      console.error('Failed to start monitoring:', error);
      stopMonitoring();
      throw error;
    }
  };

  const stopMonitoring = () => {
    if (monitoringRef.current) {
      clearInterval(monitoringRef.current);
      monitoringRef.current = null;
    }
    localStorage.removeItem('monitoredToken');
    setMonitoredToken(null);
    setIsMonitoring(false);
    setCurrentMarketCap(null);
    setInitialMarketCap(null);
    setTargetTp(null);
    setTargetSl(null);
    retryCount.current = 0;
  };

  useEffect(() => {
    return () => {
      if (monitoringRef.current) {
        clearInterval(monitoringRef.current);
      }
    };
  }, []);

  return {
    isMonitoring,
    currentMarketCap,
    initialMarketCap,
    targetTp,
    targetSl,
    monitoredToken,
    startMonitoring,
    stopMonitoring
  };
};

export default useAutoTPSL;