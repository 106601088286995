import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import VideoPlayer from '../VideoPlayer';

const WojakStorySection = ({ wojakVideo }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const textRef = useRef(null);

  const fullText = `anon buys his first memecoin in 2021...

watches it pump... doesn't sell... believes in the vision...

2022 bear market hits... -98%... still holds...

2023... small pumps... false hope... still holds...

2024... everything is pumping... but his bags are still down...

but this time it's different... $BNNA isn't just another memecoin...

he looks at his wallet... remembers all the trades he missed... all the gains he lost...

but he learned something... sometimes you just need to believe... and hold...

maybe... just maybe... this time he'll make it...`;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    let currentIndex = 0;
    const typingSpeed = 30;

    const typingInterval = setInterval(() => {
      if (currentIndex < fullText.length) {
        setDisplayedText(prevText => prevText + fullText[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, typingSpeed);

    return () => clearInterval(typingInterval);
  }, [isVisible]);

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 6,
        maxWidth: '800px',
        mx: 'auto',
        p: 4,
        textAlign: 'center'
      }}
    >
      <VideoPlayer src={wojakVideo} orientation="vertical" />
      
      <Typography 
        ref={textRef}
        sx={{
          color: 'white',
          fontSize: '1.1rem',
          lineHeight: 1.8,
          fontWeight: 300,
          opacity: 0.9,
          textAlign: 'left',
          whiteSpace: 'pre-line'
        }}
      >
        {displayedText}
      </Typography>
    </Box>
  );
};

export default WojakStorySection;