import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Paper, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SwapVert, Cached } from '@mui/icons-material';
import { toast } from 'react-toastify';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
  backdropFilter: 'blur(16px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  color: 'white',
  width: '100%',
  maxWidth: '400px',
  margin: '0 auto'
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3b82f6',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const ConversionResult = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'rgba(0, 0, 0, 0.2)',
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const ActionButton = styled(Button)({
  background: 'linear-gradient(to right, #3b82f6, #2563eb)',
  color: 'white',
  padding: '12px 24px',
  '&:hover': {
    background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
  },
});

const BnnaConverter = () => {
  const [bnnaPrice, setBnnaPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState('');
  const [isConvertingToUsd, setIsConvertingToUsd] = useState(true);

  useEffect(() => {
    fetchBnnaPrice();
    const interval = setInterval(fetchBnnaPrice, 60000);
    return () => clearInterval(interval);
  }, []);

  const fetchBnnaPrice = async () => {
    try {
      const response = await fetch(
        'https://api.dexscreener.com/latest/dex/tokens/DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump'
      );
      const data = await response.json();
      
      if (data.pairs && data.pairs[0]) {
        setBnnaPrice(parseFloat(data.pairs[0].priceUsd));
      } else {
        toast.error('Unable to fetch BNNA price');
      }
    } catch (err) {
      toast.error('Failed to fetch price data');
    } finally {
      setLoading(false);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    setAmount(value);
  };

  const toggleConversion = () => {
    setIsConvertingToUsd(!isConvertingToUsd);
    setAmount('');
  };

  const getConvertedAmount = () => {
    if (!amount || !bnnaPrice) return '0.00';
    const numAmount = parseFloat(amount);
    
    if (isConvertingToUsd) {
      return (numAmount * bnnaPrice).toFixed(6);
    } else {
      return (numAmount / bnnaPrice).toFixed(2);
    }
  };

  return (
    <StyledPaper elevation={24}>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
          BNNA/USD Converter
        </Typography>
        {bnnaPrice && (
          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            1 BNNA = ${bnnaPrice.toFixed(6)} USD
          </Typography>
        )}
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress sx={{ color: '#3b82f6' }} />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <StyledTextField
            fullWidth
            label={isConvertingToUsd ? 'BNNA Amount' : 'USD Amount'}
            value={amount}
            onChange={handleAmountChange}
            variant="outlined"
            placeholder="Enter amount"
          />
          
          <ActionButton
            onClick={toggleConversion}
            startIcon={<SwapVert />}
            fullWidth
          >
            Swap Conversion
          </ActionButton>

          <ConversionResult>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              {isConvertingToUsd ? 'USD Value' : 'BNNA Value'}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {isConvertingToUsd ? '$' : ''}{getConvertedAmount()}
              {!isConvertingToUsd ? ' BNNA' : ''}
            </Typography>
          </ConversionResult>

          <Button
            onClick={fetchBnnaPrice}
            startIcon={<Cached />}
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              '&:hover': {
                color: 'white',
              }
            }}
          >
            Refresh Price
          </Button>
        </Box>
      )}
    </StyledPaper>
  );
};

export default BnnaConverter;