import React, { useState, useEffect, useRef } from 'react';

const TokenWheel = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [winner, setWinner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const canvasRef = useRef(null);
  const wheelContainerRef = useRef(null);
  const [currentSpinner, setCurrentSpinner] = useState(null);
  
  const PRIZES = [
    { label: '🔥 $BNNA', color: '#ef4444' },
    { label: '🎁 $BNNA ', color: '#10b981' },
    { label: '🖼️ NFT ', color: '#8b5cf6' },
    { label: '📢 X Shoutout', color: '#3b82f6' },
    { label: '💎 Premium ', color: '#f59e0b' },
  ];

  const drawWheel = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = canvas.width / 2 - 10;
    const anglePerPrize = (2 * Math.PI) / PRIZES.length;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)';
    ctx.lineWidth = 3;
    ctx.stroke();

    PRIZES.forEach((prize, index) => {
      // Draw sector
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(
        centerX,
        centerY,
        radius,
        index * anglePerPrize,
        (index + 1) * anglePerPrize
      );
      ctx.closePath();
      ctx.fillStyle = prize.color;
      ctx.fill();
      
      // Add sector border
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.1)';
      ctx.lineWidth = 1;
      ctx.stroke();

      // Draw text
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(index * anglePerPrize + anglePerPrize / 2);
      ctx.textAlign = 'right';
      ctx.fillStyle = 'white';
      ctx.font = 'bold 16px Inter, system-ui, sans-serif';
      ctx.fillText(prize.label, radius - 25, 6);
      ctx.restore();
    });

    // Draw center circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, 15, 0, 2 * Math.PI);
    ctx.fillStyle = 'white';
    ctx.fill();
  };

  useEffect(() => {
    setCurrentSpinner(`MrBlind`); // Replace with actual spinner address
    drawWheel();
  }, []);

  const spinWheel = async () => {
    if (isSpinning) return;

    setIsLoading(true);
    setIsSpinning(true);

    try {
      const winningIndex = Math.floor(Math.random() * PRIZES.length);
      const rotations = 5;
      const degreesToWinner = (360 / PRIZES.length) * winningIndex;
      const totalDegrees = 360 * rotations + degreesToWinner;

      const container = wheelContainerRef.current;
      container.style.transition = 'none';
      container.style.transform = 'rotate(0deg)';
      
      // Force reflow
      void container.offsetWidth;
      
      container.style.transition = 'transform 5s cubic-bezier(0.17, 0.67, 0.12, 0.99)';
      container.style.transform = `rotate(${totalDegrees}deg)`;


      setTimeout(() => {
        setWinner(PRIZES[winningIndex]);
        setIsSpinning(false);
        setIsLoading(false);
      }, 5000);

    } catch (error) {
      console.error('Wheel spin error:', error);
      setIsSpinning(false);
      setIsLoading(false);
    }
  };

  

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center p-4">
      <div className="w-full max-w-md p-8 bg-gradient-to-br from-gray-800/90 to-gray-900/90 backdrop-blur-xl rounded-2xl border border-gray-700/50 shadow-2xl">
        <div className="mb-8">
          <h2 className="text-4xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-orange-500 to-red-600">
            Apeout Wheel Of Prophecy
          </h2>
        </div>
        
        
        <div className="flex flex-col items-center gap-8">
          <div className="relative w-[300px] h-[300px] group">
            {/* Pointer triangle */}
            <div className="absolute -top-6 left-1/2 -translate-x-1/2 w-0 h-0 border-l-[12px] border-l-transparent border-r-[12px] border-r-transparent border-t-[20px] border-yellow-400 z-10" />
            
            <div
              ref={wheelContainerRef}
              className="absolute inset-0 rounded-full overflow-hidden shadow-xl"
              style={{ transform: 'rotate(0deg)' }}
            >
              <canvas
                ref={canvasRef}
                width={300}
                height={300}
                className="block rounded-full"
              />
            </div>
            
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-black/20 backdrop-blur-sm rounded-full">
                <div className="w-10 h-10 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin" />
              </div>
            )}
          </div>

          <button
            onClick={spinWheel}
            disabled={isSpinning || isLoading}
            className="px-8 py-3 text-lg font-semibold text-white bg-gradient-to-r from-yellow-400 via-orange-500 to-red-600 rounded-xl hover:brightness-110 active:brightness-90 disabled:from-gray-600 disabled:to-gray-700 disabled:cursor-not-allowed disabled:hover:brightness-100 transition-all duration-200 shadow-lg hover:shadow-xl hover:scale-105 active:scale-100 disabled:hover:shadow-none disabled:hover:scale-100"
          >
            {isSpinning ? "Spinning..." : "Spin to Win!"}
          </button>

          {winner && (
            <div className="text-center animate-fade-in">
              <p className="text-lg font-medium text-gray-300">
                Last Prize:
                <span className="ml-2 text-yellow-400 font-bold">
                  {winner.label}
                </span>
              </p>
            </div>
          )}
          <div className="text-center mb-4">
  {currentSpinner && (
    <p className="text-sm text-gray-400">
      Current Spinner: <span className="text-yellow-400">{currentSpinner}</span>
    </p>
  )}
</div>
        </div>
      </div>
    </div>
  );
};

export default TokenWheel;