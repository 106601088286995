import { toast } from 'react-toastify';

const FEE_PERCENTAGE = 0.009; // 0.9%
const DEV_WALLET = '5LW5YcDNQipJPzEUXCuwTu6gE4vzaEuiAqFSyjfpjBZx';

// Utility function to calculate fees
const calculateFeeAmount = (tradeAmount) => {
  const fee = tradeAmount * FEE_PERCENTAGE;
  return {
    feeAmount: fee,
    effectiveAmount: tradeAmount - fee
  };
};

export const handleBuy = async (coin, amount) => {
  const toastId = toast.loading("Processing Buy Order...");
  
  try {
    // Get trading wallet from localStorage
    const tradingWallet = JSON.parse(localStorage.getItem('tradingWallet'));
    if (!tradingWallet?.apiKey) {
      throw new Error("Trading wallet not properly initialized");
    }

    const { feeAmount, effectiveAmount } = calculateFeeAmount(amount);

    const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'buy',
        mint: coin,
        amount: effectiveAmount,
        denominatedInSol: 'true',
        slippage: 15, // Default slippage
        priorityFee: 0.00001, // Default priority fee
        pool: 'raydium',
      }),
    });

    const result = await response.json();
    if (!result.signature) throw new Error(result.errors || 'Transaction failed');

    // Send dev fee
    const devFeeResponse = await fetch(`${process.env.REACT_APP_API_URL}api/send-fee-auto`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        amount: feeAmount,
        senderPublicKey: tradingWallet.walletPublicKey,
        recipientAddress: DEV_WALLET,
        privateKey: tradingWallet.privateKey
      }),
    });

    toast.update(toastId, {
      render: "Buy successful!",
      type: "success",
      isLoading: false,
      autoClose: 5000
    });

    return true;

  } catch (error) {
    console.error('Error processing buy:', error);
    toast.update(toastId, {
      render: `Buy failed: ${error.message}`,
      type: "error",
      isLoading: false,
      autoClose: 5000
    });
    return false;
  }
};

export const handleSell = async (coin, amount) => {
  const toastId = toast.loading("Processing Sell Order...");
  
  try {
    const tradingWallet = JSON.parse(localStorage.getItem('tradingWallet'));
    if (!tradingWallet?.apiKey) {
      throw new Error("Trading wallet not properly initialized");
    }

    const response = await fetch(`https://pumpportal.fun/api/trade?api-key=${tradingWallet.apiKey}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'sell',
        mint: coin,
        amount: amount,
        denominatedInSol: 'false',
        slippage: 15,
        priorityFee: 0.00001,
        pool: 'raydium',
      }),
    });

    const result = await response.json();
    if (!result.signature) throw new Error(result.errors || 'Transaction failed');

    toast.update(toastId, {
      render: "Sell successful!",
      type: "success",
      isLoading: false,
      autoClose: 5000
    });

    return true;

  } catch (error) {
    console.error('Error processing sell:', error);
    toast.update(toastId, {
      render: `Sell failed: ${error.message}`,
      type: "error",
      isLoading: false,
      autoClose: 5000
    });
    return false;
  }
};