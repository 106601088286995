import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid,
  Button,
  Card,
  CardContent,
  LinearProgress,
  IconButton,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  MonetizationOn,
  Lock,
  Refresh,
} from '@mui/icons-material';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { toast } from 'react-toastify';
import bananaStandBg from '../assets/logo.webp';
import { useNavigate } from 'react-router-dom';




// Styled Components (keep your existing styled components)
const DashboardContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  background: `linear-gradient(135deg, ${theme.palette.background.default}, #1a1a1a)`,
  padding: theme.spacing(4),
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${bananaStandBg})`,
    backgroundSize: 'cover',
    opacity: 0.1,
    zIndex: 0,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'rgba(254, 255, 235, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  border: '1px solid rgba(197, 158, 87, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 20px rgba(197, 158, 87, 0.2)',
  },
}));

const GoldButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #C59E57, #FFD700)',
  color: '#000',
  fontWeight: 'bold',
  padding: '12px 24px',
  '&:hover': {
    background: 'linear-gradient(45deg, #FFD700, #C59E57)',
  },
}));

const StatsBox = styled(Box)(({ theme }) => ({
  background: 'rgba(254, 255, 235, 0.03)',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const BananaStandDashboard = () => {
  const [stakingInfo, setStakingInfo] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(null);
  const [stakers, setStakers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isStaking, setIsStaking] = useState(false);
  const navigate = useNavigate();

  
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const STAKING_ADDRESS = "7bhLjEQeakWBhfgrdKg3DbERrQZKdoe2BSP7cPzD4w18";
  const RPC_URL = "https://mainnet.helius-rpc.com/?api-key=03227d28-b6de-4a36-9d90-cd0cc7c2f8eb";
  const BNNA_TOKEN = "DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump";

  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Fetch staking info
      const stakingResponse = await fetch(RPC_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getAccountInfo',
          params: [
            STAKING_ADDRESS,
            {
              encoding: 'jsonParsed',
            },
          ],
        }),
      });
      const stakingData = await stakingResponse.json();
      setStakingInfo(stakingData.result);
      console.log("Staking data:", stakingData);
  
      await delay(5000);
  
      // Fetch token balance using specific token account
      const tokenResponse = await fetch(RPC_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getTokenAccountBalance',
          params: [
            "GLz5murzW8PMheJMG4KHczuEo4PF5fSMnCPfxXgMpMN"
          ],
        }),
      });
      const tokenData = await tokenResponse.json();
      console.log("Token data:", tokenData);
      if (tokenData.result?.value) {
        setTokenBalance(tokenData.result.value.uiAmount);
      }
  
      await delay(5000);
  
      // Fetch stakers with modified filters
      // Fetch stakers by getting signature history
    const stakersResponse = await fetch(RPC_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        id: 1,
        method: 'getSignaturesForAddress',
        params: [
          "GLz5murzW8PMheJMG4KHczuEo4PF5fSMnCPfxXgMpMN",
          {
            limit: 1000,
          }
        ],
      }),
    });
      const stakersData = await stakersResponse.json();
      console.log("Stakers data:", stakersData);
      if (stakersData.result) {
        setStakers(stakersData.result);
      }
  
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);



  const fetchStakingInfo = async () => {
    try {
      const response = await fetch(RPC_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getAccountInfo',
          params: [
            STAKING_ADDRESS,
            {
              encoding: 'jsonParsed',
            },
          ],
        }),
      });

      const data = await response.json();
      console.log(data);
      setStakingInfo(data.result);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // Longer interval to avoid rate limits
    const interval = setInterval(fetchData, 60000); // Changed to 60 seconds
    return () => clearInterval(interval);
  }, []);



 

  if (loading) {
    return (
      <DashboardContainer>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress sx={{ color: '#C59E57' }} />
        </Box>
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer>
       <Grid item xs={12} md={8}>
          <StyledCard>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <StatsBox>
                    <MonetizationOn sx={{ color: '#C59E57', fontSize: 40 }} />
                    <Box>
                      <Typography variant="h6" color="#FEFFEB">
                        Total BNNA Staked
                      </Typography>
                      <Typography variant="h4" color="#C59E57">
                        {tokenBalance 
                          ? `${tokenBalance.toLocaleString()} BNNA`
                          : 'N/A'}
                      </Typography>
                    </Box>
                  </StatsBox>
                </Grid>

                <Grid item xs={12} md={4}>
  <StatsBox>
    <Lock sx={{ color: '#C59E57', fontSize: 40 }} />
    <Box>
      <Typography variant="h6" color="#FEFFEB">
        Total Stakers
      </Typography>
      <Typography variant="h4" color="#C59E57">
        {stakers?.length - 3 || 0}
      </Typography>
    </Box>
  </StatsBox>
</Grid>

                <Grid item xs={12} md={4}>
                  <StatsBox>
                    <Refresh sx={{ color: '#C59E57', fontSize: 40 }} />
                    <Box>
                      <Typography variant="h6" color="#FEFFEB">
                        Last Update
                      </Typography>
                      <Typography variant="h6" color="#C59E57">
                        {new Date().toLocaleTimeString()}
                      </Typography>
                    </Box>
                  </StatsBox>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Staking Card */}
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h5" color="#FEFFEB" gutterBottom>
                Staking Info
              </Typography>
              <Box sx={{ my: 3 }}>
                <Typography variant="body1" color="#C59E57">
                  Program Owner: {stakingInfo?.value?.owner || 'N/A'}
                </Typography>
                <Typography variant="body1" color="#C59E57" mt={2}>
                  Program Space: {stakingInfo?.value?.space || 'N/A'} bytes
                </Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={70} 
                  sx={{ 
                    my: 2,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: 'rgba(197, 158, 87, 0.2)',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#C59E57',
                    }
                  }}
                />
              </Box>
              <GoldButton 
              fullWidth 
              href="https://app.streamflow.finance/staking/solana/mainnet/7bhLjEQeakWBhfgrdKg3DbERrQZKdoe2BSP7cPzD4w18"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<Lock />}
            >
              Stake Now
            </GoldButton>
            </CardContent>
          </StyledCard>
        </Grid>
      
    </DashboardContainer>
  );
};

export default BananaStandDashboard;