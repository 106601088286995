import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { toast } from 'react-toastify';
import DipRipStrategy from './strategies/analyzeDipRip';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
  Chip,
  CircularProgress,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';


const AgentsManagement = ({  activeTab, 
  setActiveTab, 
  handleBuy, 
  handleSell  }) => {
  const { publicKey } = useWallet(); // Get publicKey from wallet context
  const [promoCode, setPromoCode] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [bnnaBalance, setBnnaBalance] = useState(0);
  const [tradingPairInputs, setTradingPairInputs] = useState({});
  const [strategyEnabled, setStrategyEnabled] = useState(false);

  // Fetch agents when component mounts or publicKey changes
  useEffect(() => {
    if (publicKey) {
      fetchAgents();
      fetchBnnaBalance();
    } else {
      setIsLoading(false);
    }
  }, [publicKey]);

  const fetchBnnaBalance = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/user_balances/${publicKey.toString()}`
      );
      const data = await response.json();
      
      if (data.success) {
        const bnnaToken = data.balances.find(
          token => token.mint === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump'
        );
        setBnnaBalance(bnnaToken?.balance || 0);
      }
    } catch (error) {
      console.error('Error fetching BNNA balance:', error);
    }
  };
  const handleDeleteAgent = async (agentId) => {
    if (!window.confirm('Are you sure you want to delete this agent?')) {
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/ai-agents/${agentId}?userId=${publicKey.toString()}`,
        {
          method: 'DELETE'
        }
      );

      const data = await response.json();
      
      if (data.success) {
        setAgents(agents.filter(agent => agent._id !== agentId));
        toast.success('Agent deleted successfully');
      } else {
        toast.error('Failed to delete agent');
      }
    } catch (error) {
      console.error('Error deleting agent:', error);
      toast.error('Failed to delete agent');
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/ai-agents/${publicKey.toString()}`
      );
      const data = await response.json();
      
      if (data.success) {
        setAgents(data.agents);
      } else {
        toast.error('Failed to fetch agents');
        // Set empty array instead of mock data in production
        setAgents([]);
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
      toast.error('Failed to fetch agents');
      setAgents([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePromoSubmit = async (e) => {
    e.preventDefault();
    if (!promoCode.trim()) {
      toast.error('Please enter a promo code');
      return;
    }

    toast.info("Applying promo code...");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/promo-codes/redeem`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            code: promoCode,
            walletAddress: publicKey.toString()
          })
        }
      );

      const data = await response.json();
      
      if (data.success) {
        toast.success('Promo code applied successfully!');
        setPromoCode('');
        fetchAgents(); // Refresh agents to show updated credits
      } else {
        toast.error(data.message || 'Failed to apply promo code');
      }
    } catch (error) {
      console.error('Error applying promo code:', error);
      toast.error('Failed to apply promo code');
    }
  };

  const toggleAgentStatus = async (agentId) => {
    const agent = agents.find(a => a.id === agentId);
    if (!agent) return;

    const newStatus = agent.status === 'active' ? 'inactive' : 'inactive';

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/ai-agents/${agentId}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            status: newStatus,
            walletAddress: publicKey.toString()
          })
        }
      );

      const data = await response.json();
      
      if (data.success) {
        setAgents(agents.map(agent => 
          agent.id === agentId ? { ...agent, status: newStatus } : agent
        ));
        toast.success(`Agent ${newStatus === 'active' ? 'activated' : 'deactivated'}`);
      } else {
        toast.error('Failed to update agent status');
      }
    } catch (error) {
      console.error('Error updating agent status:', error);
      toast.error('Failed to update agent status');
    }
  };


  // Add this function to handle strategy toggle
  const toggleStrategy = async (agentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/ai-agents/${agentId}/strategy`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            strategyEnabled: !agents.find(a => a._id === agentId).settings?.strategyEnabled,
            walletAddress: publicKey.toString()
          })
        }
      );

      const data = await response.json();
      
      if (data.success) {
        setAgents(agents.map(agent => 
          agent._id === agentId 
            ? { 
                ...agent, 
                settings: { 
                  ...agent.settings, 
                  strategyEnabled: !agent.settings?.strategyEnabled 
                } 
              } 
            : agent
        ));
        toast.success(`Strategy ${data.agent.settings.strategyEnabled ? 'enabled' : 'disabled'}`);
      } else {
        toast.error('Failed to update strategy settings');
      }
    } catch (error) {
      console.error('Error updating strategy:', error);
      toast.error('Failed to update strategy settings');
    }
  };

  const handleTradingPairUpdate = async (agentId, mintAddress) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/ai-agents/${agentId}/trading-pair`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            tradingPairs: [mintAddress],
            walletAddress: publicKey.toString()
          })
        }
      );
  
      const data = await response.json();
      
      if (data.success) {
        setAgents(agents.map(agent => 
          agent._id === agentId 
            ? { 
                ...agent, 
                settings: { 
                  ...agent.settings, 
                  tradingPairs: [mintAddress]
                } 
              } 
            : agent
        ));
        toast.success('Trading pair updated successfully');
      } else {
        toast.error('Failed to update trading pair');
      }
    } catch (error) {
      console.error('Error updating trading pair:', error);
      toast.error('Failed to update trading pair');
    }
  };

  if (!publicKey) {
    return (
      <div className="agents-management">
        <div className="wallet-connect-prompt">
          <h2>Connect Your Wallet</h2>
          <p>Please connect your wallet to view and manage your AI agents.</p>
          <WalletMultiButton />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="agents-management">
        <div className="loading-spinner">Loading your agents...</div>
      </div>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>Your AI Agents</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                Total Credits
                <Chip 
                  label="Coming Soon" 
                  color="primary" 
                  size="small" 
                  sx={{ ml: 1, animation: 'pulse 2s infinite' }}
                />
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <CircularProgress size={16} sx={{ mr: 1 }} />
                <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                  Feature in development
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">BNNA Balance</Typography>
              <Typography variant="h4">{bnnaBalance} BNNA</Typography>
            </CardContent>
          </Card>
        </Grid>

        {agents.length === 0 ? (
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', p: 4 }}>
              <Typography gutterBottom>You haven't created any agents yet.</Typography>
              <Button 
                variant="contained" 
                onClick={() => navigate('/ai-agents')}
                startIcon={<AddIcon />}
              >
                Create Your First Agent
              </Button>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {agents.map(agent => (
                <Grid item xs={12} md={6} key={agent._id}>
                  <Card>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h6">{agent.name}</Typography>
                        <Chip 
                          label={agent.status}
                          color={agent.status === 'active' ? 'success' : 'default'}
                        />
                      </Box>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">Max Trade</Typography>
                          <Typography>{agent.settings.maxTradeAmount} SOL</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">Success Rate</Typography>
                          <Typography>{agent.performance.successRate}%</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">Profit/Loss</Typography>
                          <Typography color={agent.performance.profitLoss >= 0 ? 'success.main' : 'error.main'}>
                            {agent.performance.profitLoss}%
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">Total Trades</Typography>
                          <Typography>{agent.performance.totalTrades}</Typography>
                        </Grid>
                      </Grid>

                      <Box sx={{ mt: 2 }}>
                        <TextField
                          fullWidth
                          label="Token Address to Monitor"
                          value={tradingPairInputs[agent._id] || agent.settings.tradingPairs?.[0] || ''}
                          onChange={(e) => setTradingPairInputs(prev => ({
                            ...prev,
                            [agent._id]: e.target.value
                          }))}
                          onBlur={() => {
                            const newPair = tradingPairInputs[agent._id];
                            if (newPair && newPair !== agent.settings.tradingPairs?.[0]) {
                              handleTradingPairUpdate(agent._id, newPair);
                            }
                          }}
                          size="small"
                          margin="normal"
                        />

                        <Button
                          fullWidth
                          variant="contained"
                          color={agent.settings?.strategyEnabled ? 'error' : 'primary'}
                          onClick={() => toggleStrategy(agent._id)}
                          sx={{ mt: 1 }}
                        >
                          {agent.settings?.strategyEnabled ? 'Disable Strategy' : 'Enable Strategy'}
                        </Button>

                        {agent.settings?.strategyEnabled && (
                          <DipRipStrategy 
                            agent={agent}
                            handleBuy={handleBuy}
                            handleSell={handleSell}
                            isActive={agent.status === 'active'}
                          />
                        )}

                        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                          <Button 
                            variant="outlined" 
                            disabled
                            startIcon={<SettingsIcon />}
                            sx={{ flex: 1 }}
                          >
                            Settings
                          </Button>
                          <IconButton 
                            color="error" 
                            onClick={() => handleDeleteAgent(agent._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setActiveTab('create')}
          startIcon={<AddIcon />}
        >
          Create New Agent
        </Button>
      </Box>
    </Box>
  );
};

export default AgentsManagement;