import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCountBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(1),
  background: 'linear-gradient(45deg, rgba(0,0,0,0.6), rgba(0,0,0,0.3))',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255,255,255,0.1)',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

const UserCount = () => {
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/count`);
        const data = await response.json();
        if (data.success) {
          setUserCount(data.count);
        }
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    fetchUserCount();
    // Refresh count every 5 minutes
    const interval = setInterval(fetchUserCount, 300000);
    return () => clearInterval(interval);
  }, []);

  return (
    <StyledCountBox>
      <Typography variant="body2" color="text.secondary">
        👥 Users:
      </Typography>
      <Typography variant="body2" color="primary">
        {userCount.toLocaleString()}
      </Typography>
    </StyledCountBox>
  );
};

export default UserCount;