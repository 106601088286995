import React, { useState } from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Slider,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
  Chip,
  Avatar
} from '@mui/material';
import { 
  Close as CloseIcon, 
  TrendingUp as TrendingUpIcon, 
  TrendingDown as TrendingDownIcon 
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import useAutoTPSL from './UseAutoTPSL';
import { toast } from 'react-toastify';
import { Sell as SellIcon } from '@mui/icons-material';
import TradeSettings from './TradeSettings';


const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
    backdropFilter: 'blur(16px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '16px',
  }
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.4)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3b82f6',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-focused': {
      color: '#3b82f6',
    },
  },
});

const StyledSlider = styled(Slider)(({ color }) => ({
  color: color,
  '& .MuiSlider-thumb': {
    backgroundColor: color,
  },
  '& .MuiSlider-track': {
    backgroundColor: color,
  },
}));

const ActionButton = styled(Button)(({ ismonitoring }) => ({
  padding: '12px',
  background: ismonitoring === 'true'
    ? 'linear-gradient(to right, #ef4444, #dc2626)'
    : 'linear-gradient(to right, #22c55e, #16a34a)',
  '&:hover': {
    background: ismonitoring === 'true'
      ? 'linear-gradient(to right, #dc2626, #b91c1c)'
      : 'linear-gradient(to right, #16a34a, #15803d)',
  },
}));

const ManualSellButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    background: 'linear-gradient(to right, #ef4444, #dc2626)',
    '&:hover': {
      background: 'linear-gradient(to right, #dc2626, #b91c1c)',
    },
  }));

const AutoTPSLModal = ({ 
    open, 
    onClose, 
    handleBuy, 
    handleBuyWithRaydium,
    handleAutoTPSLSell, 
    publicKey,
    tradingWallet,
    tradeSettings = { // Add default values
        priorityFee: 0.00001,
        slippage: 15,
        amount: 0.0001,
      },
    setTradeSettings,
  }) => {
    const [mintAddress, setMintAddress] = useState('');
    const [buyAmount, setBuyAmount] = useState(0.1);
    const [takeProfitPercent, setTakeProfitPercent] = useState(50);
    const [stopLossPercent, setStopLossPercent] = useState(20);
    const [isLoading, setIsLoading] = useState(false);
    const [localSettings, setLocalSettings] = useState({
      priorityFee: tradeSettings.priorityFee,
      slippage: Math.round(tradeSettings.slippage * 10) / 10, // Ensure proper decimal handling
      amount: tradeSettings.amount || buyAmount // Sync amount with buyAmount
  });
  
    const {
      isMonitoring,
      currentMarketCap,
      initialMarketCap,
      targetTp,
      targetSl,
      startMonitoring,
      stopMonitoring
    } = useAutoTPSL({ handleBuy, handleBuyWithRaydium, handleAutoTPSLSell, tradingWallet, tradeSettings, setTradeSettings });
  
    const handleSubmit = async () => {
        if (!mintAddress) {
          toast.error('Please enter a mint address');
          return;
        }
      
        if (buyAmount <= 0) {
          toast.error('Buy amount must be greater than 0');
          return;
        }
      
        if (!tradingWallet) {
          toast.error('Trading wallet is required');
          return;
        }
      
        setIsLoading(true);
        try {
          await startMonitoring(mintAddress, tradeSettings.amount, takeProfitPercent, stopLossPercent);
          toast.success('Monitoring started successfully');
        } catch (error) {
          console.error('Error starting TP/SL:', error);
          toast.error(error.message || 'Failed to start monitoring');
        } finally {
          setIsLoading(false);
        }
      };
  
    const handleStop = () => {
      stopMonitoring();
    };
    const handleManualSell = async () => {
        try {
          const result = await handleAutoTPSLSell(mintAddress, buyAmount);
          if (result) {
            toast.success('Manual sell executed successfully');
          }
        } catch (error) {
          console.error('Error executing manual sell:', error);
          toast.error('Failed to execute manual sell');
        }
      };
  
    // Add status display for monitoring
    const renderMonitoringStatus = () => {
      if (!isMonitoring) return null;
  
      return (
        <Box sx={{ mt: 2, p: 2, bgcolor: 'rgba(0, 0, 0, 0.2)', borderRadius: 1 }}>
          <Typography variant="subtitle2" color="white">Monitoring Status</Typography>
          <Box sx={{ mt: 1 }}>
            <Typography color="white" variant="body2">
              Initial MC: ${initialMarketCap?.toFixed(2) || '0'}
            </Typography>
            <Typography color="white" variant="body2">
              Current MC: ${currentMarketCap?.toFixed(2) || '0'}
            </Typography>
            <Typography color={targetTp && currentMarketCap >= targetTp ? 'success.main' : 'white'} variant="body2">
              TP Target: ${targetTp?.toFixed(2) || '0'}
            </Typography>
            <Typography color={targetSl && currentMarketCap <= targetSl ? 'error.main' : 'white'} variant="body2">
              SL Target: ${targetSl?.toFixed(2) || '0'}
            </Typography>
          </Box>
          <ManualSellButton
          variant="contained"
          onClick={handleManualSell}
          fullWidth
          startIcon={<SellIcon />}
        >
          Manual Sell
        </ManualSellButton>
        </Box>
      );
    };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >

      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        color: 'white'
      }}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TrendingUpIcon sx={{ color: '#3b82f6' }} /> Auto TP/SL
        </Typography>
        <IconButton onClick={onClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pb: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
        <Box sx={{ mb: -2 }}>
        <Chip
          avatar={<Avatar src="https://pbs.twimg.com/profile_images/1824073444910055424/GdSdd1l6_400x400.jpg" />}
          label="$WIF"
          onClick={() => setMintAddress('21AErpiB8uSb94oQKRcwuHqyHF93njAxBSbdUrpupump')}
          sx={{
            color: 'white',
            bgcolor: 'rgba(59, 130, 246, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(59, 130, 246, 0.2)',
            },
            cursor: 'pointer'
          }}
        />
         <Chip
          avatar={<Avatar src="https://pbs.twimg.com/profile_images/1797870574397452288/6siva-oU_400x400.jpg" />}
          label="$SPX6900"
          onClick={() => setMintAddress('0xE0f63A424a4439cBE457D80E4f4b51aD25b2c56C')}
          sx={{
            color: 'white',
            bgcolor: 'rgba(59, 130, 246, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(59, 130, 246, 0.2)',
            },
            cursor: 'pointer'
          }}
        />
         <Chip
          avatar={<Avatar src="https://pbs.twimg.com/profile_images/1848199326838988800/GhfGjGyR_400x400.jpg" />}
          label="$BITCOIN"
          onClick={() => setMintAddress('0x72e4f9F808C49A2a61dE9C5896298920Dc4EEEa9')}
          sx={{
            color: 'white',
            bgcolor: 'rgba(59, 130, 246, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(59, 130, 246, 0.2)',
            },
            cursor: 'pointer'
          }}
        />
        <Chip
          avatar={<Avatar src="https://pbs.twimg.com/profile_images/1747652306521255936/LlxV2aBK_400x400.jpg" />}
          label="$GIGA"
          onClick={() => setMintAddress('63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUUGq5tiJxcqj9')}
          sx={{
            color: 'white',
            bgcolor: 'rgba(59, 130, 246, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(59, 130, 246, 0.2)',
            },
            cursor: 'pointer'
          }}
        />
        <Chip
          avatar={<Avatar src="https://pbs.twimg.com/profile_images/1706379006063288321/rwnCvTH2_400x400.jpg" />}
          label="$MOG"
          onClick={() => setMintAddress('0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a')}
          sx={{
            color: 'white',
            bgcolor: 'rgba(59, 130, 246, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(59, 130, 246, 0.2)',
            },
            cursor: 'pointer'
          }}
        />
      </Box>
          <StyledTextField
            label="Token Mint Address"
            value={mintAddress}
            onChange={(e) => setMintAddress(e.target.value)}
            fullWidth
          />

          {/* <Box>
            <Typography sx={{ color: 'white', mb: 1 }}>Buy Amount (SOL)</Typography>
            <StyledTextField
              type="number"
              value={buyAmount}
              onChange={(e) => setBuyAmount(parseFloat(e.target.value))}
              inputProps={{ step: 0.1, min: 0 }}
              fullWidth
            />
          </Box> */}

          <Box>
            <Typography sx={{ color: 'white', mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
              <TrendingUpIcon sx={{ color: '#22c55e' }} />
              Take Profit ({takeProfitPercent}%)
            </Typography>
            <StyledSlider
              value={takeProfitPercent}
              onChange={(_, value) => setTakeProfitPercent(value)}
              min={10}
              max={1000}
              color="#22c55e"
            />
          </Box>

          <Box>
            <Typography sx={{ color: 'white', mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
              <TrendingDownIcon sx={{ color: '#ef4444' }} />
              Stop Loss ({stopLossPercent}%)
            </Typography>
            <StyledSlider
              value={stopLossPercent}
              onChange={(_, value) => setStopLossPercent(value)}
              min={5}
              max={50}
              color="#ef4444"
            />
          </Box>

          {!tradingWallet && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Please create a trading wallet first to use this feature.
            </Alert>
          )}

<ActionButton
    variant="contained"
    onClick={isMonitoring ? handleStop : handleSubmit}
    disabled={isLoading || !tradingWallet}
    ismonitoring={isMonitoring.toString()}
    fullWidth
  >
    {isLoading ? (
      <CircularProgress size={24} sx={{ color: 'white' }} />
    ) : isMonitoring ? (
      'Stop Monitoring'
    ) : (
      'Start Monitoring'
    )}
  </ActionButton>
        </Box>
        {renderMonitoringStatus()}
      <Button
          variant="contained"
          onClick={isMonitoring ? handleStop : handleSubmit}
          disabled={isLoading || !tradingWallet}
          sx={{
            py: 1.5,
            mt: 2,
            background: isMonitoring 
              ? 'linear-gradient(to right, #ef4444, #dc2626)'
              : 'linear-gradient(to right, #22c55e, #16a34a)',
            '&:hover': {
              background: isMonitoring
                ? 'linear-gradient(to right, #dc2626, #b91c1c)'
                : 'linear-gradient(to right, #16a34a, #15803d)',
            },
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : isMonitoring ? (
            'Stop Monitoring'
          ) : (
            'Start Monitoring'
          )}
        </Button>
        <Box sx={{ mt: 3 }}>
    <Typography sx={{ color: 'white', mb: 2 }}>Trade Settings</Typography>
    <TradeSettings
      priorityFee={localSettings.priorityFee}
      setPriorityFee={(fee) => {
        setLocalSettings(prev => ({ ...prev, priorityFee: fee }));
        setTradeSettings(prev => ({ ...prev, priorityFee: fee }));
      }}
      slippage={localSettings.slippage}
      setSlippage={(slip) => {
        const roundedSlippage = Math.round(slip * 10) / 10;
        setLocalSettings(prev => ({ ...prev, slippage: roundedSlippage }));
        setTradeSettings(prev => ({ ...prev, slippage: roundedSlippage }));
        localStorage.setItem('tradeSettings', JSON.stringify({
          ...tradeSettings,
          slippage: roundedSlippage
        }));
      }}
      amount={localSettings.amount} // Pass buyAmount consistently
      setAmount={(amt) => {
        setBuyAmount(amt);
        setLocalSettings(prev => ({ ...prev, amount: amt }));
        setTradeSettings(prev => ({ ...prev, amount: amt }));
      }}
    />
  </Box>
      </DialogContent>
     
      
    </StyledDialog>
  );
};

export default AutoTPSLModal;