import React, { useState } from 'react';
import { Box, Card, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const ChartCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: '12px',
  width: '100%',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  overflow: 'hidden'
}));

const TokenChart = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Use the correct URL format for GMGN
  const chartUrl = "https://www.gmgn.cc/kline/sol/DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump";
  
  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const handleIframeError = () => {
    setError('Failed to load chart');
    setIsLoading(false);
  };

  return (
    <ChartCard>
      <Typography 
        variant="h6" 
        sx={{ 
          color: 'white', 
          mb: 2,
          textAlign: 'center'
        }}
      >
        $BNNA Price Chart
      </Typography>
      <Box sx={{ 
        width: '100%', 
        height: '400px',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '8px',
        backgroundColor: 'rgba(0,0,0,0.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {isLoading && (
          <Box sx={{ position: 'absolute', zIndex: 2 }}>
            <CircularProgress />
          </Box>
        )}
        
        {error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <iframe
            src={chartUrl}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              backgroundColor: 'transparent',
              opacity: isLoading ? 0 : 1,
              transition: 'opacity 0.3s'
            }}
            title="BNNA Price Chart"
            onLoad={handleIframeLoad}
            onError={handleIframeError}
            sandbox="allow-scripts allow-same-origin"
            loading="lazy"
          />
        )}
      </Box>
    </ChartCard>
  );
};

export default TokenChart;