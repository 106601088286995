import { Box, Typography, Divider } from '@mui/material';
import React from 'react';

const SectionDivider = ({ title, icon }) => {
  return (
    <Box 
      sx={{ 
        width: '100%',
        my: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 2
      }}
    >
      <Divider 
        sx={{ 
          flexGrow: 1,
          borderColor: 'rgba(255, 255, 255, 0.1)',
          borderWidth: 1
        }} 
      />
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1,
        px: 2,
        py: 0.5,
        borderRadius: '20px',
        background: 'linear-gradient(45deg, rgba(255,255,255,0.05), rgba(255,255,255,0.1))',
        backdropFilter: 'blur(5px)',
        border: '1px solid rgba(255,255,255,0.1)'
      }}>
        {icon}
        <Typography 
          variant="h6" 
          sx={{ 
            color: 'white',
            fontWeight: 500,
            letterSpacing: '0.5px',
            textShadow: '0 2px 4px rgba(0,0,0,0.2)'
          }}
        >
          {title}
        </Typography>
      </Box>
      <Divider 
        sx={{ 
          flexGrow: 1,
          borderColor: 'rgba(255, 255, 255, 0.1)',
          borderWidth: 1
        }} 
      />
    </Box>
  );
};

export default SectionDivider;