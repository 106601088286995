import React from 'react';
import { 
  Modal, 
  Box, 
  Typography,
  IconButton
} from '@mui/material';
import { Close as CloseIcon, EmojiEvents } from '@mui/icons-material';
import PointsDisplay from '../PointsDisplay';
import Leaderboard from './Leaderboard';

const AchievementsModal = ({
  open,
  onClose,
  connected,
  hasExistingWallet,
  userTradingWallet,
  tradingWallet,
  points,
  completedTasks,
  isSignatureVerified,
  publicKey,
  isAchievementsOpen
}) => {
  return (
    <Modal
    open={isAchievementsOpen}  // Changed from 'isOpen'
    onClose={onClose}
      aria-labelledby="achievements-modal"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 600,
        bgcolor: 'background.paper',
        borderRadius: '8px',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflow: 'auto'
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <EmojiEvents sx={{ color: '#fbbf24' }} />
            Tasks & Achievements
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        {connected && hasExistingWallet && userTradingWallet && (
          <Box 
            sx={{ 
              width: '100%',
              p: 2,
              mt: 2,
              background: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              border: '1px solid rgba(255, 255, 255, 0.1)',
            }}
          >
            {tradingWallet?.walletPublicKey && (
              <>
                <PointsDisplay 
                  points={points}
                  completedTasks={completedTasks}
                />

                {isSignatureVerified && (
                  <Box sx={{ mt: 4 }}>
                    <Leaderboard currentWallet={publicKey?.toString()} />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AchievementsModal;