export const getTokenData = async (mintAddress) => {
    try {
        const cleanMintAddress = mintAddress.replace('#', '').trim();
        console.log(`[TokenData] Fetching data for: ${cleanMintAddress}`);
        
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/';
        const response = await fetch(`${baseUrl}api/pump/${cleanMintAddress}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include' // Include credentials for cross-origin requests
        });
        
        // Check content type before parsing
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
            console.error(`[TokenData] Invalid content type: ${contentType}`);
            throw new Error('Invalid response format from server');
        }

        if (!response.ok) {
            console.error(`[TokenData] HTTP error: ${response.status}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        
        // Validate the response data
        if (!data || typeof data !== 'object') {
            console.error('[TokenData] Invalid data format received');
            throw new Error('Invalid data format received');
        }

        console.log(`[TokenData] Successfully retrieved data for: ${cleanMintAddress}`);
        return data;
    } catch (error) {
        console.error('[TokenData] Error:', error.message);
        // Return a default data structure instead of null
        return {
            name: 'Unknown',
            symbol: 'Unknown',
            mint: mintAddress,
            usd_market_cap: '0',
            virtual_sol_reserves: '0',
            total_supply: '0',
            virtual_token_reserves: '0',
            reply_count: '0',
            website: null,
            twitter: null,
            telegram: null,
            description: `Unable to fetch token data: ${error.message}`,
            image_uri: null,
            created_timestamp: null,
            creator: null,
            is_currently_live: false,
            nsfw: false,
            market_cap: '0'
        };
    }
};
  
  export const assessTokenSafety = (data) => {
    if (!data) {
      return {
        safetyScore: 0,
        maxScore: 10,
        reasons: [],
        riskLevel: "Unknown",
        overall: "Unable to assess token safety due to insufficient data."
      };
    }
     // Ensure data exists with default values
     const safeData = {
        usd_market_cap: '0',
        virtual_sol_reserves: '0',
        total_supply: '0',
        virtual_token_reserves: '0',
        reply_count: '0',
        website: null,
        twitter: null,
        telegram: null,
        ...data
      };
  
    let safetyScore = 0;
    const maxScore = 10;
    const reasons = [];
    const warnings = [];
  
    // Social Presence & Verification (3 points)
    let socialScore = 0;
    if (data.website) {
      socialScore++;
      reasons.push("✓ Has a website");
    } else {
      warnings.push("✗ No website");
    }
  
    if (data.twitter) {
      socialScore++;
      reasons.push("✓ Has Twitter presence");
    }
  
    if (data.telegram) {
      socialScore++;
      reasons.push("✓ Has Telegram community");
    }
  
    if (socialScore === 3) {
      safetyScore += 3;
    } else {
      safetyScore += socialScore;
      warnings.push(`⚠ Limited social presence (${socialScore}/3)`);
    }
  
    // Market Metrics (4 points)
    // Market Cap Check
    const marketCapUSD = parseFloat(data.usd_market_cap || 0);
    if (marketCapUSD > 100000) {
      safetyScore += 1;
      reasons.push("✓ Market cap over $100,000");
    } else if (marketCapUSD > 50000) {
      safetyScore += 0.5;
      warnings.push("⚠ Moderate market cap");
    } else {
      warnings.push("⚠ Low market cap");
    }
  
    // Virtual SOL Reserves Check
    const virtualSolReserves = parseFloat(data.virtual_sol_reserves || 0) / 1e9; // Convert to SOL
    if (virtualSolReserves > 50) {
      safetyScore += 1;
      reasons.push("✓ Strong liquidity pool");
    } else if (virtualSolReserves > 20) {
      safetyScore += 0.5;
      warnings.push("⚠ Moderate liquidity");
    } else {
      warnings.push("⚠ Low liquidity");
    }
  
    // Supply Distribution (2 points)
    const totalSupply = parseFloat(data.total_supply || 0);
    const tokenReserves = parseFloat(data.virtual_token_reserves || 0);
    if (totalSupply && tokenReserves) {
      const circulatingRatio = tokenReserves / totalSupply;
      if (circulatingRatio <= 0.5) {
        safetyScore += 2;
        reasons.push("✓ Healthy token distribution");
      } else if (circulatingRatio <= 0.7) {
        safetyScore += 1;
        warnings.push("⚠ Moderate token concentration");
      } else {
        warnings.push("⚠ High token concentration");
      }
    }
  
    // Community Engagement (1 point)
    const replyCount = parseInt(data.reply_count || 0);
    if (replyCount > 10) {
      safetyScore += 1;
      reasons.push("✓ Active community engagement");
    } else if (replyCount > 5) {
      safetyScore += 0.5;
      warnings.push("⚠ Limited community engagement");
    }
  
    // Calculate risk level
    let riskLevel;
    if (safetyScore >= 8) {
      riskLevel = "Low Risk";
    } else if (safetyScore >= 6) {
      riskLevel = "Moderate Risk";
    } else if (safetyScore >= 4) {
      riskLevel = "High Risk";
    } else {
      riskLevel = "Extreme Risk";
    }
  
    // Generate overall assessment
    let overall;
    if (safetyScore >= 8) {
      overall = "This token shows strong safety indicators and good market fundamentals.";
    } else if (safetyScore >= 6) {
      overall = "This token has moderate safety indicators. Exercise caution and do your own research.";
    } else if (safetyScore >= 4) {
      overall = "This token shows several risk factors. High-risk investment.";
    } else {
      overall = "This token has multiple red flags. Extreme caution advised.";
    }
  
    return {
        safetyScore: Number(safetyScore.toFixed(1)),
        maxScore,
        reasons: reasons.length ? reasons : ['No positive indicators found'],
        warnings: warnings.length ? warnings : ['No specific warnings'],
        riskLevel: riskLevel || 'Unknown',
        overall: overall || 'Unable to assess token.',
        metrics: {
          marketCapUSD: (parseFloat(safeData.usd_market_cap) || 0).toFixed(2),
          liquiditySOL: (parseFloat(safeData.virtual_sol_reserves) / 1e9 || 0).toFixed(2),
          totalSupply: (parseFloat(safeData.total_supply) || 0).toLocaleString(),
          replyCount: parseInt(safeData.reply_count) || 0
        }
      };
  };
  
  export const formatTokenData = (data) => {
    if (!data) {
        return {
          tokenInfo: {
            name: 'Unknown',
            symbol: 'Unknown',
            mintAddress: 'Unknown',
            creator: 'Unknown',
            createdDate: 'N/A',
            totalSupply: '0',
          },
          marketData: {
            marketCapSOL: '0 SOL',
            marketCapUSD: '$0.00',
            virtualSolReserves: '0 SOL',
            virtualTokenReserves: '0',
          },
          socialLinks: {
            website: 'N/A',
            twitter: 'N/A',
            telegram: 'N/A',
          },
          communityMetrics: {
            replyCount: 0,
            lastReplyTime: 'N/A',
          },
          description: 'N/A',
          imageUri: null,
          isNSFW: false,
          isLive: false,
        };
      }
  
    const createdDate = data.created_timestamp 
      ? new Date(data.created_timestamp).toLocaleDateString()
      : 'N/A';
  
    return {
      tokenInfo: {
        name: data.name || 'N/A',
        symbol: data.symbol || 'N/A',
        mintAddress: data.mint || 'N/A',
        creator: data.creator || 'N/A',
        createdDate,
        totalSupply: data.total_supply?.toLocaleString() || 'N/A',
      },
      marketData: {
        marketCapSOL: `${Number(data.market_cap || 0).toFixed(2)} SOL`,
        marketCapUSD: `$${Number(data.usd_market_cap || 0).toFixed(2)}`,
        virtualSolReserves: `${(Number(data.virtual_sol_reserves || 0) / 1e9).toFixed(2)} SOL`,
        virtualTokenReserves: Number(data.virtual_token_reserves || 0).toLocaleString(),
      },
      socialLinks: {
        website: data.website || 'N/A',
        twitter: data.twitter || 'N/A',
        telegram: data.telegram || 'N/A',
      },
      communityMetrics: {
        replyCount: data.reply_count || 0,
        lastReplyTime: data.last_reply 
          ? new Date(data.last_reply).toLocaleString()
          : 'N/A',
      },
      description: data.description || 'N/A',
      imageUri: data.image_uri || null,
      isNSFW: data.nsfw || false,
      isLive: data.is_currently_live || false,
    };
  };
  
  // Main assessment function
  export const assessToken = async (mintAddress) => {
    try {
      const data = await getTokenData(mintAddress);
      if (!data) {
        throw new Error('Failed to fetch token data');
      }
  
      const formattedData = formatTokenData(data);
      const assessment = assessTokenSafety(data);
  
      return {
        ...assessment,
        tokenData: formattedData,
      };
    } catch (error) {
      console.error('Error assessing token:', error);
      return {
        safetyScore: 0,
        maxScore: 10,
        reasons: ['Error fetching token data'],
        warnings: ['Unable to assess token'],
        riskLevel: "Unknown",
        overall: 'Unable to assess token due to an error.',
        tokenData: null,
      };
    }
  };