import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '@mui/material';

const PointsDisplay = ({ points, completedTasks }) => {
  return (
    <div className="mt-4 p-4 bg-gray-800 rounded-lg">
      <div className="flex justify-between items-center mb-4" sx={{color:'white'}}>
        <h2 className="text-xl font-bold text-white" style={{ color:'black' }} sx={{
            color: 'white',
            
          }}>$GAPE Points: {points}</h2>
          <a 
  href="https://apeout.meme/gape" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-sm hover:text-gray-300 transition-colors"
  style={{ 
    textDecoration: 'underline', 
    fontSize: '0.75rem',
    marginTop: '4px',
    marginBottom: '4px',
    display: 'block',
    color: 'black',  // Changed to white
    opacity: 0.9,      // Added slight transparency
    '&:hover': {       // Added hover effect
      opacity: 1,
      textShadow: '0 0 8px rgba(255,255,255,0.3)'
    }
  }}
>
  What is $GAPE?
</a>
        <Button disabled
          className="px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition-colors"
          onClick={() => alert('Airdrop claiming coming soon!')}
          
          sx={{
            width: '100%',
            py: 2,
            color: 'white',
            background: 'linear-gradient(to right, #f59e0b, #d97706)',
            '&:hover': {
              background: 'linear-gradient(to right, #d97706, #b45309)',
            },
          }}
        >
          Claim $GAPE AIRDROP
        </Button>
      </div>
      
      <div className="space-y-2">
        <h3 className="text-lg font-semibold text-white mb-2" style={{ color:'black' }}>Completed Tasks:</h3>
        <div className="flex items-center space-x-2">
          <CheckCircleIcon 
            sx={{ 
              fontSize: 20,
              color: completedTasks.walletConnected ? '#22c55e' : '#6b7280'
            }}
          />
          <span style={{ color:'black' }} className="text-white">Connect Wallet</span>
        </div>
        <div className="flex items-center space-x-2">
          <CheckCircleIcon 
            sx={{ 
              fontSize: 20,
              color: completedTasks.tradingWalletCreated ? '#22c55e' : '#6b7280'
            }}
          />
          <span style={{ color:'black' }} className="text-white">Create Trading Wallet</span>
        </div>
        {/* new task here */}
        <div className="flex items-center space-x-2">
          <CheckCircleIcon 
            sx={{ 
              fontSize: 20,
              color: completedTasks.boughtBNNA ? '#22c55e' : '#6b7280'
            }}
          />
          <span style={{ color:'black' }} className="text-white">Purchase $BNNA in our App</span>
        </div>
        <div className="flex items-center space-x-2">
          <CheckCircleIcon 
            sx={{ 
              fontSize: 20,
              color: completedTasks.usedTokenAssessor ? '#22c55e' : '#6b7280'
            }}
          />
          <span style={{ color:'black' }} className="text-white">Try our Token Assessor Utility For Degens</span>
        </div>
        <div className="flex items-center space-x-2">
          <CheckCircleIcon 
            sx={{ 
              fontSize: 20,
              color: completedTasks.usedPumpCalculator ? '#22c55e' : '#6b7280'
            }}
          />
          <span style={{ color:'black' }} className="text-white">Try our Pump Calculator Utility For Degens</span>
        </div>
        <div className="flex items-center space-x-2">
          <CheckCircleIcon 
            sx={{ 
              fontSize: 20,
              color: completedTasks.bananaBurner ? '#22c55e' : '#6b7280'
            }}
          />
          <span style={{ color:'black' }} className="text-white">Burn Some BNNA  </span>
        </div>
        <div className="flex items-center space-x-2">
          <CheckCircleIcon 
            sx={{ 
              fontSize: 20,
              color: completedTasks.bananaResearcher ? '#22c55e' : '#6b7280'
            }}
          />
          <span style={{ color:'black' }} className="text-white">DYOR at our website </span>
        </div>
        <div className="flex items-center space-x-2">
          <CheckCircleIcon 
            sx={{ 
              fontSize: 20,
              color: completedTasks.bananaGamer ? '#22c55e' : '#6b7280'
            }}
          />
          <span style={{ color:'black' }} className="text-white">Go Play Our Game </span>
        </div>
        <div className="flex items-center space-x-2">
  <CheckCircleIcon 
    sx={{ 
      fontSize: 20,
      color: completedTasks.richApe ? '#22c55e' : '#6b7280'
    }}
  />
  <span style={{ color:'black' }} className="text-white">Become a Rich Ape (Own 50M $BNNA)</span>
</div>

      </div>
    </div>
  );
};

export default PointsDisplay;