import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, Typography, Box, LinearProgress, IconButton, Card, CardContent, Slider, TextField } from '@mui/material';
// import { Close as CloseIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemText, CircularProgress } from '@mui/material';
// import { Sell as SellIcon, TrendingUp } from '@mui/icons-material';
import { Close as CloseIcon, Sell as SellIcon, TrendingUp, TrendingDown, Refresh as RefreshIcon } from '@mui/icons-material';
import TradeSettings from './components/trading/TradeSettings';
import pumpLogo from './assets/pump-logo.png';
import TokenGains from './components/TokenGains';
import TokenPurchaseHistory from './components/trading/TokenPurchaseHistory';
import TokenBalanceDisplay from './components/trading/TokenBalanceDisplay';
import LaunchIcon from '@mui/icons-material/Launch';





const toastConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  style: {
    background: 'rgba(23, 32, 42, 0.95)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '12px',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
    color: 'white',
    fontSize: '14px',
    padding: '16px',
  },
  progressStyle: {
    background: 'linear-gradient(to right, #4CAF50, #81C784)',
  },
};

const getPumpProfileUrl = (walletAddress) => {
  return `https://pump.fun/profile/${walletAddress}`;
};

const DemoAutoApeModal = ({ 
  open, 
  onClose, 
  tradingWallet, 
  handleBuy, 
  handleSell, 
  ownedTokensData = [], // Add default empty array
  setTradeSettings, 
  tradeSettings = [] ,
  tokenPurchases = [], // Add this line


}) => {
  const [currentMintAddress, setCurrentMintAddress] = useState('');
  const [isDemo, setIsDemo] = useState(false);
  const [progress, setProgress] = useState(0);
  const shownNotifications = useRef(new Set());
  const currentTokenIndex = useRef(0);
  const isProcessing = useRef(false);
  const pollInterval = useRef(10000);
  // Add these at the top of your component with other state/refs
const processedMints = useRef(new Set());
const MAX_POLL_INTERVAL = 30000; // Max 30 second interval
const INITIAL_POLL_INTERVAL = 10000;
const pollTimeoutRef = useRef(null);
const BACKOFF_MULTIPLIER = 1.5;       // How much to increase interval on failure
  const MAX_RETRIES = 3;                // Max retries before backing off
  const REQUEST_TIMEOUT = 5000;
  const retryCount = useRef(0);
  const lastRequestTime = useRef(Date.now());
  // Add a ref to store the cached mint address
const cachedMintAddress = useRef(null);
const [showOwnedTokens, setShowOwnedTokens] = useState(false);
const [tokenDetails, setTokenDetails] = useState({});
const [balances, setBalances] = useState({});
const [isLoadingBalances, setIsLoadingBalances] = useState(true);
const balanceFetchRef = useRef(null);
const lastFetchTime = useRef(Date.now());
const FETCH_COOLDOWN = 5000; // 5s cooldown between fetches
const [tokenDetailsMap, setTokenDetailsMap] = useState({});
const [isLoadingDetails, setIsLoadingDetails] = useState(false);
const [loadingProgress, setLoadingProgress] = useState(0);
const [isFetchingData, setIsFetchingData] = useState(false);
const [localSettings, setLocalSettings] = useState({
  priorityFee: tradeSettings.priorityFee,
  slippage: tradeSettings.slippage,
  amount: tradeSettings.amount || 0.001 // Add default amount
});
const [tokenPurchaseHistory, setTokenPurchaseHistory] = useState([]); // Initialize as array instead of object

  const DEMO_TOKENS = [
    'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump',
    
    //
  ]

  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();
  
    const fetchBalances = async () => {
      if (!tradingWallet?.walletPublicKey) return;
      
      try {
        setIsLoadingBalances(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/user_balances/${tradingWallet.walletPublicKey}`,
          { signal: controller.signal }
        );
        
        if (!response.ok) throw new Error('Failed to fetch balances');
        
        const data = await response.json();
        if (mounted) {
          console.debug('Balances updated:', data);
          // Extract and format balances
          const formattedBalances = {};
          data.balances.forEach(token => {
            formattedBalances[token.mint] = token.balance;
          });
          setBalances(formattedBalances);
          setIsLoadingBalances(false);
        }
      } catch (error) {
        if (error.name === 'AbortError') return;
        console.error('Error fetching balances:', error);
        if (mounted) setIsLoadingBalances(false);
      }
    };
  
    fetchBalances();
    const interval = setInterval(fetchBalances, 100000);
  
    return () => {
      mounted = false;
      controller.abort();
      clearInterval(interval);
    };
  }, [tradingWallet?.walletPublicKey]);
  // Add manual refresh function
  const handleRefreshBalances = async () => {
    try {
      console.log('Starting balance refresh...');
      setIsLoadingBalances(true);
      
      if (!tradingWallet?.walletPublicKey) {
        console.log('No wallet connected, aborting refresh');
        return;
      }
  
      // Fetch updated balances first
      const balanceResponse = await fetch(
        `${process.env.REACT_APP_API_URL}api/user_balances/${tradingWallet.walletPublicKey}`
      );
      
      if (!balanceResponse.ok) {
        throw new Error('Failed to fetch balances');
      }
      
      const balanceData = await balanceResponse.json();
      console.log('Updated balances:', balanceData);
  
      // Format and set balances
      const formattedBalances = {};
      balanceData.balances.forEach(token => {
        formattedBalances[token.mint] = token.balance;
      });
      setBalances(formattedBalances);
  
      // Then fetch token details
      console.log('Fetching token details...');
      await fetchTokenData();
  
    } catch (error) {
      console.error('Error refreshing data:', error);
      toast.error('Failed to refresh token data');
    } finally {
      setIsLoadingBalances(false);
    }
  };
useEffect(() => {
  let mounted = true;
  let progressInterval;
  
  if (isDemo) {
    // Initialize progress bar
    setProgress(0);
    progressInterval = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress + 5;
        if (newProgress >= 100) {
          if (mounted) processNextToken();
          return 0;
        }
        return newProgress;
      });
    }, Math.min(pollInterval.current / 20, 500)); // Adjust progress speed based on poll interval

    // Polling function with rate limiting
    const pollMints = async () => {
      if (!mounted || !isDemo) return;

      try {
        const timeElapsed = Date.now() - lastRequestTime.current;
        if (timeElapsed < pollInterval.current) {
          await new Promise(resolve => 
            setTimeout(resolve, pollInterval.current - timeElapsed)
          );
        }

        await fetchCurrentMint();

        if (mounted && isDemo) {
          // Clear existing timeout before setting new one
          if (pollTimeoutRef.current) {
            clearTimeout(pollTimeoutRef.current);
          }
          pollTimeoutRef.current = setTimeout(pollMints, pollInterval.current);

          // Gradually decrease interval on success if we're not at initial
          if (pollInterval.current > INITIAL_POLL_INTERVAL) {
            pollInterval.current = Math.max(
              pollInterval.current / BACKOFF_MULTIPLIER,
              INITIAL_POLL_INTERVAL
            );
            console.log(`Success - Decreasing poll interval to: ${pollInterval.current}ms`);
          }
        }
      } catch (error) {
        console.error('Polling error:', error);
        if (mounted && isDemo) {
          // Increase interval on error
          retryCount.current++;
          if (retryCount.current >= MAX_RETRIES) {
            console.log('Max retries reached - Backing off significantly');
            pollInterval.current = MAX_POLL_INTERVAL;
            retryCount.current = 0;
          } else {
            pollInterval.current = Math.min(
              pollInterval.current * BACKOFF_MULTIPLIER,
              MAX_POLL_INTERVAL
            );
          }
          console.log(`Error - Increasing poll interval to: ${pollInterval.current}ms`);
          pollTimeoutRef.current = setTimeout(pollMints, pollInterval.current);
        }
      }
    };

    // Start initial poll
    pollMints();
  }

  // Cleanup function
  return () => {
    mounted = false;
    if (progressInterval) clearInterval(progressInterval);
    if (pollTimeoutRef.current) clearTimeout(pollTimeoutRef.current);
    // Reset all state
    retryCount.current = 0;
    pollInterval.current = INITIAL_POLL_INTERVAL;
    lastRequestTime.current = Date.now();
  };
}, [isDemo]); // Only depend on isDemo



const loadPurchaseHistory = async () => {
  if (!tradingWallet?.walletPublicKey) return;
  
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/users/${tradingWallet.walletPublicKey}`
    );
    
    if (!response.ok) throw new Error('Failed to fetch user data');
    
    const userData = await response.json();
    if (Array.isArray(userData.tokenPurchases)) {
      setTokenPurchaseHistory(userData.tokenPurchases);
      console.debug('Loaded purchase history:', userData.tokenPurchases);
    } else {
      console.warn('Invalid token purchase data:', userData.tokenPurchases);
      setTokenPurchaseHistory([]);
    }
  } catch (error) {
    console.error('Error loading purchase history:', error);
    setTokenPurchaseHistory([]);
  }
};
  const fetchWithTimeout = async (url, options = {}) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);

    try {
      const timeElapsed = Date.now() - lastRequestTime.current;
      if (timeElapsed < pollInterval.current) {
        await new Promise(resolve => setTimeout(resolve, pollInterval.current - timeElapsed));
      }

      const response = await fetch(url, {
        ...options,
        signal: controller.signal
      });

      if (!response.ok) {
        if (response.status === 429) {
          retryCount.current++;
          pollInterval.current = Math.min(
            pollInterval.current * BACKOFF_MULTIPLIER,
            MAX_POLL_INTERVAL
          );
          console.log(`Rate limited, increasing interval to ${pollInterval.current}ms`);
          throw new Error('Rate limit reached');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Reset on successful request
      retryCount.current = 0;
      lastRequestTime.current = Date.now();
      return response;
    } finally {
      clearTimeout(timeout);
    }
  };
  const processNextToken = async () => {
    if (isProcessing.current || !isDemo) return;
    
    try {
      isProcessing.current = true;
      
      // Try to get real mint first, fall back to demo tokens if none available
      const mintAddress = await fetchCurrentMint();

      // Skip if we've already processed this mint address
      if (mintAddress && processedMints.current.has(mintAddress)) {
        console.log(`Already processed token: ${mintAddress}`);
        return;
      }
      const tokenToProcess = mintAddress 
      
      if (tokenToProcess) {
        if (tokenToProcess !== currentMintAddress) {
          setCurrentMintAddress(tokenToProcess);
          
          toast.info(
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ color: '#81C784' }}>🔍</span>
              <div>
                <div style={{ fontWeight: 500 }}>New Token Detected</div>
                <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
                  {currentMintAddress}
                </div>
              </div>
            </div>,
            toastConfig
          );
          
          await new Promise(resolve => setTimeout(resolve, 2000));
          await handleBuy(tokenToProcess, 0.0001);
          // Add to processed mints after successful purchase
          processedMints.current.add(tokenToProcess);
          
          // Reset poll interval after finding new token
          pollInterval.current = INITIAL_POLL_INTERVAL;
          
          // // Only increment demo index if we used a demo token
          // if (!mintAddress) {
          //   currentTokenIndex.current = (currentTokenIndex.current + 1) % DEMO_TOKENS.length;
          // }
        }
    }
    } catch (error) {
      console.error('Error processing token:', error);
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>❌</span>
          <div>
            <div style={{ fontWeight: 500 }}>Auto-Ape Buy Failed</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              {error.message}
            </div>
          </div>
        </div>,
        toastConfig
      );
    } finally {
      isProcessing.current = false;
    }
  };
  const fetchCurrentMint = async () => {
    try {

      
      if (retryCount.current >= MAX_RETRIES) {
        console.log('Max retries reached, pausing requests temporarily');
        await new Promise(resolve => setTimeout(resolve, MAX_POLL_INTERVAL));
        retryCount.current = 0;
      }

      if (!cachedMintAddress.current) {

        const response = await fetchWithTimeout(`${process.env.REACT_APP_API_URL}api/current-mint`);
        const data = await response.json();
        
        if (data.success && data.mintAddress && data.mintAddress !== currentMintAddress) {
          setCurrentMintAddress(data.mintAddress);
          // Gradually decrease interval on success
          pollInterval.current = Math.max(
            pollInterval.current / BACKOFF_MULTIPLIER,
            INITIAL_POLL_INTERVAL
          );
          return data.mintAddress;
        }
      }
      return cachedMintAddress.current;
    } catch (error) {
      console.error('Error fetching current mint:', error);
      return cachedMintAddress.current;
    }
  };
  const fetchTokenDetails = async () => {
    setIsLoadingDetails(true);
    setLoadingProgress(0);
    const details = {};
    const tokens = Object.keys(balances);
    
    for (let i = 0; i < tokens.length; i++) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${tokens[i]}`);
        const data = await response.json();
        if (data.success) {
          details[tokens[i]] = data.data;
        }
        setLoadingProgress(((i + 1) / tokens.length) * 100);
        await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (error) {
        console.error(`Error fetching details for ${tokens[i]}:`, error);
      }
    }
    
    setTokenDetailsMap(details);
    console.log(tokenDetailsMap)
    setIsLoadingDetails(false);
  };
  const fetchTokenData = async () => {
    setIsFetchingData(true);
    try {
      const tokens = Object.keys(balances);
      const details = {};
      
      for (const mint of tokens) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${mint}`);
        const data = await response.json();
        if (data.success) {
          details[mint] = data.data;
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      
      setTokenDetailsMap(details);
      
      console.log('MAP', tokenDetailsMap)
    } catch (error) {
      console.error('Error fetching token data:', error);
    }
    setIsFetchingData(false);
   };

  const cleanup = () => {
    if (pollTimeoutRef.current) {
      clearTimeout(pollTimeoutRef.current);
    }
    retryCount.current = 0;
    pollInterval.current = INITIAL_POLL_INTERVAL;
    lastRequestTime.current = 0;
  };

  const simulateAutoBuy = async (mintAddress) => {
    try {
      const mintAddressStr = mintAddress.toString();
      
      // Only show notification if we haven't shown it for this mint address
      if (!shownNotifications.current.has(mintAddressStr)) {
        toast.info(`New token detected: ${mintAddressStr.slice(0, 6)}...${mintAddressStr.slice(-4)}`);
        shownNotifications.current.add(mintAddressStr);
      }

      await handleBuy(mintAddressStr);
    } catch (error) {
      console.error('Error in demo buy:', error);
      toast.error('Demo buy failed');
    }
  };

  const startDemo = async () => {
    if (!tradingWallet) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>⚠️</span>
          <div>
            <div style={{ fontWeight: 500 }}>Wallet Required</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              Please connect your trading wallet first
            </div>
          </div>
        </div>,
        toastConfig
      );
      return;
    }
  
    try {
      // Start token monitoring
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/start-monitoring`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to start token monitoring');
      }
  
      setIsDemo(true);
      toast.info(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ color: '#81C784' }}>🚀</span>
          <div>
            <div style={{ fontWeight: 500 }}>Auto-Ape  Started</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              Monitoring for new tokens...
            </div>
          </div>
        </div>,
        toastConfig
      );
    } catch (error) {
      console.error('Error starting demo:', error);
      toast.error('Failed to start Auto-Ape demo');
    }
  };
  
  const stopDemo = async () => {
    try {
      // Stop token monitoring
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/stop-monitoring`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to stop token monitoring');
      }
  
      setIsDemo(false);
      setProgress(0);
      setCurrentMintAddress('');
      shownNotifications.current.clear();
      currentTokenIndex.current = 0;
  
      toast.info(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>✋</span>
          <div>
            <div style={{ fontWeight: 500 }}>Auto-Ape Stopped</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              Auto-Ape has been stopped
            </div>
          </div>
        </div>,
        toastConfig
      );
    } catch (error) {
      console.error('Error stopping Auto-Ape:', error);
      toast.error('Failed to stop Auto-Ape ');
    }
  };

  return (
    <>
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
          backdropFilter: 'blur(16px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, color: 'white' }}>
        Auto-Ape Demo
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent>
        <Typography sx={{ mb: 2, color: 'white' }}>
          Experience how Auto-Ape automatically buys new tokens as they're minted on the Solana blockchain.
        </Typography>

        {currentMintAddress && (
          <Box sx={{ mb: 2, p: 2, bgcolor: 'rgba(0, 0, 0, 0.2)', borderRadius: 1 }}>
            <Typography sx={{ color: 'white' }}>
              Latest Mint: {currentMintAddress.slice(0, 6)}...{currentMintAddress.slice(-4)}
            </Typography>
          </Box>
        )}

        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress 
            variant="determinate" 
            value={progress}
            sx={{
              height: 10,
              borderRadius: 5,
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              '& .MuiLinearProgress-bar': {
                bgcolor: 'primary.main',
              },
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Button
            onClick={isDemo ? stopDemo : startDemo}
            variant="contained"
            sx={{
              bgcolor: isDemo ? 'error.main' : 'success.main',
              '&:hover': {
                bgcolor: isDemo ? 'error.dark' : 'success.dark',
              },
            }}
          >
            {isDemo ? 'Stop Auto-Ape' : 'Start Auto-Ape'}
          </Button>
          <Button
            onClick={() => onClose()}
            variant="outlined"
            sx={{
              color: 'white',
              borderColor: 'white',
              '&:hover': {
                borderColor: 'white',
                bgcolor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            Close
          </Button>
              
        <Button
          onClick={() => setShowOwnedTokens(true)}
          variant="contained"
          sx={{
            bgcolor: 'primary.main',
            '&:hover': { bgcolor: 'primary.dark' },
          }}
          startIcon={<SellIcon />}
        >
          View Owned Tokens 
        </Button>
        <Button
  onClick={() => {
    if (tradingWallet?.walletPublicKey) {
      window.open(getPumpProfileUrl(tradingWallet.walletPublicKey), '_blank', 'noopener,noreferrer');
    } else {
      toast.warn(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>⚠️</span>
          <div>
            <div style={{ fontWeight: 500 }}>Wallet Required</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              Please connect your trading wallet to view your profile
            </div>
          </div>
        </div>,
        toastConfig
      );
    }
  }}
  variant="contained"
  sx={{
    bgcolor: 'primary.main',
    '&:hover': { bgcolor: 'primary.dark' },
  }}
  startIcon={<LaunchIcon />} // Replace SellIcon with LaunchIcon for better UX
>
  View Profile
</Button>
  
        </Box>
        <TradeSettings
  priorityFee={localSettings.priorityFee}
  setPriorityFee={(fee) => {
    setLocalSettings(prev => ({ ...prev, priorityFee: fee }));
    setTradeSettings(prev => ({ ...prev, priorityFee: fee }));
    localStorage.setItem('tradeSettings', JSON.stringify({
      ...tradeSettings,
      priorityFee: fee
    }));
  }}
  slippage={localSettings.slippage}
  setSlippage={(slip) => {
    setLocalSettings(prev => ({ ...prev, slippage: slip }));
    setTradeSettings(prev => ({ ...prev, slippage: slip }));
    localStorage.setItem('tradeSettings', JSON.stringify({
      ...tradeSettings,
      slippage: slip
    }));
  }}
  amount={localSettings.amount}
  setAmount={(amt) => {
    setLocalSettings(prev => ({ ...prev, amount: amt }));
    setTradeSettings(prev => ({ ...prev, amount: amt }));
    localStorage.setItem('tradeSettings', JSON.stringify({
      ...tradeSettings,
      amount: amt
    }));
  }}
  className="modal-trade-settings"
/>
      </DialogContent>

    </Dialog>
    <Dialog open={showOwnedTokens} onClose={() => setShowOwnedTokens(false)} maxWidth="md" fullWidth>
        <DialogTitle sx={{ color: 'black', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Owned Tokens
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton
              onClick={handleRefreshBalances}
              disabled={isLoadingBalances}
              sx={{ color: 'white' }}
            >
              <RefreshIcon sx={{ color: 'green' }}  />
            </IconButton>
            <IconButton onClick={() => setShowOwnedTokens(false)} sx={{ '& svg': { color: '#22c55e' } }} >
              <CloseIcon />
            </IconButton>
            <Button
      onClick={fetchTokenData}
      disabled={isFetchingData}
      variant="contained"
      size="small"
      sx={{ bgcolor: 'primary.main' }}
      startIcon={isLoadingDetails ? <CircularProgress size={16} /> : null}
    >
      {isLoadingDetails ? `Loading ${Math.round(loadingProgress)}%` : 'Fetch Details'}
    </Button>
          </Box>
        </DialogTitle>
        
        <DialogContent>
          
            <TokenBalanceDisplay 
      tradingWallet={tradingWallet}
      handleSell={handleSell}
      tokenPurchases={tokenPurchases}
      onRefresh={handleRefreshBalances}
      isLoading={isLoadingBalances}
    />
          <TokenPurchaseHistory 
  tokenPurchases={tokenPurchases}
  currentMarketCaps={Object.fromEntries(
    Object.entries(tokenDetailsMap).map(([mint, details]) => [
      mint, 
      details.marketCap
    ])
  )}
/>
        </DialogContent>
      </Dialog>
    </>
    
  );
};

export default DemoAutoApeModal;