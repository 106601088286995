import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AutoTPSLButton from './trading/AutoTPSLButton'; // Make sure to adjust the import path

const PremiumModal = ({
  isOpen,
  setIsOpen,
  publicKey,
  tradingWallet,
  handleBuy,
  handleAutoTPSLSell,
  isPremium,
  handleBuyWithRaydium,
  navigate,
  setShowPumpAssessment,
  setShowDemoModal,
  setShowAutoBumpModal,
}) => {
  const handleClose = () => setIsOpen(false);

  const buttonStyle = {
    background: 'linear-gradient(to right, #3b82f6, #2563eb)',
    '&:hover': {
      background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
    },
    color: 'white',
    textTransform: 'none',
    width: '100%',
    py: 1,
    borderRadius: 2,
  };

  return (
    <>
      {/* <Button
        onClick={() => setIsOpen(true)}
        startIcon={<StarIcon />}
        sx={{
          background: 'linear-gradient(to right, #fbbf24, #f97316)',
          '&:hover': {
            background: 'linear-gradient(to right, #f97316, #ea580c)',
          },
          '&:disabled': {
            background: 'linear-gradient(to right, #9ca3af, #6b7280)',
            opacity: 0.6,
            cursor: 'not-allowed',
          },
          color: 'white',
          textTransform: 'none',
          fontWeight: 600,
          py: 1,
          px: 2,
          borderRadius: 2,
          boxShadow: 2,
        }}
      >
        {isPremium === 'PREMIUM' ? 'Premium Active' : 'Upgrade to Premium'}
      </Button> */}

      <Dialog 
        open={isOpen} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 3,
            bgcolor: 'background.paper',
            maxWidth: '42rem',
            margin: { xs: 2, sm: 'auto' },
          }
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mb: 3 }}>
            <StarIcon sx={{ color: '#fbbf24' }} />
            <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
              
            </Typography>
            <StarIcon sx={{ color: '#fbbf24' }} />
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mb: 2 }}>
              <StarIcon sx={{ color: '#fbbf24', fontSize: 20 }} />
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                Automations & Tools
              </Typography>
              <StarIcon sx={{ color: '#fbbf24', fontSize: 20 }} />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() => {
                    setShowPumpAssessment(true);
                    handleClose();
                  }}
                  sx={buttonStyle}
                >
                  Analyze Pump Tokens
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  startIcon={<StarIcon />}
                  onClick={() => {
                    setShowDemoModal(true);
                    handleClose();
                  }}
                  sx={buttonStyle}
                >
                  Auto-Ape (On Mint)
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  startIcon={<StarIcon />}
                  onClick={() => {
                    navigate('/ai-agents', { 
                      state: { publicKey: publicKey?.toString() } 
                    });
                    handleClose();
                  }}
                  sx={buttonStyle}
                >
                  Create AI Trading Agent
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  startIcon={<TrendingUpIcon />}
                  onClick={() => {
                    setShowAutoBumpModal(true);
                    handleClose();
                  }}
                  sx={{
                    ...buttonStyle,
                    background: 'linear-gradient(to right, #4b5563, #374151)',
                    '&:hover': {
                      background: 'linear-gradient(to right, #374151, #1f2937)',
                    },
                  }}
                >
                  Auto-Bumper
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ width: '100%' }}>
                  <AutoTPSLButton
                    publicKey={publicKey}
                    tradingWallet={tradingWallet}
                    handleBuy={handleBuy}
                    handleAutoTPSLSell={handleAutoTPSLSell}
                    isPremium={isPremium}
                    handleBuyWithRaydium={handleBuyWithRaydium}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PremiumModal;