export const getTokenBurnData = async (mintAddress) => {
  try {
      console.log(`[BurnData] Fetching data for: ${mintAddress}`);
      
      // Use the correct base URL based on environment
      const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/';
      const response = await fetch(`${baseUrl}api/token-burns/${mintAddress}`, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          credentials: 'include' // Include credentials for cross-origin requests
      });

      if (!response.ok) {
          console.error(`[BurnData] HTTP error: ${response.status}`);
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (!data.success) {
          console.error(`[BurnData] API error: ${data.error}`);
          throw new Error(data.error);
      }
      
      console.log(`[BurnData] Successfully retrieved data for: ${mintAddress}`);
      return data.data;
  } catch (error) {
      console.error('[BurnData] Error:', error.message);
      // Return default burn data
      return {
          currentSupply: '0',
          burnedAmount: '0',
          burnPercentage: '0',
          maxSupply: '0',
          decimals: 0
      };
  }
};