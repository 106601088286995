import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// Simple SVG Icons as components
const PlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polygon points="5 3 19 12 5 21 5 3" />
  </svg>
);

const PauseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <rect x="6" y="4" width="4" height="16" />
    <rect x="14" y="4" width="4" height="16" />
  </svg>
);

const VolumeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
    <path d="M15.54 8.46a5 5 0 0 1 0 7.07" />
    <path d="M19.07 4.93a10 10 0 0 1 0 14.14" />
  </svg>
);

const MuteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
    <line x1="23" y1="9" x2="17" y2="15" />
    <line x1="17" y1="9" x2="23" y2="15" />
  </svg>
);

const FullscreenIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
  </svg>
);


const VideoContainer = styled(Box)(({ theme, orientation }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 2,
  background: 'linear-gradient(135deg, rgba(17, 24, 39, 0.9), rgba(31, 41, 55, 0.9))',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease',
  width: '100%',
  maxWidth: orientation === 'vertical' ? '400px' : '800px',
  aspectRatio: orientation === 'vertical' ? '9/16' : '16/9',
  '&:hover': {
    border: '1px solid rgba(255, 255, 255, 0.2)',
    boxShadow: '0 0 30px rgba(0, 0, 0, 0.5)',
  }
}));

const VideoControls = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(3),
  background: 'linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent)',
  opacity: 0,
  transition: 'opacity 0.5s ease',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  backdropFilter: 'blur(8px)',
  '.MuiBox-root:hover &': {
    opacity: 1,
  }
}));

const ControlButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  background: 'linear-gradient(135deg, rgba(59, 130, 246, 0.2), rgba(147, 51, 234, 0.2))',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(4px)',
  transition: 'all 0.3s ease',
  color: 'white',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 0 15px rgba(59, 130, 246, 0.5)',
  },
  '&:active': {
    transform: 'scale(0.95)',
  }
}));

const PlayButton = styled(ControlButton)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '50%',
  background: 'linear-gradient(135deg, rgba(59, 130, 246, 0.3), rgba(147, 51, 234, 0.3))',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 0 30px rgba(59, 130, 246, 0.3)',
  }
}));

const VideoOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.4)',
  backdropFilter: 'blur(4px)',
  transition: 'opacity 0.5s ease'
}));

const CornerAccent = styled(Box)(({ theme, position }) => ({
  position: 'absolute',
  width: 48,
  height: 48,
  border: '2px solid rgba(255, 255, 255, 0.2)',
  borderRadius: theme.shape.borderRadius,
  ...position
}));


const VideoPlayer = ({ src, orientation = 'horizontal' }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  const buttonClass = `
    relative
    p-3
    rounded-xl
    bg-gradient-to-br from-blue-500/20 to-purple-500/20
    border border-white/10
    backdrop-filter backdrop-blur-sm
    transition-all duration-300
    text-white
    overflow-hidden
    group
    hover:scale-105
    hover:border-white/30
    hover:shadow-[0_0_15px_rgba(59,130,246,0.5)]
    active:scale-95
    before:content-['']
    before:absolute
    before:inset-0
    before:bg-gradient-to-r
    before:from-blue-500/10
    before:to-purple-500/10
    before:opacity-0
    before:transition-opacity
    hover:before:opacity-100
    disabled:opacity-50
    disabled:hover:scale-100
  `;

  const playButtonClass = `
    p-8
    rounded-full
    bg-gradient-to-r from-blue-500/30 to-purple-500/30
    border border-white/20
    backdrop-filter backdrop-blur-md
    transition-all duration-500
    text-white
    hover:scale-110
    hover:shadow-[0_0_30px_rgba(59,130,246,0.3)]
    hover:border-white/40
    active:scale-95
    group
  `;

  const controlsClass = `
    absolute bottom-0 left-0 right-0
    p-6
    bg-gradient-to-t from-black/90 via-black/50 to-transparent
    opacity-0 group-hover:opacity-100
    transition-all duration-500
    flex items-center gap-6
    backdrop-filter backdrop-blur-sm
  `;

  const svgClass = `
    w-6 h-6
    transition-transform duration-300
    group-hover:scale-110
    group-active:scale-90
  `;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (!entry.isIntersecting && isPlaying) {
          setIsPlaying(false);
          videoRef.current?.pause();
        }
      },
      { threshold: 0.5 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, [isPlaying]);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleFullscreenToggle = async () => {
    if (!document.fullscreenElement) {
      await containerRef.current?.requestFullscreen();
      setIsFullscreen(true);
    } else {
      await document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const containerClass = `
    relative group
    overflow-hidden rounded-xl
    bg-gradient-to-br from-gray-900/90 to-gray-800/90
    border border-white/10
    transition-all duration-300
    hover:border-white/20
    ${orientation === 'vertical' ? 'w-full max-w-sm aspect-[9/16]' : 'w-full max-w-4xl aspect-video'}
  `;

  

  return (
    <VideoContainer orientation={orientation}>
      <Box component="video"
        ref={videoRef}
        src={src}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
        loop
        muted={isMuted}
        playsInline
        poster={`${src}#t=0.1`}
      />

      {!isPlaying && (
        <VideoOverlay>
          <PlayButton onClick={handlePlayPause}>
            <PlayIcon />
          </PlayButton>
        </VideoOverlay>
      )}

      <VideoControls>
        <ControlButton onClick={handlePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </ControlButton>
        
        <ControlButton onClick={handleMuteToggle}>
          {isMuted ? <MuteIcon /> : <VolumeIcon />}
        </ControlButton>
        
        <Box sx={{ flexGrow: 1 }} />
        
        <ControlButton onClick={handleFullscreenToggle}>
          <FullscreenIcon />
        </ControlButton>
      </VideoControls>

      <CornerAccent position={{ top: 0, left: 0, borderRight: 'none', borderBottom: 'none' }} />
      <CornerAccent position={{ top: 0, right: 0, borderLeft: 'none', borderBottom: 'none' }} />
      <CornerAccent position={{ bottom: 0, left: 0, borderRight: 'none', borderTop: 'none' }} />
      <CornerAccent position={{ bottom: 0, right: 0, borderLeft: 'none', borderTop: 'none' }} />
    </VideoContainer>
  );
};

export default VideoPlayer;