import React, { useState, useEffect } from 'react';
import './AIAgentDashboard.css';

import CreateAgent from './CreateAgent';
import HowItWorks from '../HowItWorks';
import bigBallsLogo from '../../assets/avatars/bb.webp'
import whaleFinderLogo from '../../assets/avatars/whaletrack.webp'
import guardianLogo from '../../assets/avatars/guardian.webp'
import alphaHunterLogo from '../../assets/avatars/alpha-hunt.webp'
import dcaLogo from '../../assets/avatars/dca.webp'
import alphaTill from '../../assets/avatars/alpha_seekers.webp'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Grid, Button, Paper, Stack, IconButton, Dialog, DialogTitle, DialogContent, ButtonGroup } from '@mui/material';
import ComingSoonOverlay from '../ComingSoonOverlay';
import AgentsManagement from './AgentsManagements';
import { useNavigate } from 'react-router-dom';
import logoImage from '../../assets/logo-tp.png';


const StyledHomeButton = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '20px',
  left: '20px',
  zIndex: 1000,
  '@media (max-width: 600px)': {
    top: '10px',
    left: '10px',
  }
}));

const StyledWalletButton = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: '20px',
    right: '20px',
    zIndex: 1000,
    width: 'auto',
    '@media (max-width: 600px)': {
      top: '10px',
      right: '10px',
    }
  }));



const AIAgentDashboard = ({publicKey, handleBuy, handleSell}) => {
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('explore');
  const navigate = useNavigate();
  const [isNavigating, setIsNavigating] = useState(false);


  // Create a navigation handler
  const handleHomeNavigation = () => {
    setIsNavigating(true);
    window.location.href = '/'; // This will force a full page refresh
  };

  // Add debug logging
  useEffect(() => {
    console.log('AIAgentDashboard props:', {
      hasPublicKey: !!publicKey,
      hasHandleBuy: typeof handleBuy === 'function',
      hasHandleSell: typeof handleSell === 'function'
    });
  }, [publicKey, handleBuy, handleSell]);
  // Placeholder data - replace with actual API call
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        // TODO: Replace with actual API call
        const mockAgents = [
          {
            id: 1,
            name: "Aping With Big Balls",
            description: "Aggressive momentum trading strategy",
            performance: "+233.5%",
            trades: 156,
            creator: "9oMoQZ...wZaC",
            avatar: bigBallsLogo
          },
          // Add more mock agents...
          {
            id: 2,
            name: "Blue Chip Guardian",
            description: "Conservative strategy focusing on established tokens with strong fundamentals",
            performance: "+45.2%",
            trades: 82,
            creator: "5xKpL...mN3r",
            avatar: guardianLogo,
            strategy: "Conservative"
          },
          {
            id: 3,
            name: "Whale Tracker Elite",
            description: "Copy-trades top performing wallets with proven track records",
            performance: "+127.8%",
            trades: 134,
            creator: "7jRtY...vB9q",
            avatar: whaleFinderLogo,
            strategy: "Copy-trading"
          },
          {
            id: 4,
            name: "Telegram Alpha Hunter",
            description: "Automatically executes trades based on signals from premium Telegram channels",
            performance: "+167.3%",
            trades: 98,
            creator: "3pNkR...qX2w",
            avatar: alphaHunterLogo,
            strategy: "Signal-based"
          },
          {
            id: 5,
            name: "Twitter Alpha Seeker",
            description: "Monitors and trades based on influential crypto Twitter accounts with proven track records",
            performance: "+142.9%",
            trades: 112,
            creator: "8mVtH...yP4k",
            avatar: alphaTill,
            strategy: "Social-trading"
          },
          {
            id: 6,
            name: "DCA Master",
            description: "Smart DCA strategy that automatically buys your favorite tokens during dips using technical indicators",
            performance: "+76.4%",
            trades: 245,
            creator: "2wQjL...nR9s",
            avatar: dcaLogo,
            strategy: "DCA"
          }
        ];
        setAgents(mockAgents);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching agents:', error);
        setIsLoading(false);
      }
    };

    fetchAgents();
  }, []);

  return (
    <div className="agent-dashboard">
      <StyledHomeButton>
  <IconButton
    onClick={handleHomeNavigation}
    disabled={isNavigating}
    sx={{
      background: 'linear-gradient(to right, #4f46e5, #3b82f6)',
      padding: '8px',
      borderRadius: '12px',
      opacity: isNavigating ? 0.7 : 1,
      '&:hover': {
        background: 'linear-gradient(to right, #3b82f6, #4f46e5)',
        transform: 'translateY(-2px)',
        boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
      },
      transition: 'all 0.3s ease',
    }}
  >
    <img 
      src={logoImage} 
      alt="Home" 
      style={{
        width: '42px',
        height: '42px',
        
      }}
    />
  </IconButton>
</StyledHomeButton>
      {/* <ComingSoonOverlay /> */}
      <nav className="dashboard-nav">
      <div className="nav-buttons">
        <button
          className={`nav-button ${activeTab === 'explore' ? 'active' : ''}`}
          onClick={() => setActiveTab('explore')}
        >
          Explore Agents
        </button>
        <button
          className={`nav-button ${activeTab === 'create' ? 'active' : ''}`}
          onClick={() => setActiveTab('create')}
        >
          Create Agent
        </button>
        <button
          className={`nav-button ${activeTab === 'manage' ? 'active' : ''}`}
          onClick={() => setActiveTab('manage')}
        >
          My Agents
        </button>
        <button
          className="nav-button how-it-works"
          onClick={() => setActiveTab('how')}
        >
          How It Works
        </button>
      </div>
      <div c>
        <WalletMultiButton 
          style={{
            padding: '12px 24px',
            background: 'linear-gradient(to right, #4f46e5, #3b82f6)',
            border: 'none',
            borderRadius: '12px',
            color: 'white',
            fontWeight: 600,
            transition: 'all 0.3s ease',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        />
      </div>
    </nav>
      
      <div className="dashboard-intro">
  <h1 className="dashboard-title">AI Trading Agents</h1>
  <p className="dashboard-description">
    Welcome to ApeOut's AI Trading Agents! Create or explore automated trading bots that can execute trades 24/7 based on your strategy. 
    Our agents can monitor Telegram signals, Twitter alpha, or track whale wallets to find the best trading opportunities. 
    Powered by $BNNA, these agents help you trade smarter and faster while you sleep. 🍌🤖
  </p>
</div>


<div className="dashboard-content">
        {activeTab === 'create' ? (
          <CreateAgent setActiveTab={setActiveTab} activeTab={activeTab} publicKey={publicKey} />
        ) : activeTab === 'how' ? (
          <HowItWorks setActiveTab={setActiveTab} activeTab={activeTab} />
        ) : activeTab === 'manage' ? (
          <AgentsManagement setActiveTab={setActiveTab} activeTab={activeTab} publicKey={publicKey} handleBuy={handleBuy} handleSell={handleSell} />
        ) : isLoading ? (
          <div className="loading-spinner">Loading...</div>
        ) : (
          <div className="agents-grid">
            {agents.map(agent => (
              <div
                key={agent.id}
                className="agent-card"
              >
                <img src={agent.avatar} alt={agent.name} className="agent-avatar" />
                <h3 style={{ color: 'white' }}>{agent.name}</h3>
                <p style={{ color: 'white' }}>{agent.description}</p>
                <div className="agent-stats">
                  <span className="performance">{agent.performance}</span>
                  <span style={{ color: 'white' }} className="trades">{agent.trades} trades</span>
                </div>
                <div className="agent-creator">
                  Created by: {agent.creator}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AIAgentDashboard;