import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Typography, IconButton, Button } from '@mui/material';
import { Close, Download, Clear } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const LogContainer = styled(Box)({
  backgroundColor: '#1a1a1a',
  color: '#00ff00',
  fontFamily: 'monospace',
  padding: '16px',
  borderRadius: '4px',
  height: '500px',
  overflowY: 'auto',
  whiteSpace: 'pre-wrap',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#333',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#666',
    borderRadius: '4px',
  },
});

const LogEntry = styled(Typography)({
  fontSize: '12px',
  lineHeight: 1.5,
  marginBottom: '4px',
});

const ConsoleLogModal = ({ open, onClose }) => {
  const [logs, setLogs] = useState([]);
  const logContainerRef = useRef(null);
  const isCapturing = useRef(false);

  // Frontend log collection
  useEffect(() => {
    if (!open) return; // Only start capturing when modal is open

    const originalConsole = {
      log: console.log.bind(console),
      error: console.error.bind(console),
      warn: console.warn.bind(console),
      debug: console.debug.bind(console),
    };

    const captureLog = (type, ...args) => {
      if (isCapturing.current) return originalConsole[type](...args);
      
      isCapturing.current = true;
      
      try {
        const timestamp = new Date().toISOString();
        const logEntry = {
          timestamp,
          type,
          message: args.map(arg => {
            if (arg === null) return 'null';
            if (arg === undefined) return 'undefined';
            if (typeof arg === 'object') {
              try {
                return JSON.stringify(arg, null, 2);
              } catch (e) {
                return String(arg);
              }
            }
            return String(arg);
          }).join(' '),
        };

        setLogs(prev => [...prev, logEntry].slice(-1000));
        originalConsole[type](...args);
      } finally {
        isCapturing.current = false;
      }
    };

    // Override console methods
    console.log = (...args) => captureLog('log', ...args);
    console.error = (...args) => captureLog('error', ...args);
    console.warn = (...args) => captureLog('warn', ...args);
    console.debug = (...args) => captureLog('debug', ...args);

    // Cleanup function
    return () => {
      console.log = originalConsole.log;
      console.error = originalConsole.error;
      console.warn = originalConsole.warn;
      console.debug = originalConsole.debug;
    };
  }, [open])

  // Auto-scroll to bottom
  useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [logs]);

  const handleDownload = () => {
    const logData = JSON.stringify(logs, null, 2);
    const blob = new Blob([logData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `console-logs-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleClear = () => {
    setLogs([]);
  };

  const renderLogEntry = (entry) => {
    let color = '#00ff00'; // default color
    switch (entry.type) {
      case 'error':
        color = '#ff0000';
        break;
      case 'warn':
        color = '#ffff00';
        break;
      case 'debug':
        color = '#0088ff';
        break;
      default:
        break;
    }

    return (
      <LogEntry key={entry.timestamp} sx={{ color }}>
        [{entry.timestamp}] {entry.type.toUpperCase()}: {entry.message}
      </LogEntry>
    );
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#2d2d2d',
          color: '#fff'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: '1px solid #444'
      }}>
        <Typography variant="h6">Console Logs</Typography>
        <Box>
          <IconButton onClick={handleClear} sx={{ mr: 1, color: '#fff' }}>
            <Clear />
          </IconButton>
          <IconButton onClick={handleDownload} sx={{ mr: 1, color: '#fff' }}>
            <Download />
          </IconButton>
          <IconButton onClick={onClose} sx={{ color: '#fff' }}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <LogContainer ref={logContainerRef}>
          {logs.map(renderLogEntry)}
        </LogContainer>
      </DialogContent>
    </Dialog>
  );
};

export default ConsoleLogModal;