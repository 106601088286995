import React, { useState, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, Typography, Box, 
  IconButton, TextField, Slider, Stack } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
import TradeSettings from '../trading/TradeSettings';


const AutoBumpDemoModal = ({ open, onClose, tradingWallet, handleBuy,tradeSettings, 
  setTradeSettings }) => {
  const [isDemo, setIsDemo] = useState(false);
  const [bumpAmount, setBumpAmount] = useState(0.015);
  const [frequency, setFrequency] = useState(60); // seconds
  const intervalRef = useRef(null);
  const [tokenMint, setTokenMint] = useState('DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump');

  const [lastBumpTime, setLastBumpTime] = useState(null);
  const [localSettings, setLocalSettings] = useState({
    priorityFee: tradeSettings.priorityFee,
    slippage: tradeSettings.slippage,
    amount: tradeSettings.amount || bumpAmount // Use bumpAmount as default

  });

  const setPriorityFee = (fee) => {
    // Handle empty or invalid input
    if (fee === '' || isNaN(parseFloat(fee))) {
      const defaultFee = 0.001;
      setLocalSettings(prev => ({ ...prev, priorityFee: defaultFee }));
      setTradeSettings(prev => ({ ...prev, priorityFee: defaultFee }));
      localStorage.setItem('tradeSettings', JSON.stringify({
        ...tradeSettings,
        priorityFee: defaultFee
      }));
      return;
    }
  
    // Convert to string and handle decimal places properly
    let numericValue = parseFloat(fee);
    
    // Ensure we keep 3 decimal places for proper increment handling
    const formattedValue = Math.max(0.001, Number(numericValue.toFixed(3)));
    
    // Store the formatted value
    setLocalSettings(prev => ({ ...prev, priorityFee: formattedValue }));
    setTradeSettings(prev => ({ ...prev, priorityFee: formattedValue }));
    localStorage.setItem('tradeSettings', JSON.stringify({
      ...tradeSettings,
      priorityFee: formattedValue
    }));
  };
  
  // Helper function to increment priority fee
  const incrementPriorityFee = (currentFee) => {
    // Convert to string to handle decimal places
    const feeStr = currentFee.toString();
    
    // Find decimal position
    const decimalPos = feeStr.indexOf('.');
    
    // Get multiplier based on decimal position
    const multiplier = Math.pow(10, 3); // We want 3 decimal places
    
    // Convert to integer, increment, then convert back
    const incrementedValue = Math.round((currentFee * multiplier) + 1) / multiplier;
    
    return incrementedValue;
  };
  
  // Helper function to decrement priority fee
  const decrementPriorityFee = (currentFee) => {
    const multiplier = Math.pow(10, 3); // We want 3 decimal places
    const decrementedValue = Math.round((currentFee * multiplier) - 1) / multiplier;
    
    // Ensure we don't go below minimum
    return Math.max(0.001, decrementedValue);
  };

  const startDemo = () => {
    console.debug('Starting auto-bump demo', { bumpAmount, frequency });
    if (!tradingWallet) {
      toast.error('Please connect your trading wallet first');
      return;
    }

    setIsDemo(true);
    scheduleBump();
    
    toast.info(
      <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <span>🚀</span>
        <div>
          <div style={{ fontWeight: 500 }}>Auto-Bump Started</div>
          <div style={{ fontSize: '12px', opacity: 0.8 }}>
            Bumping {bumpAmount} SOL every {frequency} seconds
          </div>
        </div>
      </div>
    );
  };

  const scheduleBump = () => {
    console.debug('Scheduling next bump');
    intervalRef.current = setInterval(async () => {
      try {
        console.debug('Executing scheduled bump');
        await handleBuy(tokenMint, bumpAmount, localSettings.slippage, localSettings.priorityFee);
        setLastBumpTime(new Date());
      } catch (error) {
        console.error('Auto-bump failed:', error);
        toast.error('Auto-bump failed: ' + error.message);
      }
    }, frequency * 1000);
  };

  const stopDemo = () => {
    console.debug('Stopping auto-bump demo');
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setIsDemo(false);
    setLastBumpTime(null);
    
    toast.info('Auto-Bump demo stopped');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
          backdropFilter: 'blur(16px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        }
      }}
    >
      <DialogTitle sx={{ color: 'white', m: 0, p: 2 }}>
        Auto-Bump (Volume Booster)
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          <Typography color="white">
            Experience automatic token bumping with customizable amount and frequency. Boost your volume and get more attention from the community
          </Typography>
          <Typography color="yellow">
            Only Buys 0.01 and Above will Trigger Bumps, Be sure to Account for Fees. (Supporting $BNNA only currently)
          </Typography>
          <Box>
            <Typography color="white" gutterBottom>
              Token Mint Address
            </Typography>
            <TextField
              value={tokenMint}
              onChange={(e) => setTokenMint(e.target.value)}
              placeholder="Enter token mint address"
              sx={{
                input: { color: 'white' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                  '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.4)' },
                }
              }}
              fullWidth
            />
          </Box>

          <Box>
            {/* <Typography color="white" gutterBottom>
              Bump Amount (SOL)
            </Typography>
            <TextField
  type="number"
  value={bumpAmount}
  onChange={(e) => {
    const value = e.target.value;
    if (!value || isNaN(parseFloat(value))) {
      setBumpAmount(0.001);
      return;
    }
    const numericValue = parseFloat(value);
    const formattedValue = Math.max(0.001, numericValue);
    setBumpAmount(formattedValue);
    setLocalSettings(prev => ({ ...prev, amount: formattedValue }));
  }}
  sx={{
    input: { color: 'white' },
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
      '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.4)' },
    }
  }}
  inputProps={{ 
    step: 0.001,
    min: 0.001,
    // Add these props to improve number input behavior
    onKeyDown: (e) => {
      // Allow: backspace, delete, tab, escape, enter, decimal point
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
         // Allow: Ctrl+A, Command+A
        (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
         // Allow: home, end, left, right, down, up
        (e.keyCode >= 35 && e.keyCode <= 40)) {
        return;
      }
      // Ensure that it is a number and stop the keypress if not
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
          (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    }
  }}
  fullWidth
/> */}
          </Box>

          <Box>
            <Typography color="white" gutterBottom>
              Frequency (seconds)
            </Typography>
            <Slider
              value={frequency}
              onChange={(_, value) => setFrequency(value)}
              min={30}
              max={300}
              step={30}
              valueLabelDisplay="auto"
              sx={{ color: '#4ade80' }}
            />
          </Box>
          <TradeSettings
              priorityFee={localSettings.priorityFee}
              setPriorityFee={(fee) => {
                if (fee === '' || isNaN(parseFloat(fee))) {
                  const defaultFee = 0.001;
                  setLocalSettings(prev => ({ ...prev, priorityFee: defaultFee }));
                  setTradeSettings(prev => ({ ...prev, priorityFee: defaultFee }));
                  return;
                }
        
                const numericValue = parseFloat(fee);
                const formattedValue = Math.max(0.001, Number(numericValue.toFixed(3)));
        
                setLocalSettings(prev => ({ ...prev, priorityFee: formattedValue }));
                setTradeSettings(prev => ({ ...prev, priorityFee: formattedValue }));
              }}
              onIncrementPriorityFee={() => {
                const newValue = incrementPriorityFee(localSettings.priorityFee);
                setLocalSettings(prev => ({ ...prev, priorityFee: newValue }));
                setTradeSettings(prev => ({ ...prev, priorityFee: newValue }));
              }}
              onDecrementPriorityFee={() => {
                const newValue = decrementPriorityFee(localSettings.priorityFee);
                setLocalSettings(prev => ({ ...prev, priorityFee: newValue }));
                setTradeSettings(prev => ({ ...prev, priorityFee: newValue }));
              }}
            slippage={localSettings.slippage}
            setSlippage={(slip) => {
              setLocalSettings(prev => ({ ...prev, slippage: slip }));
              setTradeSettings(prev => ({ ...prev, slippage: slip }));
              localStorage.setItem('tradeSettings', JSON.stringify({
                ...tradeSettings,
                slippage: slip
              }));
            }}
            amount={bumpAmount} // Add this
            setAmount={(value) => {  // Add this
              if (!value || isNaN(parseFloat(value))) {
                setBumpAmount(0.001);
                setLocalSettings(prev => ({ ...prev, amount: 0.001 }));
                return;
              }
              const numericValue = parseFloat(value);
              const formattedValue = Math.max(0.001, numericValue);
              setBumpAmount(formattedValue);
              setLocalSettings(prev => ({ ...prev, amount: formattedValue }));
            }}
            
            className="modal-trade-settings"
          />

          {lastBumpTime && (
            <Typography color="white" variant="body2">
              Last bump: {lastBumpTime.toLocaleTimeString()}
            </Typography>
          )}

          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              onClick={isDemo ? stopDemo : startDemo}
              variant="contained"
              sx={{
                bgcolor: isDemo ? 'error.main' : 'success.main',
                '&:hover': {
                  bgcolor: isDemo ? 'error.dark' : 'success.dark',
                }
              }}
            >
              {isDemo ? 'Stop Bumping' : 'Start Bumping'}
            </Button>

            <Button
              onClick={onClose}
              variant="outlined"
              sx={{
                color: 'white',
                borderColor: 'white',
                '&:hover': {
                  borderColor: 'white',
                  bgcolor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AutoBumpDemoModal;