import React from 'react';
import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import { TrendingUp, TrendingDown } from '@mui/icons-material';

const TokenPurchaseHistory = ({ tokenPurchases = [], currentMarketCaps = {} }) => {
  // Guard against invalid data
  if (!Array.isArray(tokenPurchases)) {
    console.warn('TokenPurchases is not an array:', tokenPurchases);
    return null;
  }

  const formatDate = (timestamp) => {
    try {
      return new Date(timestamp).toLocaleString();
    } catch (error) {
      return 'Invalid Date';
    }
  };

  const calculateGains = (purchaseMarketCap, currentMarketCap) => {
    if (!purchaseMarketCap || !currentMarketCap) return null;
    return ((currentMarketCap - purchaseMarketCap) / purchaseMarketCap) * 100;
  };

  if (!tokenPurchases.length) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography sx={{ color: 'rgba(255,255,255,0.7)' }}>
          No purchase history available
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4, width: '100%' }}>
      <Typography variant="h6" sx={{ color: 'black', mb: 2 }}>
        Token Purchase History
      </Typography>
      
      <TableContainer component={Paper} sx={{ bgcolor: 'rgba(0,0,0,0.5)' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Token</TableCell>
              <TableCell sx={{ color: 'white' }}>Purchase Date</TableCell>
              <TableCell sx={{ color: 'white' }}>Amount</TableCell>
              <TableCell sx={{ color: 'white' }}>Purchase MC</TableCell>
              <TableCell sx={{ color: 'white' }}>Current MC</TableCell>
              <TableCell sx={{ color: 'white' }}>Gain/Loss</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokenPurchases.map((purchase, index) => {
              const gain = calculateGains(
                purchase.purchaseMarketCap,
                currentMarketCaps[purchase.tokenMint]
              );
              
              return (
                <TableRow key={purchase._id || index}>
                  <TableCell sx={{ color: 'white' }}>
                    {`${purchase.tokenMint?.slice(0, 6)}...${purchase.tokenMint?.slice(-4)}`}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    {formatDate(purchase.purchaseTimestamp)}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    {purchase.amount?.toFixed(4) || '0.0000'}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    ${purchase.purchaseMarketCap?.toFixed(2) || '0.00'}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    {currentMarketCaps[purchase.tokenMint] 
                      ? `$${currentMarketCaps[purchase.tokenMint].toFixed(2)}`
                      : 'Loading...'}
                  </TableCell>
                  <TableCell>
                    {gain !== null ? (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        color: gain >= 0 ? '#4ade80' : '#ef4444'
                      }}>
                        {gain.toFixed(2)}%
                        {gain >= 0 ? (
                          <TrendingUp sx={{ fontSize: 16 }} />
                        ) : (
                          <TrendingDown sx={{ fontSize: 16 }} />
                        )}
                      </Box>
                    ) : (
                      <CircularProgress size={16} />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TokenPurchaseHistory;