import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Slider,
  TextField,
  InputAdornment,
  Stack,
  Divider,
  Tooltip,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(145deg, rgba(25, 118, 210, 0.05), rgba(25, 118, 210, 0.1))',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  position: 'relative',
  overflow: 'visible',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit',
    background: 'linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0))',
    pointerEvents: 'none'
  }
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    background: 'linear-gradient(to right, #1976d2, #64b5f6)'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  }
}));

const SettingSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  background: 'rgba(255, 255, 255, 0.05)',
  marginBottom: theme.spacing(2)
}));

const TradeSettings = ({ 
  priorityFee, 
  setPriorityFee, 
  slippage, 
  setSlippage, 
  amount, 
  setAmount,
  onIncrementPriorityFee,  // Add these new props
  onDecrementPriorityFee, 
}) => {
  return (
    <StyledCard>
      <CardContent>
        <Stack spacing={3}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <SettingsIcon sx={{ color: 'primary.main' }} />
            <Typography variant="h6" color="white" fontWeight="500">
              Trade Settings
            </Typography>
          </Box>

          <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />

          <SettingSection>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
              <Typography color="white" variant="subtitle2">
                Priority Fee (SOL)
              </Typography>
              <Tooltip title="Higher priority fee increases transaction speed" placement="top">
              <button onClick={onDecrementPriorityFee}>-</button>
    <button onClick={onIncrementPriorityFee}>+</button>
              </Tooltip>
            </Box>
            <TextField
              value={priorityFee}
              onChange={(e) => setPriorityFee(parseFloat(e.target.value))}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">SOL</InputAdornment>,
                sx: {
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.23)'
                  }
                }
              }}
              sx={{ width: '100%' }}
            />
          </SettingSection>

          <SettingSection>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
              <Typography color="white" variant="subtitle2">
                Slippage Tolerance
              </Typography>
              <Typography color="primary.light" variant="body2">
                {slippage}%
              </Typography>
            </Box>
            <StyledSlider
              value={slippage}
              onChange={(_, value) => setSlippage(Math.round(value * 10) / 10)} // Add this fix
              min={0.1}
              max={100}
              step={0.1}
              valueLabelDisplay="auto"
              valueLabelFormat={value => `${value}%`}
            />
          </SettingSection>

          <SettingSection>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
              <Typography color="white" variant="subtitle2">
                Trade Amount (SOL)
              </Typography>
              <Tooltip title="Default amount for trades" placement="top">
                <IconButton size="small" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <TextField
          type="number"
          value={amount}
          onChange={(e) => {
            const value = e.target.value;
            if (!value || isNaN(parseFloat(value))) {
              setAmount(0.001);
              return;
            }
            const numericValue = parseFloat(value);
            const formattedValue = Math.max(0.001, numericValue);
            setAmount(formattedValue);
          }}
          sx={{
            input: { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.4)' },
            }
          }}
          inputProps={{ 
            step: 0.001,
            min: 0.001,
            onKeyDown: (e) => {
              if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
                (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                (e.keyCode >= 35 && e.keyCode <= 40)) {
                return;
              }
              if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
                  (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
              }
            }
          }}
          fullWidth
        />
          </SettingSection>
        </Stack>
      </CardContent>
    </StyledCard>
  );
};

export default TradeSettings;