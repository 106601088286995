import React, { useState, useEffect } from 'react';
import { FaAd } from 'react-icons/fa';
import './FavoritesModal.css'; // Make sure to create this CSS file

const FavoritesModal = ({ onClose, favorites, onBuy, onSell, ownedTokens, ownedTokensData, onRemoveFavorite, boughtTokens, isWalletConnected, wallet, fetchOwnedTokens }) => {
  const [activeTab, setActiveTab] = useState('favorites');
  const [loading, setLoading] = useState(true);
  const [processedOwnedTokens, setProcessedOwnedTokens] = useState([]);
  const [sellPercentage, setSellPercentage] = useState(100);


  const handleSell = (token) => {
    const ownedAmount = ownedTokens[token.mint] || 0;
    const amountToSell = (ownedAmount * sellPercentage) / 100;
    onSell(token, amountToSell);
  };

  const handleFetchOwnedTokens = async () => {
    if (wallet && wallet.public_key) {
      await fetchOwnedTokens(wallet.public_key); // Call the function to fetch owned tokens
    }
  };



  useEffect(() => {
    setLoading(false);
  }, [ownedTokensData]);

  const renderFavoritesList = () => (
    <ul className="tokens-list">
      {favorites.map((coin, index) => {
        const ownedAmount = ownedTokens[coin.mint] || 0;
        return (
          <li key={index} className="token-item">
            <img src={coin.image} alt={coin.name} className="token-image" />
            <div className="token-info">
              <h3>{coin.name}</h3>
              <a href={`https://pump.fun/${coin.mint}`} target="_blank" rel="noopener noreferrer">
                <FaAd />
              </a>
              <p>{coin.description}</p>
            </div>
            <div className="token-actions">
              <button onClick={() => onBuy(coin, 0.1)}>🦍➖</button>
              {ownedAmount > 0 && (
                <>
                  <button onClick={() => handleSell(coin.mint)}>🦍➖</button>
                  
                  <span>Owned: {ownedAmount}</span>
                </>
              )}
              <button onClick={() => onRemoveFavorite(coin)}>Remove</button>
            </div>
          </li>
        );
      })}
    </ul>
  );

  const renderOwnedTokensList = () => {
    if (loading) {
      return <p>Loading owned tokens...</p>;
    }
  
    if (!isWalletConnected) {
      return <p>Please connect your wallet to view owned tokens.</p>;
    }
  
    if (!ownedTokensData || ownedTokensData.length === 0) {
      return <p>No owned tokens found.</p>;
    }
  
    return (
      <ul className="tokens-list">
        {ownedTokensData.map((token, index) => (
          <li key={token.mint || index} className="token-item">
            <div className="token-header">
              <img src={token.image} alt={token.name} className="token-image" />
              <div className="token-title">
                <h3>{token.name}</h3>
                <a href={`https://pump.fun/${token.mint}`} target="_blank" rel="noopener noreferrer" className="pump-fun-link">
                  <FaAd /> View on Pump.fun
                </a>
              </div>
            </div>
            <div className="token-body">
              <p className="token-description">{token.description}</p>
              <div className="token-stats">
                <span className="token-balance">Owned: {ownedTokens[token.mint] || 0}</span>
                {token.price && <span className="token-price">Price: ${token.price.toFixed(4)}</span>}
              </div>
            </div>
            <div className="token-actions">
              <button onClick={() => handleSell(token)} className="sell-button">
                <span className="button-icon">🦍➖</span>
                <span className="button-text">Sell</span>
              </button>
              <div className="sell-percentage">
                <select 
                  value={sellPercentage} 
                  onChange={(e) => setSellPercentage(Number(e.target.value))}
                  className="sell-percentage-select"
                >
                  <option value={25}>25%</option>
                  <option value={50}>50%</option>
                  <option value={75}>75%</option>
                  <option value={100}>100%</option>
                </select>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="favorites-modal-overlay">
      <div className="favorites-modal">
        <div className="modal-tabs">
          <button 
            className={`tab-button ${activeTab === 'favorites' ? 'active' : ''}`}
            onClick={() => setActiveTab('favorites')}
          >
            Favorites
          </button>
          <button 
            className={`tab-button ${activeTab === 'owned' ? 'active' : ''}`}
            onClick={() => setActiveTab('owned')}
          >
            Owned Tokens
          </button>
        </div>
        <div className="modal-content">
        {activeTab === 'favorites' ? renderFavoritesList() : (
            <>
              <button onClick={handleFetchOwnedTokens} className="fetch-owned-tokens-button">Fetch Owned Tokens</button> {/* New button */}
              {renderOwnedTokensList()}
            </>
          )}
        </div>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );

}

export default FavoritesModal;