import React, { useState, useEffect, useRef } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { toast } from 'react-toastify';
import { 
  Box, 
  Dialog, 
  DialogTitle, 
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Typography 
} from '@mui/material';

const AuthenticationHandler = ({ 
  onAuthComplete,
  children,
  tradingWallet,
  setTradingWallet 
}) => {
  const { publicKey, signMessage, disconnect } = useWallet();
  const [sessionToken, setSessionToken] = useState(null);
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [show2FADialog, setShow2FADialog] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const sessionCheckInterval = useRef(null);

  // Check session validity every 5 minutes
  useEffect(() => {
    if (sessionToken) {
      sessionCheckInterval.current = setInterval(validateSession, 300000);
    }
    return () => clearInterval(sessionCheckInterval.current);
  }, [sessionToken]);

  // Verify wallet ownership with message signing
  const verifyWalletOwnership = async () => {
    if (!publicKey || !signMessage) {
      toast.error('Wallet connection required');
      return false;
    }

    try {
      setIsVerifying(true);
      const message = new TextEncoder().encode(
        `Verify wallet ownership for secure trading operations at ${new Date().toISOString()}`
      );
      const signature = await signMessage(message);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/auth/verify-wallet`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          publicKey: publicKey.toString(),
          signature: Buffer.from(signature).toString('base64'),
          message: Buffer.from(message).toString('base64')
        })
      });

      if (!response.ok) throw new Error('Wallet verification failed');
      
      const { sessionToken: newToken, requires2FA } = await response.json();
      setSessionToken(newToken);
      setIs2FAEnabled(requires2FA);
      
      if (requires2FA) {
        setShow2FADialog(true);
      } else {
        await initializeSession(newToken);
      }
      
      return true;
    } catch (error) {
      console.error('Wallet verification error:', error);
      toast.error('Failed to verify wallet ownership');
      return false;
    } finally {
      setIsVerifying(false);
    }
  };

  // Initialize session with 2FA if required
  const verify2FA = async () => {
    try {
      setIsVerifying(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/auth/verify-2fa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionToken}`
        },
        body: JSON.stringify({
          code: twoFactorCode,
          publicKey: publicKey.toString()
        })
      });

      if (!response.ok) throw new Error('2FA verification failed');
      
      const { verifiedSession } = await response.json();
      await initializeSession(verifiedSession);
      setShow2FADialog(false);
    } catch (error) {
      console.error('2FA verification error:', error);
      toast.error('Invalid 2FA code');
    } finally {
      setIsVerifying(false);
      setTwoFactorCode('');
    }
  };

  // Initialize trading session
  const initializeSession = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/auth/init-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ publicKey: publicKey.toString() })
      });

      if (!response.ok) throw new Error('Failed to initialize session');
      
      const { tradingWallet: wallet } = await response.json();
      setTradingWallet(wallet);
      
      // Store session info securely
      sessionStorage.setItem('lastActivity', Date.now().toString());
      sessionStorage.setItem('sessionToken', token);
      
      if (onAuthComplete) onAuthComplete(wallet);
    } catch (error) {
      console.error('Session initialization error:', error);
      toast.error('Failed to initialize trading session');
      handleSignOut();
    }
  };

  // Validate existing session
  const validateSession = async () => {
    const lastActivity = parseInt(sessionStorage.getItem('lastActivity') || '0');
    const SESSION_TIMEOUT = 3600000; // 1 hour

    if (Date.now() - lastActivity > SESSION_TIMEOUT) {
      handleSignOut();
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/auth/validate-session`, {
        headers: {
          'Authorization': `Bearer ${sessionToken}`
        }
      });

      if (!response.ok) throw new Error('Invalid session');
      
      sessionStorage.setItem('lastActivity', Date.now().toString());
    } catch (error) {
      console.error('Session validation error:', error);
      handleSignOut();
    }
  };

  // Handle sign out
  const handleSignOut = async () => {
    try {
      if (sessionToken) {
        await fetch(`${process.env.REACT_APP_API_URL}api/auth/logout`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${sessionToken}`
          }
        });
      }
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setSessionToken(null);
      setTradingWallet(null);
      sessionStorage.clear();
      await disconnect();
    }
  };

  return (
    <>
      {children({
        isAuthenticated: !!sessionToken && !!tradingWallet,
        isVerifying,
        verifyWalletOwnership,
        handleSignOut
      })}

      <Dialog open={show2FADialog} onClose={() => setShow2FADialog(false)}>
        <DialogTitle>Two-Factor Authentication Required</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Enter 2FA Code"
            type="text"
            fullWidth
            value={twoFactorCode}
            onChange={(e) => setTwoFactorCode(e.target.value)}
            disabled={isVerifying}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShow2FADialog(false)} 
            disabled={isVerifying}
          >
            Cancel
          </Button>
          <Button 
            onClick={verify2FA}
            disabled={!twoFactorCode || isVerifying}
            variant="contained"
          >
            {isVerifying ? <CircularProgress size={24} /> : 'Verify'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AuthenticationHandler;