import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Stack,
  Modal,
  Divider,
  Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CalculateIcon from '@mui/icons-material/Calculate';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import PumpAssessmentModal from './PumpAssessmentModal';


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.9), rgba(31, 41, 55, 0.9))',
  backdropFilter: 'blur(16px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.4)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3b82f6',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
});

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
  
  const ModalPaper = styled(Paper)(({ theme }) => ({
    width: '90%',
    maxWidth: '400px',
    maxHeight: '90vh',
    overflow: 'auto',
    padding: theme.spacing(2),
    background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
    backdropFilter: 'blur(16px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: theme.spacing(2),
    boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  }));

const ResultRow = ({ label, value, isPercentage = false, isDollar = false }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
    <Typography color="rgba(255, 255, 255, 0.7)">{label}</Typography>
    <Typography color="white" fontWeight="bold">
      {isDollar && '$'}
      {typeof value === 'number' ? value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) : value}
      {isPercentage && '%'}
    </Typography>
  </Box>
);

const MetallicButton = styled(Button)(({ variant }) => {
  const gradients = {
    gold: 'linear-gradient(45deg, #FFD700, #FFA500)',
    silver: 'linear-gradient(45deg, #C0C0C0, #E8E8E8)',
    bronze: 'linear-gradient(45deg, #CD7F32, #B8860B)'
  };

  const hoverGradients = {
    gold: 'linear-gradient(45deg, #FFA500, #FFD700)',
    silver: 'linear-gradient(45deg, #E8E8E8, #C0C0C0)',
    bronze: 'linear-gradient(45deg, #B8860B, #CD7F32)'
  };

  return {
    background: gradients[variant] || gradients.gold,
    color: variant === 'silver' ? '#000' : '#FFF',
    fontWeight: 'bold',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    padding: '0.75rem 1rem',
    borderRadius: '12px',
    fontSize: '1rem',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    minWidth: '150px',
    maxWidth: '200px',
    flex: 1,
    '&:hover': {
      background: hoverGradients[variant] || hoverGradients.gold,
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    }
  };
});

const GainsCalculator = () => {
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({
    investmentUsd: '',
    entryMc: '',
    targetMc: '',
  });
  const [results, setResults] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetCalculator();
  };

  const calculateGains = async () => {
    try {
      const investment = parseFloat(inputs.investmentUsd);
      const entryMc = parseFloat(inputs.entryMc);
      const targetMc = parseFloat(inputs.targetMc);

      if (isNaN(investment) || isNaN(entryMc) || isNaN(targetMc)) {
        throw new Error('Please enter valid numbers');
      }
      if (entryMc === 0) {
        throw new Error('Entry market cap cannot be zero');
      }

      const multiplier = targetMc / entryMc;
      const potentialGainUsd = investment * multiplier;
      const profitUsd = potentialGainUsd - investment;
      const percentageGain = (multiplier - 1) * 100;

      setResults({
        investment,
        entryMc,
        targetMc,
        multiplier,
        potentialGainUsd,
        profitUsd,
        percentageGain,
      });

      // Add points for using calculator
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/update-user-points`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            walletAddress: window.solana?.publicKey?.toString(),
            taskName: 'usedPumpCalculator'
          })
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            console.log('Points awarded for using calculator');
          }
        }
      } catch (error) {
        console.error('Error awarding points:', error);
      }

    } catch (error) {
      alert(error.message);
    }
  };

  const resetCalculator = () => {
    setInputs({
      investmentUsd: '',
      entryMc: '',
      targetMc: '',
    });
    setResults(null);
  };

  const calculatorContent = (
    <Stack spacing={2}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="h6" component="h2" color="white">
          Gains Calculator
        </Typography>
        <Button onClick={handleClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </Button>
      </Box>

      <StyledTextField
        label="Investment Amount (USD)"
        name="investmentUsd"
        value={inputs.investmentUsd}
        onChange={handleInputChange}
        type="number"
        size="small"
        fullWidth
      />
      <StyledTextField
        label="Entry Market Cap (USD)"
        name="entryMc"
        value={inputs.entryMc}
        onChange={handleInputChange}
        type="number"
        size="small"
        fullWidth
      />
      <StyledTextField
        label="Target Market Cap (USD)"
        name="targetMc"
        value={inputs.targetMc}
        onChange={handleInputChange}
        type="number"
        size="small"
        fullWidth
      />

      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          onClick={calculateGains}
          startIcon={<CalculateIcon />}
          fullWidth
          size="small"
          sx={{
            background: 'linear-gradient(to right, #3b82f6, #2563eb)',
            '&:hover': {
              background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
            },
          }}
        >
          Calculate
        </Button>
        <Button
          variant="outlined"
          onClick={resetCalculator}
          startIcon={<RestartAltIcon />}
          fullWidth
          size="small"
          sx={{
            borderColor: 'rgba(255, 255, 255, 0.23)',
            color: 'white',
            '&:hover': {
              borderColor: 'rgba(255, 255, 255, 0.4)',
              background: 'rgba(255, 255, 255, 0.05)',
            },
          }}
        >
          Reset
        </Button>
      </Stack>

      {results && (
        <>
          <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
          <Box>
            <Typography variant="subtitle1" color="white" gutterBottom>
              Results
            </Typography>
            <ResultRow label="Investment" value={results.investment} isDollar />
            <ResultRow label="Entry MC" value={results.entryMc} isDollar />
            <ResultRow label="Target MC" value={results.targetMc} isDollar />
            <ResultRow label="Multiplier" value={`${results.multiplier}x`} />
            <ResultRow label="Potential Value" value={results.potentialGainUsd} isDollar />
            <ResultRow label="Profit" value={results.profitUsd} isDollar />
            <ResultRow label="Percentage Gain" value={results.percentageGain} isPercentage />
          </Box>
        </>
      )}
    </Stack>
  );

  return (
    <>
    <Box 
  sx={{ 
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}
>
<MetallicButton
      onClick={handleOpen}
      startIcon={<CalculateOutlinedIcon />}
      variant="outlined"
      size="large"
      sx={{
        borderColor: 'rgba(255, 255, 255, 0.23)',
        color: 'white',
        padding: '10px 24px',
        width: '50%',
        fontSize: '1rem',
        textTransform: 'none',
        borderRadius: '12px',
        backgroundColor: 'rgb(79, 70, 229)', 
        '&:hover': {
          borderColor: 'rgba(255, 255, 255, 0.4)',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      }}
    >
        Gains Calculator
      </MetallicButton>
</Box>
     

      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="gains-calculator-modal"
      >
        <ModalPaper>{calculatorContent}</ModalPaper>
      </StyledModal>

    {/* <Container maxWidth="md" sx={{ py: 4 }}>
        <PumpAssessmentModal open={true} onClose={() => window.history.back()} />
    </Container> */}
    </>
  );
};

export default GainsCalculator;