import React from 'react';
import Button from '@mui/material/Button';
import StarIcon from '@mui/icons-material/Star';

const PremiumTriggerButton = ({ setShowPremiumModal, isPremium, className = '' }) => {
  return (
    <Button
      onClick={() => setShowPremiumModal(true)}
      startIcon={<StarIcon />}
      className={className}
      sx={{
        background: 'linear-gradient(to right, #fbbf24, #f97316)',
        '&:hover': {
          background: 'linear-gradient(to right, #f97316, #ea580c)',
        },
        '&:disabled': {
          background: 'linear-gradient(to right, #9ca3af, #6b7280)',
          opacity: 0.6,
          cursor: 'not-allowed',
        },
        textTransform: 'none',
        color: 'white',
        fontWeight: 600,
        padding: '8px 16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
      }}
    >
      {isPremium === 'PREMIUM' ? 'Premium Active' : 'Automation Tools'}
    </Button>
  );
};

export default PremiumTriggerButton;