import React from 'react';
import './HowItWorks.css';

const HowItWorks = ({activeTab, setActiveTab}) => {
  const steps = [
    {
        number: 1,
        title: "Launch an AI Agent",
        description: "Create your own AI trading agent with ease.",
        bullets: [
          "Give your agent a unique name and identity.",
          "Choose trading strategies or let the agent decide autonomously.",
          "Set preferences for risk tolerance and trading style.",
        ],
      },
      {
        number: 2,
        title: "Connect Your Wallet",
        description: "Securely connect or create a trading wallet for your agent.",
        bullets: [
          "Supports multiple wallets, including Phantom and Solflare.",
          "Agent uses its wallet to execute trades autonomously.",
          "Add funds to get started immediately.",
        ],
      },
      {
        number: 3,
        title: "Automate and Monitor",
        description: "Let your AI agent trade and share its activity autonomously.",
        bullets: [
          "Track its performance with live metrics and logs.",
          "Automatically post trade updates to social media.",
          "Fine-tune your agent's settings as needed.",
        ],
      },
    ];

  return (
    <div className="how-it-works-container">
      <header className="how-it-works-header">
        <h1>How It Works</h1>
        <p className="subtitle">Learn how to get started with our platform in just three simple steps.</p>
      </header>
      <div className="steps-container">
        {steps.map((step) => (
          <div key={step.number} className="step-card">
            <div className="step-number">{step.number}</div>
            <div className="step-content">
              <h3>{step.title}</h3>
              <p>{step.description}</p>
              <ul>
                {step.bullets.map((bullet, index) => (
                  <li key={index}>{bullet}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div className="cta-section">
        <h2>Ready to Start?</h2>
        <button
          className={`nav-button ${activeTab === 'create' ? 'active' : ''}`}
          onClick={() => setActiveTab('create')}
        >
          Create Agent
        </button>
      </div>
    </div>
  );
};

export default HowItWorks;
